// import React from 'react';
import { setDateOfCompletion } from '../../store/action-creators';
import * as API from '../../scorm-api/API';
import { useStore, useSelector, useDispatch } from 'react-redux';

const SCORM = process.env.REACT_APP_SCORM;

export default function useScorm() {
  // const reduxState = useSelector((state) => state);
  const replayMode = useSelector((state) => state.settings.replayMode);
  const store = useStore();
  const dispatch = useDispatch();

  const _setSuspendData = () => {
    const stringData = JSON.stringify(store.getState());
    if (!SCORM && window.sessionStorage) {
      window.sessionStorage.setItem('suspend_data', stringData)
    }
    API.LMSSetValue('cmi.suspend_data', stringData);
    API.LMSCommit('cmi.suspend_data', stringData);
  };

  const methods = {
    getInteractionCount() {
      return API.LMSGetValue('cmi.interactions._count');
    },
    getStudentName() {
      return API.LMSGetValue('cmi.core.student_name') || 'LMS Student Name';
    },
    logResult({ i, result }) {
      if (replayMode) return;

      const state = store.getState();
      const rawScore = Object.values(state.questions).reduce((total, topic) => {
        return total + topic.qs.reduce((qTotal, q) => qTotal + q.score, 0);
      }, 0);
      // result must be a string, either 'correct' or 'wrong'
      API.LMSSetValue('cmi.interactions.' + i + '.result', result);
      API.LMSSetValue('cmi.core.score.raw', rawScore);
    },
    logInteraction({ i, q, interactionType, response, questionSet, objective, ans }) {
      if (replayMode) return;

      let formattedObjective = objective.replace(/ /g, '_'); // objective must be string with no spaces

      API.LMSSetValue('cmi.interactions.' + i + '.id', questionSet + q);
      API.LMSSetValue('cmi.interactions.' + i + '.type', interactionType);
      API.LMSSetValue('cmi.interactions.' + i + '.correct_responses.0.pattern', ans);
      API.LMSSetValue('cmi.interactions.' + i + '.objectives.0.id', formattedObjective);
      API.LMSSetValue('cmi.interactions.' + i + '.student_response', response);
    },
    commitAndSaveProgress() {
      if (replayMode) return;

      _setSuspendData();
      API.LMSCommit();
    },
    completeCourse() {
      const lessonStatus = API.LMSGetValue('cmi.core.lesson_status');

      if (replayMode) return true;
      if (lessonStatus === 'passed' || lessonStatus === 'failed') return;

      const mode = API.LMSGetValue('cmi.core.lesson_mode');
      const rawScore = API.LMSGetValue('cmi.core.score.raw');

      if (mode !== 'review' && mode !== 'browse') {
        if (rawScore > -1) {
          // TODO: this threshold needs to be adjusted once a final tally of questions is made
          API.LMSSetValue('cmi.core.lesson_status', 'passed');
          API.LMSCommit('cmi.core.lesson_status', 'passed');
        } else {
          API.LMSSetValue('cmi.core.lesson_status', 'failed');
          API.LMSCommit('cmi.core.lesson_status', 'failed');
        }
        console.log('setting date...');
        let d = new Date();
        let day = d.getDate();
        let m = d.getMonth() + 1;
        let y = d.getFullYear();
        let date = `${m}/${day}/${y}`;
        dispatch(setDateOfCompletion(date))

        _setSuspendData();
        // API.LMSSetValue('cmi.suspend_data', JSON.stringify(store.getState()));
        // API.LMSSetValue('cmi.core.exit', '');
      }

      // API.LMSFinish();
      return true;
    },
    exitCourse() {
      // API.LMSCommit('cmi.core.exit', replayMode ? '' : 'suspend'); // we're trying to communicate that the user is planning on returning to the course
      API.LMSCommit('cmi.core.exit', ''); 
      API.LMSFinish('');
    },
    finishCourse() {
      API.LMSCommit('cmi.core.exit', ''); // 'logout' could potentially automatically log the user out on exit
      API.LMSFinish('');
    }
  };

  return methods;
}
