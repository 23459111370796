import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AnimatedTRDialog from './AnimatedTRDialog';
import PointsIncrementor from './PointsIncrementor';
//import HintReveal from './HintReveal';
import HelpfulHintCheckbox from '../inputs/HelpfulHintCheckbox';
import { increasePoints, setLastPointTotal, setHelpfulHintsShowed } from '../../store/action-creators';
import useScorm from '../../utils/hooks/useScorm';
import { createUseStyles } from 'react-jss';
import SkewedDialogBox from './SkewedDialogBox';
import OrangeHeader from '../typography/OrangeHeader';
import ButtonCustom from '../inputs/ButtonCustom';

const useStyles = createUseStyles({
  pointsContainer: {
    maxWidth: 150,
    position: 'absolute',
    top: '-2vw',
    right: '-2vw',
    width: '12vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      top: '-2vh'
    }
  },
  hints: {
    listStyle: 'none',
    padding: 0,
    '&>li': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& p':{
      marginTop: 5,
      marginBottom: 0,
      fontSize: '1.1vw',
      '@media screen and (max-width: 750px) and (orientation:landscape)':{
        fontSize: 8
      }
    }
  },
  checkboxContainer: {
    flex: '0 0 9vw',
    border: 'unset',
    background: 'unset',
    marginLeft: '-1vw',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      flex: 1
    }
  },
  hintContainer: {
    flex: '1 1 auto',
    marginRight: 10
  },
  helperText:{
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: '2.5vw',
    fontSize: '1.1vw',
    '@media screen and (max-width: 750px) and (orientation:landscape)':{
      fontSize: 10
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      marginBottom: '1.1vw'
    },
    '& span':{
      fontWeight: 400,
      display: 'block',
      marginTop: 5,
      '@media screen and (max-width: 750px) and (orientation:landscape)':{
        display: 'block',
      }
    }
  },
  dialog: {
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '70vw'
    }
  },
  headerRoot: {
    width: '28vw',
    position: 'relative',
    top: '-3vw',
    left: '15%',
    transform: 'translate(-50%, -50%)',
    marginBottom: '-5vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      top: '-3vh',
      marginBottom: '-10vh',
      left: '6vw',
      height: '15vh'
    }
  },
  headerText: {
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      fontSize: '3.4vh',
    }
  },
  buttonRoot: {
    width: '16vw',
    height: '4.4vw',
    marginTop: '3vw',
    marginBottom: '-7.5vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      marginTop: '3vh',
      marginBottom: '-15vh'
    }

  }
})

const disabledRootStyles = {
  opacity: '55%',
};

const disabledInnerStyles = {
  background: 'darkgrey'
};

const disabledOuterStyles = {
  background: 'black'
};
  
const disabledBorderStyles = {
  background: 'lightgrey'
};

const HelpfulHints = ({ hints, isBloop, helperText, buttonText, close, isOpen, bounce, callback }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const pointTotal = useSelector((state) => state.player.points);
  const helpfulHintsShowed = useSelector((state) => state.progress.helpfulHintsShowed);
  const initialRef = useRef();
  const { commitAndSaveProgress } = useScorm();

  const [isAllocatingPoints, setIsAllocatingPoints] = useState(false);

  const disableButton = useMemo(() => {
    return !hints.every((hint) => hint.id in helpfulHintsShowed);
  }, [hints, helpfulHintsShowed]);

  const allocatePoints = (points, id) => {
    if (disableButton) {
      setIsAllocatingPoints(true);
      dispatch(increasePoints(points));
      dispatch(setLastPointTotal(pointTotal + points));
      dispatch(setHelpfulHintsShowed(id))
      commitAndSaveProgress();
    }
  };

  return (
    <AnimatedTRDialog
      ariaLabel='Helpful hints' 
      isOpen={isOpen} 
      dismiss={() => null}
      close={close}
      initialFocusRef={initialRef}
      showBackButton
      dialogStyles={{
        background: 'transparent',
        padding: 0
      }}
      classes={{ dialog: classes.dialog }}
    >
      <SkewedDialogBox>
      <header>
        <OrangeHeader
          headerText="Helpful Hints"
          fontSize={{fontSize: "0.9vw"}}
          // rootStyles={orangeHeaderStyles}
          tabIndex={-1}
          classes={{ root: classes.headerRoot, text: classes.headerText }}
        />
        <p ref={initialRef} tabIndex={0} className={classes.helperText}>
          {helperText}
          <span>Select the tip to learn more and earn coins!</span>
        </p>
      </header>
      <section>
        <ul aria-label={'Collect all coins to continue the game.'}  className={classes.hints}>
          {hints.map((hint, idx) => (
            <li role="presentation" key={buttonText + idx} >
              <div className={classes.checkboxContainer}>
                <HelpfulHintCheckbox
                  callback={callback}
                  allocatePoints={allocatePoints}
                  points={hint.points}
                  hint={hint.text}
                  stack={false}
                  idx={idx}
                  id={hint.id}
                  // only bounce the first coin of the first set of helpful hints
                  bounce={(bounce === true) && idx === 0} 
                />
              </div>
            </li>
          ))}
        </ul>
        <div>
          <ButtonCustom 
            isBloop={isBloop}
            arialabeltext={'Continue game'}
            disabled={disableButton}
            disabledOuterStyles={disableButton ? disabledOuterStyles : {}} 
            disabledInnerStyles={disableButton ? disabledInnerStyles : {}} 
            disabledBorderStyles={disableButton ? disabledBorderStyles : {}} 
            onClick={close}
            buttonText={buttonText}
            classes={{ root: classes.buttonRoot }}
            overlayRootStyles={disableButton ? disabledRootStyles : {}}
          >
          </ButtonCustom>
        </div>
        <div className={classes.pointsContainer}>
          <PointsIncrementor 
            pointTotal={pointTotal}
            isOpen={isOpen}
            isAllocatingPoints={isAllocatingPoints}
            onRest={() => setIsAllocatingPoints(false)}
          />
        </div>
      </section>
      </SkewedDialogBox>
    </AnimatedTRDialog>
  );
};

HelpfulHints.propTypes = {
  hints: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired
    })
  ),
  helperText: PropTypes.string,
  buttonText: PropTypes.string,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default HelpfulHints;
