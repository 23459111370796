import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Portal from '@reach/portal';
import { createUseStyles } from 'react-jss';

const Julia = React.lazy(() => import('./JuliaOverlay'));
const Michael = React.lazy(() => import('./MichaelOverlay'));
const Sarah = React.lazy(() => import('./SarahOverlay'));
const Anthony = React.lazy(() => import('./AnthonyOverlay'));

const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    top: 'calc(50% - 21vw)',
    left: '11.6vw',
    height: '44vw', 
    width: '22vw',
    zIndex: 1000,
    pointerEvents: 'none',
    '@media screen and (max-width: 767px)': {
      display: 'none'
    },
    '@media screen and (max-height: 390px) and (orientation:landscape)':{
      display: 'none'
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      left: '4.4vw',
      top: 'calc(50% - 40vh)',
      height: '88vh'
    }
  },
})

const CharacterOverlay = ({ styles }) => {
  const classes = useStyles();
  const character = useSelector(state => state.player.character);

  return (
    <Portal>
      <div className={classes.root} style={styles}>
        <React.Suspense fallback={<div></div>}>
          {character === 'JULIA' ? <Julia /> : null}
          {character === 'MICHAEL' ? <Michael /> : null}
          {character === 'SARAH' ? <Sarah /> : null}
          {character === 'ANTHONY' ? <Anthony /> : null}
        </React.Suspense>
      </div>
    </Portal>
  );
};

CharacterOverlay.propTypes = {
  styles: PropTypes.object
};

export default CharacterOverlay;
