import React, { } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    width: '11vw',
    height: '6vw',
    filter: 'drop-shadow(5px 0px 5px rgba(0, 0, 0, 0.3))',
  },
  //
  outer_poligon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: 'inherit',
    height: 'inherit',
    background: 'white',
    boxSizing: 'border-box',
    clipPath: 'polygon(1% 2%, 100% 2%, 96% 98%, 0% 100%);',

  },
  //inside polygon
  inner_poligon: {
    position: 'relative',
    display: 'block',
    background: '#0dd9fd',
    width: 'calc(100% - 0.7vw)',
    height: 'calc(100% - 0.5vw)',
    clipPath: 'polygon(1% 2%, 100% 2%, 96% 98%, 0% 100%);',
  },
  //border polygon in the bottom
  inner_bottom_border: {
    width: '100%',
    height: '100%',
    background: '#13acfc',
    clipPath: 'polygon(5% 92%, 92% 92%, 100% 0, 100% 100%, 0 100%, 0 0)'
  },
  //text contant
  center: {
    position: 'absolute',
    display: 'flex',
    color: 'white',
    fontSize: '1.7vw',
    transform: 'translate(50%, -50%)',
    right: '50%',
    top: '50%',
  }
});

const SmallHeader = ({ headerText = 'OR', fontSize, rootStyles }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer} style={{ ...rootStyles }}>
      <div className={classes.outer_poligon}>
        <div className={classes.inner_poligon}>
          <div className={classes.inner_bottom_border}>
          </div>
          <div className={classes.center}>
            <h1 role='presentation'>{headerText}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmallHeader;