import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { toggleSoundsEffects, toggleMusic } from '../../store/action-creators'

const useStyles = createUseStyles({
  soundBtnRoot: {
    position: 'fixed',
    top: 10,
    width: '100vw',
    height: '30px',
    zIndex: 2,
  },
  soundBtn: {
    display: 'block',
    position: 'relative',
    fontSize: 14,
    padding: 10,
    borderRadius: '50%',
    margin: 10,
    backgroundColor: '#7500c0',
    color: '#ffffff',
    border: '2px solid #ffffff',
    boxSizing: 'border-box',
    boxShadow: '0 1px 2px rgba(0,0,0,0.3)',
    cursor: 'pointer',
  },
  slash: {
    position: 'absolute',
    top: 4,
    left: 16,
    width: 2,
    height: 26,
    backgroundColor: '#ffffff',
    borderLeft: '1px solid #7500c0',
    transformOrigin: 'center',
    transform: 'rotate(-45deg)',
    pointerEvents: 'none'
  },
  hidden: {
    visibility: 'hidden'
  }
})

const SoundSelectionButton = ({ modal }) => {
  const classes = useStyles();
  const isMusic = useSelector(state => state.settings.music);
  const soundsEffects = useSelector(state => state.settings.soundsEffects);
  const modalOpen = useSelector(state => state.settings.modalOpen);
  const splash = useSelector(state => state.progress.splash);
  const dispatch = useDispatch();

  const toggleMusicHandler = (evt) => {
    evt.stopPropagation();
    dispatch(toggleMusic(!isMusic))
  };

  const toggleSoundsEffectsHandler = (evt) => {
    evt.stopPropagation();
    dispatch(toggleSoundsEffects(!soundsEffects))
  };

  return (
    <div aria-hidden={splash} className={`${classes.soundBtnRoot} ${splash || (!modal && modalOpen) ? classes.hidden : ''}`}>
      <button
        className={classes.soundBtn}
        aria-label={`Background music toggle. Background music is ${isMusic ? 'Active' : 'Inactive'}`}
        tabIndex={0}
        //type='button'
        onClick={toggleMusicHandler}
      >
        <FontAwesomeIcon icon={faMusic} />
        <span className={`${classes.slash} ${isMusic ? classes.hidden : ''}`}></span>
      </button>
      <button
        className={classes.soundBtn}
        aria-label={`Sound effects toggle. Sound effects are ${soundsEffects ? 'Active' : 'Inactive'}.`}
        tabIndex={0}
        onClick={toggleSoundsEffectsHandler}
      >
        <FontAwesomeIcon icon={faVolumeUp} />
        <span className={`${classes.slash} ${soundsEffects ? classes.hidden : ''}`}></span>
      </button>
    </div>)
};

SoundSelectionButton.propTypes = {
  modal: PropTypes.bool
};

export default SoundSelectionButton;
