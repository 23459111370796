const lottieRanges = {
  // fwd: [0, 0],
  // fwd: '0deg',
  fwd: 'fwd',
  // fwd_rt: [0, 0],
  // fwd_rt: '-45deg',
  fwd_rt: 'fwd_rt',
  // rt: [0, 0],
  // rt: '-90deg',
  rt: 'rt',
  // bwd_rt: [0, 0],
  // bwd_rt: '-135deg',
  bwd_rt: 'bwd_rt',
  // bwd: [0, 0],
  // bwd: '180deg',
  bwd: 'bwd',
  // bwd_lt: [0, 0],
  // bwd_lt: '135deg',
  bwd_lt: 'bwd_lt',
  // lt: [0, 0],
  // lt: '90deg',
  lt: 'lt',
  // fwd_lt: [0, 0],
  // fwd_lt: '45deg',
  fwd_lt: 'fwd_lt',
};

export const steps = [
  { topic: '', restPct: 0, range: [1, 8] }, // 0 NOTE: Set restPct to non-zero value so that initially the character faces the player
  { topic: '', restPct: 3, range: [4, 7] }, // 1
  { topic: '', restPct: 5.4, range: [3, 6] }, // 2
  { topic: '', restPct: 8, range: [2, 5] }, // 3
  { topic: '', restPct: 10.6, range: [1, 4] }, // 4
  { topic: 'topic-1', restPct: 13.3, range: [4, 8] }, // 5 **
  { topic: 'topic-1', restPct: 16, range: [3, 8] }, // 6 **
  { topic: 'topic-1', restPct: 19.2, range: [2, 8] }, // 7 **
  { topic: 'topic-1', restPct: 22.4, range: [1, 8] }, // 8 **
  { topic: '', restPct: 25.4, range: [4, 7] }, // 9
  { topic: '', restPct: 28.6, range: [3, 6] }, // 10
  { topic: '', restPct: 31.4, range: [2, 5] }, // 11
  { topic: '', restPct: 34.3, range: [1, 4] }, // 12
  { topic: 'topic-2', restPct: 37, range: [4, 8] }, // 13 **
  { topic: 'topic-2', restPct: 39.1, range: [3, 8] }, // 14 **
  { topic: 'topic-2', restPct: 41.7, range: [2, 8] }, // 15 **
  { topic: 'topic-2', restPct: 44.5, range: [1, 8] }, // 16 **
  { topic: '', restPct: 46.8, range: [4, 7] }, // 17 
  { topic: '', restPct: 49.5, range: [3, 6] }, // 18 
  { topic: '', restPct: 52, range: [2, 5] }, // 19 
  { topic: '', restPct: 54.6, range: [1, 4] }, // 20  
  { topic: 'topic-3', restPct: 57, range: [4, 8] }, // 21 **
  { topic: 'topic-3', restPct: 59.5, range: [3, 8] }, // 22 **
  { topic: 'topic-3', restPct: 62.2, range: [2, 8] }, // 23 **
  { topic: 'topic-3', restPct: 64.7, range: [1, 8] }, // 24 **
  { topic: '', restPct: 67.2, range: [4, 7] }, // 25
  { topic: '', restPct: 70.1, range: [3, 6] }, // 26
  { topic: '', restPct: 73.5, range: [2, 5] }, // 27
  { topic: '', restPct: 76.5, range: [1, 4] }, // 28
  { topic: 'topic-4', restPct: 79.1, range: [8, 8] }, // 29 **
  { topic: 'topic-4', restPct: 81.2, range: [7, 7] }, // 30 **
  { topic: 'topic-4', restPct: 83.4, range: [6, 6] }, // 31 **
  { topic: 'topic-4', restPct: 86.3, range: [5, 5] }, // 32 **
  { topic: '', restPct: 89.4, range: [4, 4] }, // 33
  { topic: '', restPct: 91.6, range: [3, 3] }, // 34
  { topic: '', restPct: 94.3, range: [2, 2] }, // 35
  { topic: '', restPct: 96.8, range: [1, 1] }, // 36
  { topic: '', restPct: 99.9, range: [1, 1] }, // 37
];

export const lottieEvents = [
  { toPct: 0, cycle: lottieRanges.fwd },
  { toPct: 0.1, cycle: lottieRanges.fwd },
  { toPct: 3.6, cycle: lottieRanges.bwd_rt },
  { toPct: 5.4, cycle: lottieRanges.rt },
  { toPct: 10.6, cycle: lottieRanges.fwd_rt },
  { toPct: 13.8, cycle: lottieRanges.rt },
  { toPct: 15.1, cycle: lottieRanges.bwd_rt },
  { toPct: 16.6, cycle: lottieRanges.bwd },
  { toPct: 18.9, cycle: lottieRanges.bwd_lt },
  { toPct: 20, cycle: lottieRanges.bwd },
  { toPct: 21.4, cycle: lottieRanges.bwd_rt },
  { toPct: 28.2, cycle: lottieRanges.rt },
  { toPct: 29.1, cycle: lottieRanges.bwd_rt },
  { toPct: 30.3, cycle: lottieRanges.bwd },
  { toPct: 31.4, cycle: lottieRanges.bwd_lt },
  { toPct: 35.3, cycle: lottieRanges.lt },
  { toPct: 40.5, cycle: lottieRanges.bwd_lt },
  { toPct: 42.5, cycle: lottieRanges.lt },
  { toPct: 43.3, cycle: lottieRanges.fwd_lt },
  { toPct: 48.2, cycle: lottieRanges.fwd },
  { toPct: 49.2, cycle: lottieRanges.fwd_lt },
  { toPct: 51.2, cycle: lottieRanges.lt },
  { toPct: 51.7, cycle: lottieRanges.bwd_lt },
  { toPct: 54.1, cycle: lottieRanges.bwd },
  { toPct: 54.9, cycle: lottieRanges.bwd_lt },
  { toPct: 58.5, cycle: lottieRanges.lt },
  { toPct: 59.8, cycle: lottieRanges.fwd_lt },
  { toPct: 61.7, cycle: lottieRanges.fwd },
  { toPct: 62.1, cycle: lottieRanges.fwd_lt },
  { toPct: 64.7, cycle: lottieRanges.lt },
  { toPct: 65.8, cycle: lottieRanges.bwd_lt },
  { toPct: 69.3, cycle: lottieRanges.bwd },
  { toPct: 70.9, cycle: lottieRanges.bwd_lt },
  { toPct: 74.7, cycle: lottieRanges.lt },
  { toPct: 79.1, cycle: lottieRanges.fwd_lt },
  { toPct: 82, cycle: lottieRanges.fwd },
  { toPct: 83.8, cycle: lottieRanges.fwd_rt },
  { toPct: 88, cycle: lottieRanges.rt },
  { toPct: 89.7, cycle: lottieRanges.fwd_rt },
  { toPct: 91.2, cycle: lottieRanges.fwd },
  { toPct: 93.8, cycle: lottieRanges.fwd_lt },
  { toPct: 94.5, cycle: lottieRanges.fwd },
  { toPct: 95.4, cycle: lottieRanges.fwd_rt },
  { toPct: 98.7, cycle: lottieRanges.rt },
  { toPct: 100, cycle: lottieRanges.fwd_rt },
];
