export default {
  progress: {
    lastSpin: 1,
    currentSpin: 1,
    lastStep: 0,
    currentStep: 0,
    characterMoving: false,
    showedProfessorFirstInstructions: false,
    showedProfessorSecondInstructions: false,
    showedFunFact: false, // we're only showing one fun fact between each topic
    lastFunFactShowed: -1,  // indicates index when no funFacts have been shown
    helpfulHintsShowed: {},
    splash: false,
    currentPath: '/',
    dateOfCompletion:  ''
  },
  questions: {
    t01: {
      step: 'start',
      completed: false,
      qs: [
        { name: 'q1', score: 0, res: '' },
        { name: 'q2', score: 0, res: '' }
      ]
    },
    t02: {
      step: 'start',
      completed: false,
      qs: [
        { name: 'q1', score: 0, res: '' }
      ]
    },
    t03: {
      step: 'start',
      completed: false,
      qs: [
        { name: 'q1', score: 0, res: '' },
        { name: 'q2', score: 0, res: '' },
        { name: 'q3', score: 0, res: '' },
        { name: 'q4', score: 0, res: '' }
      ]
    },
    t04: {
      step: 'start',
      completed: false,
      qs: [
        { name: 'q1', score: 0, res: '' },
        { name: 'q2', score: 0, res: '' },
      ]
    },
  },
  player: {
    character: '',
    // character: 'JULIA', // hard-code for testing with route-guards 
    officeOrHome: '',
    travel: '',
    accentureArea: '',
    points: 0,
    lastPointTotal: 0, // used to enable decrementing points
    pointsByTopic: {
      t01: 0,
      t02: 0,
      t03: 0,
      t04: 0
    },
  },
  settings: {
    music: true,
    soundsEffects: true,
    replayMode: false,
    modalOpen: false
  }
};
