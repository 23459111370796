export function findLastIndex(array, predicate) {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array))
      return l;
  }
  return -1;
}

export const sfxPlayHandler = (ref, playFromStart = true) => {
  try { if(playFromStart) ref.current.currentTime = 0; } catch { }
  let playedPromise = ref.current.play();
  if (playedPromise) {
    playedPromise.catch((e) => {
      console.warn('Promise error', e)
    })
  }
}

export const setScoreInMachineContext = (ctx, assign, q, result, response) => {
  const questionIdx = ctx.qs.findIndex((qu) => qu.name === q);
  const newQuestion = { ...ctx.qs[questionIdx], score: result === 'correct' ? 1 : 0, res: response };
  ctx.qs = [
    ...ctx.qs.slice(0, questionIdx),
    newQuestion,
    ...ctx.qs.slice(questionIdx + 1)
  ];
  assign({ 
    qs: [
      ...ctx.qs.slice(0, questionIdx),
      newQuestion,
      ...ctx.qs.slice(questionIdx + 1)
    ]
  });
};

