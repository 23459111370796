import { Machine, actions } from 'xstate';
import { setScore, setLastStep, increasePoints, markTopicCompleted, decreasePoints, setTopicPointTotal } from '../store/action-creators';
import { setScoreInMachineContext } from '../utils/helpers';

const { assign } = actions;

export default function createTopicFourMachine(context) {
  // destructuring here to provide insight into the shape of context
  const {
    step,
    completed,
    qs,
    dispatch,
    scorm,
  } = context;

  return Machine({
    id: 'topicFour',
    initial: 'start',
    context: {
      step,
      completed,
      qs,
      dispatch,
      scorm,
      currentQuestionIndex: 0
    },
    states: {
      entry: [assign({ step: 'start' }), 'dispatchSetLastStep'],
      start: {
        after: {
          1000: [
            { target: 'lifeChoice', cond: 'shouldShowLifeChoice' },
            { target: 'firstHelpfulHints', cond: 'shouldShowFirstHelpfulHints' },
            { target: 'monitor.initialView', cond: 'shouldShowMonitorInitialView' },
            { target: 'monitor.loadScreen', cond: 'shouldShowMonitorLoadScreen' },
            { target: 'monitor.firstIconDialog', cond: 'shouldShowMonitorFirstIconDialog' },
            { target: 'monitor.firstText', cond: 'shouldShowMonitorFirstText' },
            { target: 'monitor.secondIconDialog', cond: 'shouldShowMonitorSecondIconDialog' },
            { target: 'monitor.browser', cond: 'shouldShowMonitorBrowser' },
            { target: 'monitor.secondText', cond: 'shouldShowMonitorSecondText' },
            { target: 'monitor.thirdIconDialog', cond: 'shouldShowMonitorThirdIconDialog' },
            { target: 'monitor.thirdText', cond: 'shouldShowMonitorThirdText' },
            { target: 'questionOne.awaitingInput', cond: 'shouldShowQuestionOne' },
            { target: 'questionOne.outcome', cond: 'shouldShowQuestionOneOutcome' },
            { target: 'questionTwo.awaitingInput', cond: 'shouldShowQuestionTwo' },
            { target: 'questionTwo.outcome', cond: 'shouldShowQuestionTwoOutcome' },
            { target: 'finished' }
          ]
        }
      },
      lifeChoice: {
        entry: [assign({ step: 'lifeChoice' }), 'dispatchSetLastStep'],
        on: {
          CONTINUE: {
            target: 'firstHelpfulHints'
          }
        }
      },
      firstHelpfulHints: {
        id: 'firstHelpfulHints',
        entry: [assign({ step: 'firstHelpfulHints' }), 'dispatchSetLastStep'],
        on: {
          CONTINUE: {
            target: '#monitor'
          },
          BACK: {
            target: 'lifeChoice' 
          }
        }
      },
      monitor: {
        id: 'monitor',
        initial: 'initialView',
        entry: [assign({ step: 'loadScreen' }), 'dispatchSetLastStep'],
        states: {
          initialView: {
            after: {
              1500: 'loadScreen'
            },
          },
          loadScreen: {
            on: {
              CONTINUE: {
                target: 'firstIconDialog'
              },
              BACK: {
                target: '#firstHelpfulHints',
                internal: false 
              }
            }
          },
          firstIconDialog: {
            entry: [assign({ step: 'firstIconDialog' }), 'dispatchSetLastStep'],
            on: {
              CONTINUE: {
                target: 'firstText'
              },
              BACK: {
                target: 'loadScreen' 
              }
            }
          },
          firstText: {
            entry: [assign({ step: 'firstText' }), 'dispatchSetLastStep'],
            on: {
              CONTINUE: {
                target: 'secondIconDialog'
              },
              BACK: {
                target: 'firstIconDialog' 
              }
            }
          },
          secondIconDialog: {
            entry: [assign({ step: 'secondIconDialog' }), 'dispatchSetLastStep'],
            on: {
              CONTINUE: {
                target: 'browser'
              },
              BACK: {
                target: 'firstText' 
              }
            }
          },
          browser: {
            entry: [assign({ step: 'browser' }), 'dispatchSetLastStep'],
            on: {
              CONTINUE: {
                target: 'secondText'
              },
              BACK: {
                target: 'secondIconDialog' 
              }
            }
          },
          secondText: {
            entry: [assign({ step: 'secondText' }), 'dispatchSetLastStep'],
            on: {
              CONTINUE: {
                target: 'thirdIconDialog'
              },
              BACK: {
                target: 'browser' 
              }
            }
          },
          thirdIconDialog: {
            entry: [assign({ step: 'thirdIconDialog' }), 'dispatchSetLastStep'],
            on: {
              CONTINUE: {
                target: 'thirdText'
              },
              BACK: {
                target: 'secondText' 
              }
            }
          },
          thirdText: {
            entry: [assign({ step: 'thirdText' }), 'dispatchSetLastStep'],
            on: {
              CONTINUE: [
                { target: '#questionTwo', cond: 'shouldSkipToQuestionTwo' },
                { target: '#questionOne' }
              ],
              BACK: {
                target: 'thirdIconDialog' 
              }
            }
          },
          //End monitor
        }
      },
      questionOne: {
        id: 'questionOne',
        entry: [assign({ step: 'questionOne' }), 'dispatchSetLastStep'],
        initial: 'awaitingInput',
        states: {
          awaitingInput: {
            on: {
              ANSWER: {
                target: 'outcome'
              },
              BACK: {
                target: '#monitor.thirdText',
                internal: false
              }
            }
          },
          outcome: {
            entry: [
              assign({ step: 'questionOneOutcome' }),
              'dispatchSetLastStep',
              'dispatchSetScore',
              'dispatchIncreasePoints', // allocate points in action here 
            ],
            on: {
              CONTINUE: {
                target: '#questionTwo'
              }
            }
          }
        }
      },
      questionTwo: {
        id: 'questionTwo',
        entry: [assign({ step: 'questionTwo' }), 'dispatchSetLastStep'],
        initial: 'awaitingInput',
        states: {
          awaitingInput: {
            on: {
              ANSWER: {
                target: 'outcome'
              },
              BACK: {
                target: '#monitor.thirdText',
                internal: false
              }
            }
          },
          outcome: {
            entry: [
              assign({ step: 'questionTwoOutcome' }),
              'dispatchSetLastStep',
              'dispatchSetScore',
              'dispatchIncreasePoints', // allocate points in action here 
            ],
            on: {
              CONTINUE: {
                target: '#finished'
              }
            }
          }
        }
      },
      finished: {
        id: 'finished',
        entry: [
          assign({ step: 'finished' }), 'dispatchSetLastStep',
        ],
        on: {
          CONTINUE: {
            target: 'final'
          }
        }
      },
      final: {
        entry: [
          'dispatchSetPointTotal', 
          'dispatchTopicFourComplete'
        ],
        type: 'final'
      }
    }
  },
    {
      actions: {
        dispatchSetScore: (ctx, evt) => {
          if (!evt || !evt.ans) return;

          const i = ctx.scorm.getInteractionCount(); // the interactionCount
          const {
            q, // the question key (e.g., 'q1)
            interactionType, // SCORM question type ('performance' for all of them)
            response, // user's input
            result, // must be either 'correct' or 'wrong'
            questionSet, // the topic key (e.g., 't01')
            objective, // a string (no white-space) describing the question
            ans // the correct answer
          } = evt;
          // we can take advantage of the fact that context is set first with assign
          setScoreInMachineContext(ctx, assign, q, result, response);
          ctx.dispatch(setScore({ questionSet, response, question: q, score: result === 'correct' ? 1 : 0 }));
          ctx.scorm.logInteraction({ i, q, interactionType, response, questionSet, objective, ans });
          ctx.scorm.logResult({ i, result });
        },
        dispatchSetLastStep: (ctx, evt) => {
          // we can take advantage of the fact that context is set first with assign
          ctx.dispatch(setLastStep('t04', ctx.step));
          ctx.scorm.commitAndSaveProgress();
        },
        dispatchSetPointTotal: (ctx) => {
          ctx.dispatch(setTopicPointTotal('t04'));
        },
        dispatchTopicFourComplete: (ctx, evt) => {
          ctx.dispatch(markTopicCompleted('t04'));
          ctx.scorm.commitAndSaveProgress();
        },
        dispatchIncreasePoints: (ctx, evt) => {
          if (!evt || !evt.result) return;

          if (evt.result === 'correct') {
            ctx.dispatch(increasePoints(evt.points));
          } else {
            ctx.dispatch(decreasePoints(evt.points / 2))
          }
          ctx.scorm.commitAndSaveProgress();
        }
      },
      guards: {
        shouldShowLifeChoice: (ctx) => {
          return ctx.step === 'start' || ctx.step === 'lifeChoice'; // will be updated once the state tree is built out and we want to save user progress
        },
        shouldShowFirstHelpfulHints: (ctx) => {
          return ctx.step === 'firstHelpfulHints'; // we use the previous step as a guide b/c we don't want to show firstHelpfulHints twice
        },
        shouldShowMonitorInitialView: (ctx) => {
          return ctx.step === 'loadScreen';
        },
        shouldShowMonitorLoadScreen: (ctx) => {
          return ctx.step === 'firstHelpfulHints' || ctx.step === 'loadScreen';
        },
        shouldShowMonitorFirstIconDialog: (ctx) => {
          return ctx.step === 'firstIconDialog';
        },
        shouldShowMonitorFirstText: (ctx) => {
          return ctx.step === 'firstText';
        },
        shouldShowMonitorSecondIconDialog: (ctx) => {
          return ctx.step === 'secondIconDialog';
        },
        shouldShowMonitorBrowser: (ctx) => {
          return ctx.step === 'browser';
        },
        shouldShowMonitorSecondText: (ctx) => {
          return ctx.step === 'secondText';
        },
        shouldShowMonitorThirdIconDialog: (ctx) => {
          return ctx.step === 'thirdIconDialog';
        },
        shouldShowMonitorThirdText: (ctx) => {
          return ctx.step === 'thirdText';
        },
        shouldShowQuestionOne: (ctx) => {
          return ctx.step === 'questionOne';
        },
        shouldShowQuestionOneOutcome: (ctx) => {
          return ctx.step === 'questionOneOutcome';
        },
        shouldSkipToQuestionTwo: (ctx) => {
          return !!ctx.qs[0].res;
        },
        shouldShowQuestionTwo: (ctx) => {
          return ctx.step === 'questionTwo';
        },
        shouldShowQuestionTwoOutcome: (ctx) => {
          return ctx.step === 'questionTwoOutcome';
        },
        isFinished: (ctx) => {
          return ctx.completed;
        }
      }
    })
}
