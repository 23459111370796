import React from 'react';
import PropTypes from 'prop-types';
import { OFFICE, HOME } from './constants';
import HeaderCustom from '../typography/OrangeHeader';
import { createUseStyles } from 'react-jss';
import ButtonCustom from '../inputs/ButtonCustom';
import SmallHeader from '../typography/SmallHeader';

const cardBaseStyles = {
  width: '23vw',
  height: '26vw',
  fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  textAlign: 'center',
  borderRadius: '27px',
  fontSize: '1.35vw',
}

const useStyles = createUseStyles({
  rootOffice: {
    ...cardBaseStyles,
    transform: 'rotate(-3deg)',
  },
  img: {
    width: '100%',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      height: '100%'
    },
  },
  rootHome: {
    ...cardBaseStyles,
    transform: 'rotate(3deg)',
  },
  container: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      justifyContent: 'end',
      paddingBottom: 0
    }
  },
  bottomContainer: {
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: '0 0 10px 10px',
    width: '100%',
    minHeight: '8vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      minHeight: '16vh'
    }
  },
  middleContainer: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: '-1.8vw',
    marginRight: '-2vw',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      marginLeft: '-1.5vw',
      marginRight: '-1.5vw',
    },
  },
  text: {
    position: 'relative',
    top: '1.8vw',
    padding: '0 10px',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      top: '3.6vh'
    }
  },
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonRoot: {
    width: '15vw',
    height: '5vw',
    bottom: '-4vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '30vh',
      height: '10vh',
      bottom: '-8vh'
    }
  },
  buttonText: {
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      fontSize: '4.2vh'
    }
  }

})

const OfficeOrHome = ({ makeSelection, ariaId }) => {
  const classes = useStyles();
  const innerPoligonStyles = {
    width: '97.5%'
  };

  return (
    <div>
      <HeaderCustom
        headerText={'Where do you expect to work most of the time?'}
        rootStyles={{
          paddingBottom: '1.3vw',
          width: '54vw',
          height: '5.5vw',
          position: 'relative',
          left: '50%',
          transform: 'translate(-50%, 0)',
        }}
        innerPoligonStyles={innerPoligonStyles}
        fontSize={{ fontSize: '0.9vw', textTransform: 'unset' }} />
      <div className={classes.root}>
        <div className={classes.rootOffice}>
          <div className={classes.container} >
            <img
              className={classes.img}
              src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/LifeChoice-1-Office.png'}
              alt=''
              role='presentation'
            />
            <div className={classes.bottomContainer}>
              <div aria-label={'office side'} className={classes.text} >
                In the office or at a client site
              </div>
              <ButtonCustom
                onClick={() => makeSelection('officeOrHome', OFFICE)}
                buttonText={'GO!'}
                arialabeltext={'I will work in the office.'}
                classes={{ root: classes.buttonRoot, buttonText: classes.buttonText }}
              />
            </div>
          </div>
        </div>
        <div className={classes.middleContainer}>
          <SmallHeader />
        </div>
        <div className={classes.rootHome}>
          <div className={classes.container} >
            <img
              className={classes.img}
              src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/LifeChoice-1-Home.png'}
              alt=''
            />
            <div className={classes.bottomContainer}>
              <div aria-label={'at home'} className={classes.text}>
                At home
              </div>
              <ButtonCustom
                onClick={() => makeSelection('officeOrHome', HOME)}
                buttonText={'GO!'}
                arialabeltext={'I will work at home.'}
                classes={{ root: classes.buttonRoot, buttonText: classes.buttonText }}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

OfficeOrHome.propTypes = {
  ariaId: PropTypes.string.isRequired,
  makeSelection: PropTypes.func.isRequired
};

export default OfficeOrHome;
