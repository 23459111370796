import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import QuestionDialog from '../../dialogs/QuestionDialog';
import QuestionOutcomeDialog from '../../dialogs/QuestionOutcomeDialog';
import FinishedTopicDialog from '../../dialogs/FinishedTopicDialog';
import { t01 } from '../../../data/questions';
import { TopicOneContext } from '../../../contexts/topicOneContext';
import { OFFICE, HOME } from '../../life-choices/constants';
import CharacterOverlay from '../../overlays/CharacterOverlay';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  }
});

const questionOne = t01.q1;
const questionTwo = t01.q2;

const choiceBackgroundMap = {
  [OFFICE]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Office-01.png',
  [HOME]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Home-01.png'
};

const TopicOneQuestionView = (props) => {
  const classes = useStyles();
  const { machineState, send } = useContext(TopicOneContext);
  const officeOrHome = useSelector((state) => state.player.officeOrHome)

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${choiceBackgroundMap[officeOrHome] || choiceBackgroundMap[OFFICE]})`
      }}
    >
      <QuestionDialog
        send={send}
        isOpen={machineState.matches('questionOne.awaitingInput')}
        ariaLabel={'question one of topic one'}
        topic='t01'
        q={questionOne.id}
        prompt={questionOne.prompt}
        objective={questionOne.objective}
        options={questionOne.options}
        ans={questionOne.ans}
        points={questionOne.points}
        questionType={questionOne.type}
      />
      {machineState.matches('questionOne.outcome') &&
        <QuestionOutcomeDialog
          isOpen={machineState.matches('questionOne.outcome')}
          close={() => send('CONTINUE')}
          topic='t01'
          q={questionOne.id}
          points={questionOne.points}
          feedbackCorrect={questionOne.feedbackCorrect}
          feedbackIncorrect={questionOne.feedbackIncorrect}
          ans={questionOne.ans}
        />
      }
      <QuestionDialog
        send={send}
        isOpen={machineState.matches('questionTwo.awaitingInput')}
        ariaLabel={'question two of topic one'}
        topic='t01'
        q={questionTwo.id}
        prompt={questionTwo.prompt}
        objective={questionTwo.objective}
        options={questionTwo.options}
        ans={questionTwo.ans}
        points={questionTwo.points}
        questionType={questionTwo.type}
      />
      {machineState.matches('questionTwo.outcome') &&
        <QuestionOutcomeDialog
          isOpen={machineState.matches('questionTwo.outcome')}
          close={() => send('CONTINUE')}
          topic='t01'
          q={questionTwo.id}
          points={questionTwo.points}
          feedbackCorrect={questionTwo.feedbackCorrect}
          feedbackIncorrect={questionTwo.feedbackIncorrect}
          ans={questionTwo.ans}
        />
      }
      <FinishedTopicDialog
        isOpen={machineState.matches('finished')}
        ariaLabel={'That\'s it for questions on passwords, let\'s head back to the gameboard...'}
        text={'That\'s it for questions on passwords, let\'s head back to the gameboard...'}
        buttonText='Play on!'
        close={() => send('CONTINUE')}
      />
      <CharacterOverlay />
    </div>
  );
};

TopicOneQuestionView.propTypes = {};

export default TopicOneQuestionView;
