import React, { useRef, useEffect  } from 'react';
//import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { backgroundMusicSrc } from '../../data/audioMap';
import { sfxPlayHandler } from '../../utils/helpers';

const AudioPlayer = (props) => {
  const mounted = useRef(false);
  const playedOnce = useRef(false);
  const audioRef = useRef();
  const isMusic = useSelector(state => state.settings.music);

  useEffect(() => {
		const audioOnLoadCLick = () => {
      if(isMusic) {
        sfxPlayHandler(audioRef, false);
      }
      playedOnce.current = true;
      document.removeEventListener('click', audioOnLoadCLick);
		};

    if (!mounted.current) {
      audioRef.current.volume = 0.5;
      audioRef.current.loop = true;
      document.addEventListener('click', audioOnLoadCLick)
			mounted.current = true;
    }
  }, [isMusic]);

  useEffect(() => {
    if (playedOnce.current) {
      isMusic ? sfxPlayHandler(audioRef, false) : audioRef.current.pause()
    }
  }, [isMusic])

  return (
    <audio id='music' ref={audioRef} src={backgroundMusicSrc} type='audio' />
  );
};

AudioPlayer.propTypes = {};

export default AudioPlayer;
