import React, { useContext, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { TopicOneContext } from '../../../contexts/topicOneContext';
import { useSelector } from 'react-redux';
import ButtonCustom from '../../inputs/ButtonCustom';
import { outlookAvatarSourceMap } from '../../../data/character'
import FocusLock from "react-focus-lock";
import useInitialFocus from '../../../utils/hooks/useInitialFocus';

const useStyles = createUseStyles({

  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'absolute',
    width: '40%',
    // border: '1px solid red',
    boxSizing: 'border-box',
    transform: 'translateX(-75%)',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      left: 0,
      transform: 'none',
    },
    '-webkit-transition': 'transform 500ms cubic-bezier(0.360, 0.480, 0.175, 1.120)',
    '-moz-transition': 'transform 500ms cubic-bezier(0.360, 0.480, 0.175, 1.120)',
    '-o-transition': 'transform 500ms cubic-bezier(0.360, 0.480, 0.175, 1.120)',
    transition: 'transform 500ms cubic-bezier(0.360, 0.480, 0.175, 1.120)', /* custom */
    fallbacks: {
      '-webkit-transition': 'transform 500ms cubic-bezier(0.360, 0.480, 0.175, 1)', /* older webkit */
    },
    '& h4': {
      fontSize: '2vw',
      textTransform: 'uppercase',
      color: 'white',
      marginTop: '6%',
      marginBottom: '6%',
    },
    '& img': {
      height: '10vw',
      width: '10vw',
      borderRadius: '50%',
      boxShadow: '0px 0px 12px 1px #0000006e',
    }
  },
  centered: {
    transform: 'translateX(0%)',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      left: 0,
      transform: 'translateX(75%)',
    },
  },
  interactionContainer: {
    width: '60%',
    marginLeft: '40%',
    marginBottom: 20,
    opacity: 1,
    transition: 'opacity 300ms 800ms',
    position: 'relative',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    '&.hidden': {
      opacity: 0,
    },
    '& p': {
      color: 'white',
      textAlign: 'center',
      padding: '0 50px',
      fontWeight: 600,
      maxWidth: 400,
      fontSize: '1.2vw',
      lineHeight: 1.3
    }
  },
  inputContainer: {
    width: '20vw',
    height: '4vw',
    marginBottom: 10
  },
  outer_poligon_username: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: 'inherit',
    height: 'inherit',
    background: 'white',
    boxSizing: 'border-box',
    clipPath: 'polygon(0 0, 100% 0%, 97% 100%, 1% 100%)',

  },
  inner_poligon_username: {
    position: 'relative',
    display: 'block',
    backgroundImage: 'linear-gradient(to right, #00baff , #17d2fd)',
    width: '96.5%',
    height: '83%',
    clipPath: 'polygon(0 0, 100% 0%, 97% 100%, 1% 100%)',
  },
  outer_poligon_password: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: 'inherit',
    height: 'inherit',
    background: 'white',
    boxSizing: 'border-box',
    clipPath: 'polygon(0 0, 100% 0%, 99% 99%, 3% 98%)',

  },
  inner_poligon_password: {
    position: 'relative',
    display: 'block',
    backgroundImage: 'linear-gradient(to right, #00baff , #17d2fd)',
    width: '96.5%',
    height: '83%',
    clipPath: 'polygon(0 0, 100% 0%, 99% 99%, 3% 98%)',
  },
  center: {
    position: 'relative',
    textAlign: 'center',
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '1.2vw',
    paddingLeft: '10px',
    paddingRight: '10px',
    boxSizing: 'border-box',
    '& span': {
      fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    }
  },
  negMargin: {
    marginTop: '4%'
  },
  pText: {
    color: 'white',
    fontSize: '2.2vw',
    fontWeight: 600,
    width: '70%',
    lineHeight: 1.3,
  },
  textBox: {
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      width: '100%',
      textAlign: 'center'
    },
  },
});

const overlayRootButtonStyles = {
  width: '16vw',
  height: '4.4vw',
  marginTop: '10px'
};

const ThirdText = ({ onClick }) => {
  const classes = useStyles();
  const lastText = useInitialFocus();

  return (
    <>
      <p
        ref={lastText}
        tabIndex={0}
        className={classes.pText}
      >
        Use the tips you just learned to answer the following questions to keep playing.
      </p>
      <ButtonCustom
        buttonText='Next!'
        overlayRootStyles={overlayRootButtonStyles}
        onClick={onClick}
      />
    </>
  )
};

const LoginScreen = ({ SFXOnClick }) => {
  const classes = useStyles();
  const character = useSelector((state) => state.player.character);
  const { machineState, send } = useContext(TopicOneContext);

  const hintText = useMemo(() => {
    switch (true) {
      case machineState.matches('monitor.firstText'):
        return <>You should always make sure that passwords are complex and unique. Remember, with so much personal information available to hackers from past breaches, you shouldn't use a password based on personal information that is readily available. It's also important to change your password frequently. <br/><br/>Accenture will never ask you for your password via email, text, or instant message. If your password is ever needed to resolve a technical support issue, you will be asked to enter it into a secure web portal.</>;
      case machineState.matches('monitor.secondText'):
        return <>It is tempting to recycle passwords to make them easier to remember, but you should never use the same password for Accenture work and for personal sites (social media or online shopping). <br/><br/>Also, be sure to only use your Accenture email for work purposes and your personal email for sites that are not business related.</>;
      default:
        return '';
    }
  }, [machineState]);

  const handleBtnClick = () => {
    SFXOnClick();
    send('CONTINUE')
  }

  return (
    <>
      {machineState.matches('monitor.thirdText')
        ? <ThirdText onClick={handleBtnClick} />
        : <>

          <div role='alert' className={`${classes.loginContainer} ${machineState.matches('monitor.initialView') ? classes.centered : ''}`}>
            <img src={(outlookAvatarSourceMap[character] && outlookAvatarSourceMap[character].src) || ''} alt={`${character ? character: 'Character'} avatar graphic`} />
            <h4 role='application' aria-labelledby='Login'>
              Login
            </h4>
            <div className={classes.inputContainer}>
              <div className={classes.outer_poligon_username}>
                <div className={classes.inner_poligon_username}>
                </div>
              </div>
              <div className={classes.center}>
                <span>username</span>
              </div>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.outer_poligon_password}>
                <div className={classes.inner_poligon_password}>
                </div>
              </div>
              <div className={classes.center}>
                <span className={classes.negMargin}>********</span>
              </div>
            </div>
          </div>
          {
            <div className={`${classes.interactionContainer} ${machineState.matches('monitor.initialView') ? 'hidden' : ''}`}>
              <p style={{ margin: '0 auto 10px' }} tabIndex={0} aria-live={'polite'}>{hintText}</p>
              {hintText 
                ? <ButtonCustom 
                    isBloop={false} 
                    arialabeltext={'Continue game and start questionnaire'} 
                    buttonText='Got it!' 
                    overlayRootStyles={overlayRootButtonStyles} 
                    onClick={handleBtnClick}
                  />
                : null}
            </div>
          }
        </>
      }
    </>
  );
};

LoginScreen.propTypes = {

};

export default LoginScreen;
