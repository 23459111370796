import { Machine, actions } from 'xstate';
import { steps } from '../data/steps';
// import { findLastIndex } from '../utils/helpers';
import { setShowedProfessorInstructions, markTopicCompleted, setSpinAndStep, setCharacterMoving } from '../store/action-creators';

const { assign } = actions;

export default function createBoardMachine(context) {
  // destructuring here to provide some insight into the shape of context
  const {
    lastSpin,
    currentSpin,
    lastStep,
    currentStep,
    showedProfessorFirstInstructions,
    showedProfessorSecondInstructions,
    dispatch,
    scorm,
    questions,
  } = context;

  return Machine(
    {
      id: 'board',
      initial: 'stationary',
      context: {
        lastSpin,
        currentSpin,
        lastStep,
        currentStep,
        showedProfessorFirstInstructions,
        showedProfessorSecondInstructions,
        dispatch,
        scorm,
        questions,
        showedFunFact: false,
        characterMoving: false
      },
      states: {
        stationary: {
          on: {
            START: [
              { target: 'professorFirstInstructions', cond: 'shouldShowProfessorFirstInstructions' },
              { target: 'professorSecondInstructions', cond: 'shouldShowProfessorSecondInstructions' },
              { target: 'claimBadge', cond: 'shouldShowClaimBadge' },
              { target: 'lifeChoice', cond: 'shouldShowLifeChoice' },
              { target: 'awaitingInput' }
            ]
          }
        },
        professorFirstInstructions: {
          on: {
            CONTINUE: [
              {
                target: 'claimBadge',
                cond: 'shouldShowClaimBadge',
                actions: [
                  assign({ showedProfessorFirstInstructions: true }),
                  'setProfessorFirstInstructionsShown'
                ]
              },
              {
                target: 'lifeChoice',
                cond: 'shouldShowLifeChoice',
                actions: [
                  assign({ showedProfessorFirstInstructions: true }),
                  'setProfessorFirstInstructionsShown'
                ]
              },
              {
                target: 'awaitingInput',
                actions: [
                  assign({ showedProfessorFirstInstructions: true }),
                  'setProfessorFirstInstructionsShown'
                ]
              }
            ]
          }
        },
        professorSecondInstructions: {
          on: {
            CONTINUE: [
              {
                target: 'claimBadge',
                cond: 'shouldShowClaimBadge',
                actions: [
                  assign({ showedProfessorSecondInstructions: true }),
                  'setProfessorSecondInstructionsShown'
                ]
              },
              {
                target: 'lifeChoice',
                cond: 'shouldShowLifeChoice',
                actions: [
                  assign({ showedProfessorSecondInstructions: true }),
                  'setProfessorSecondInstructionsShown'
                ]
              },
              {
                target: 'awaitingInput',
                actions: [
                  assign({ showedProfessorSecondInstructions: true }),
                  'setProfessorSecondInstructionsShown'
                ]
              }
            ]
          }
        },
        awaitingInput: {
          id: 'awaitingInput',
          on: {
            SPIN: {
              target: 'spinning',
              actions: [
                assign({
                  characterMoving: true,
                  lastSpin: (ctx) => ctx.currentSpin,
                  currentSpin: (_, evt) => evt.value,
                  lastStep: (ctx) => ctx.currentStep,
                  currentStep: (ctx, evt) => ctx.currentStep + evt.value
                }),
                'dispatchSetCharacterMoving',
                'dispatchSetSpinAndStep'
              ]
            }
          }
        },
        spinning: {
          on: {
            COMPLETED: {
              target: 'spinnerIsReturning'
            }
          }
        },
        spinnerIsReturning: {
          after: {
            1500: {
              target: 'characterMoving'
            }
          }
        },
        characterMoving: {
          on: {
            COMPLETED: [
              { 
                target: 'lifeChoice', cond: 'shouldShowLifeChoice', 
                actions: [assign({ characterMoving: false }), 'dispatchSetCharacterMoving'] 
              },
              { 
                target: 'gameTopic', cond: 'shouldShowGameTopic', 
                actions: [assign({ characterMoving: false }), 'dispatchSetCharacterMoving'] 
              },
              { 
                target: 'funFact', cond: 'shouldShowFunFact', 
                actions: [assign({ characterMoving: false }), 'dispatchSetCharacterMoving']
              },
              { 
                target: 'awaitingInput', 
                actions: [assign({ characterMoving: false }), 'dispatchSetCharacterMoving'] 
              }
            ],
            CANCEL: {
              target: 'awaitingInput',
              actions: [assign({ characterMoving: false })]
            }
          }
        },
        lifeChoice: {
          on: {
            SELECT: {
              target: 'gameTopic'
            }
          }
        },
        funFact: {
          // onEntry: assign({ showedFunFact: true }),
          on: {
            CLOSE: [
              {
                target: 'professorSecondInstructions',
                cond: 'shouldShowProfessorSecondInstructions',
                actions: [assign({ showedFunFact: true })]
              },
              {
                target: 'awaitingInput',
                actions: [assign({ showedFunFact: true })]
              },
            ]
          }
        },
        claimBadge: {
          on: {
            CLAIM: [
              {
                target: 'awaitingInput',
                actions: 'setCurrentTopicBadgeClaimed'
              },
            ]
          }
        },
        gameTopic: {
          type: 'final'
        },
        gameComplete: {
          type: 'final'
        }
      }
    },
    {
      actions: {
        logIt: (ctx, evt) => {
          console.log('ctx:', ctx);
          console.log('evt:', evt);
        },
        dispatchSetCharacterMoving: (ctx, evt) => {
          ctx.dispatch(setCharacterMoving(ctx.characterMoving));
        },
        dispatchSetSpinAndStep: (ctx, evt) => {
          ctx.dispatch(setSpinAndStep(evt.value));
        },
        setProfessorFirstInstructionsShown: (ctx) => {
          ctx.dispatch(setShowedProfessorInstructions('first'));
          ctx.scorm.commitAndSaveProgress();
        },
        setProfessorSecondInstructionsShown: (ctx) => {
          ctx.dispatch(setShowedProfessorInstructions('second'));
          ctx.scorm.commitAndSaveProgress();
        },
        setCurrentTopicBadgeClaimed: (ctx) => {
          if (ctx.questions.t01.step === 'finished') {
            if ((ctx.questions.t02.step === 'finished')&&(!ctx.questions.t02.completed)) {
              ctx.dispatch(markTopicCompleted('t02'));
            }
            else if ((ctx.questions.t03.step === 'finished') && (!ctx.questions.t03.completed)) {
              ctx.dispatch(markTopicCompleted('t03'));
            }
            else if ((ctx.questions.t04.step === 'finished') && (!ctx.questions.t04.completed)) {
              ctx.dispatch(markTopicCompleted('t04'));
            }
          }
          ctx.scorm.commitAndSaveProgress();
        }
      },
      guards: {
        shouldShowLifeChoice: (ctx) => {
          const { currentStep } = ctx;

          if (steps[currentStep] && !!steps[currentStep].topic) {
            let t = 't0' + steps[currentStep].topic.slice(-1);
            if (ctx.questions[t].step !== 'start') {
              return false;
            }
            if (steps[currentStep].topic === 'topic-2') {
              return false;
            }
            return true;
          }
          return false;
          // return steps[currentStep] && !!steps[currentStep].topic;
        },
        shouldShowGameTopic: (ctx) => {
          const { currentStep } = ctx;
          return steps[currentStep] && !!steps[currentStep].topic;
        },
        shouldShowFunFact: (ctx) => {
          // return false
          const { currentStep, showedFunFact } = ctx;
          return steps[currentStep] && !steps[currentStep].topic && !showedFunFact;
        },
        shouldShowProfessorFirstInstructions: (ctx) => {
          const { showedProfessorFirstInstructions } = context;
          return !showedProfessorFirstInstructions;
        },
        shouldShowProfessorSecondInstructions: (ctx) => {
          const { showedProfessorSecondInstructions } = context;
          return !showedProfessorSecondInstructions;
        },
        shouldShowClaimBadge: (ctx) => {
          //check if not completed too ?
          return (ctx.questions.t02.step === 'finished' ||
            ctx.questions.t03.step === 'finished' ||
            ctx.questions.t04.step === 'finished')
        }
      }
    }
  );
}
