import React, { useContext, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { TopicTwoContext } from '../../../contexts/topicTwoContext';
import { helpfulHintsTopicTwo } from '../../../data/hints';
import HelpfulHints from '../../dialogs/HelpfulHints';
import AnimatedDialogWithIcon from '../../dialogs/AnimatedDialogWithIcon';
import InnerScreenView from '../InnerScreenView';
import ButtonCustom from '../../inputs/ButtonCustom';
import { OFFICE, HOME } from '../../life-choices/constants';
import useInitialFocus from '../../../utils/hooks/useInitialFocus';


const choiceBackgroundMap = {
  [OFFICE]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/ComputerOffice-02.png',
  [HOME]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/ComputerHome-02.png'
};

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  },
  background: {
    boxSizing: 'border-box',
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    flex: '50%'
  },
  leftSide: {
    flex: 1.4,
  },
  rightSide: {
    flex: 1,
    height: '100%',
    flexGrow: 1,
    '-webkit-flex-grow': 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'left',
    paddingLeft: 20,
    fontSize: '1.35vw',
    '& > div:first-child': {
      marginBottom: '2vw'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      alignItems: 'stretch'
    }
  },
  center: {
    textAlign: 'center',
    color: 'white',
    fontSize: '2.2vw',
    fontWeight: 600,
    width: '70%',
    lineHeight: 1.3,
    marginTop: '3vw',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      width: '100%'
    },
  },
  list: {
    fontSize: '1.35vw',
    fontFamily: '\'Graphik Regular\', Arial, sans-serif',
    fontWeight: 600,
    lineHeight: 1.3,
    textAlign: 'left',
    maxWidth: '80%',
    margin: '20px auto 0'
  }
});

const TopicTwoInitialView = ({ choice = 'OFFICE', audioClickHandlerT2 }) => {
  const classes = useStyles();
  const { machineState, send } = useContext(TopicTwoContext);
  const firstText = useInitialFocus();
  const focusText = useInitialFocus();
  const secondText = useInitialFocus();

  const close = useCallback(() => {
    audioClickHandlerT2();
    send('CONTINUE');
  }, [send, audioClickHandlerT2]);

  const showOutlook =
    machineState.matches('initialOutlookScreen') ||
    machineState.matches('secondOutlookScreen') ||
    machineState.matches('firstHelpfulHints') ||
    machineState.matches('firstIconDialog') ||
    machineState.matches('secondIconDialog') ||
    machineState.matches('thirdIconDialog');

  const textOne = [
    `Wow! You have a lot of emails. Be sure to stay vigilant. Not everyone sending you emails has your best interests in mind.`,
    `At Accenture, external emails are given a tag and header to help distinguish these emails from internal ones. Be on the lookout for suspicious emails, senders, links and attachments, especially when dealing with external emails.`,
    `If you receive an email that doesn't look like it came from a legitimate sender, use the Report Phishing button or simply delete the email.`,
  ];
  const textTwo = [
    `One of the most common types of mistakes people make that can cause an information security incident is a misdirected email (sending the wrong information or sending to the wrong person).`,
    `When sending attachments or links, take an extra 10 seconds to verify that you are sending the right information to the correct person.`,
  ]

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `${choiceBackgroundMap[choice] || choiceBackgroundMap['OFFICE']}`
      }}
    >
      <InnerScreenView backgroundImage={choiceBackgroundMap[choice]}>
        <div className={classes.background}>
          {// Left outlook img
            showOutlook ?
              <div className={classes.leftSide}>
                {machineState.matches('initialOutlookScreen')
                  ? <img style={{ width: '100%' }} src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Outlook-02.png'} alt='' />
                  : <img style={{ width: '100%' }} src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Outlook-01.png'} alt='' />
                }
              </div> : ''
          }
          <div className={classes.rightSide}>
            {
              machineState.matches('initialOutlookScreen') ?
                <>
                  <div style={{ fontSize: '1.3vw' }}>
                    {textOne.map((i, idx) => {
                      return <p key={idx} ref={idx === 0 ? firstText : null} tabIndex={0}>{i}</p>
                    })}
                  </div>
                  <div>
                    <ButtonCustom isBloop={false} onClick={close} buttonText={'GOT IT!'} />
                  </div>
                </> : <></>
            }
            {
              machineState.matches('secondOutlookScreen') ? <>
                <div>
                  {textTwo.map((i, idx) => {
                    return <p key={idx} ref={idx === 0 ? focusText : null} tabIndex={0}>{i}</p>
                  })}
                </div>
                <div>
                  <ButtonCustom isBloop={false} onClick={close} buttonText={'GOT IT!'} />
                </div>
              </> : <></>
            }
            {
              machineState.matches('preQuestionsScreen') ? <>
                <div ref={secondText} tabIndex={0} className={classes.center}>
                  {`Now let’s see if you can apply what you’ve learned! It looks like your manager, Sombir Budhwar, wants you to send over the PowerPoint from the Monjardin account to a colleague working on another client account.`}
                </div>
                <div>
                  <ButtonCustom isBloop={false} onClick={close} buttonText={'Let\'s go!'} />
                </div>
              </> : <></>
            }
          </div>
        </div>
      </InnerScreenView>
      <HelpfulHints
        hints={helpfulHintsTopicTwo}
        buttonText='Next!'
        isOpen={machineState.matches('firstHelpfulHints')}
        close={close}
        callback={audioClickHandlerT2}
        isBloop={false}
    />

      {/*
      <AnimatedDialogWithIcon
        ariaLabel='firstIconDialog'
        buttonText='GOT IT!'
        isOpen={machineState.matches('firstIconDialog')}
        close={close}
        boardSize={'55vw'}
        title={'Oh new email!'}
        iconSrc='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Email-01.png'
        isBloop={false}
        showBackButton
      >
        <p tabIndex={0}>It looks like your manager, Sombir Budhwar, wants you to send over the PowerPoint from the Monjardin account to a colleague working on another client account. Let’s scrub the document and then we can send it over</p>
      </AnimatedDialogWithIcon>
      */}

      <AnimatedDialogWithIcon
        ariaLabel='secondIconDialog'
        buttonText='NEXT!'
        isOpen={machineState.matches('secondIconDialog')}
        close={close}
        boardSize={'55vw'}
        iconSrc='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Social-01.png'
        isBloop={false}
        showBackButton
      >
        <p tabIndex={0}>Before emailing or sharing deliverables with people outside your project team, make sure Accenture has the right to share it and make sure that you scrub sensitive data such as:</p>
        <ul role='group' tabIndex={0} className={classes.list}>
          <li>Client name</li>
          <li>Client logo</li>
          <li>Client location</li>
          <li>Sensitive financial data</li>
          <li>Be especially careful to check hidden columns in Excel and speaker notes in PowerPoint</li>
        </ul>
        <p tabIndex={0}> This applies when sharing information at work and at home. When posting on social media, do not mention client name, locations or other sensitive data.</p>
      </AnimatedDialogWithIcon>

      <AnimatedDialogWithIcon
        ariaLabel='thirdIconDialog'
        buttonText='NEXT!'
        isOpen={machineState.matches('thirdIconDialog')}
        close={close}
        boardSize={'55vw'}
        iconSrc='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Lock-01.png'
        isBloop={false}
        showBackButton
      >
        <p tabIndex={0}>When checking your inbox, be on the lookout for these common phishing indicators:</p>
        <ul role='group' tabIndex={0} className={classes.list} style={{ marginBottom: 20 }}>
          <li>Unsolicited requests for sensitive information</li>
          <li>Unfamiliar email addresses</li>
          <li>Links to unknown websites</li>
          <li>Suspicious attachments</li>
          <li>Generic imagery</li>
          <li>Spelling and grammatical errors</li>
        </ul>
      </AnimatedDialogWithIcon>
    </div>
  )
}

export default TopicTwoInitialView;
