import BackButton from '../components/inputs/BackButton';
import React, { useContext, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPath } from '../store/action-creators';
import { createUseStyles } from 'react-jss';
import { TopicFourContextProvider, TopicFourContext } from '../contexts/topicFourContext';
import TopicFourNonScreenView from '../components/topics/topic-4/TopicFourNonScreenView';
import TopicFourScreenView from '../components/topics/topic-4/TopicFourScreenView';
import ExitCourseButton from '../components/inputs/ExitCourseButton';

import CharacterOverlay from '../components/overlays/CharacterOverlay';
import { audioClickMap } from '../data/audioMap';
import { sfxPlayHandler } from '../utils/helpers';

const useStyles = createUseStyles({
  viewContainer: {
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto', // necessary for InnerScreenView component to scroll on very wide screens
    backgroundColor: '#ddffff'
  }
})

const TopicFourContents = () => {
  const classes = useStyles();
  const { machineState } = useContext(TopicFourContext);
  const dispatch = useDispatch();
  const audioClickRef = useRef();
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  useEffect(() => {
    if (machineState.matches('final')) {
      // history.push('/board');
      dispatch(setCurrentPath('/board'));
    }
  }, [machineState, dispatch]);

  const showTopicFourScreenView = useMemo(() => {
    return machineState.matches('monitor');
  }, [machineState]);

  const audioClickHandlerT4 = () => {
    try {
      if (soundsEffects) {
        audioClickRef.current.pause();
        sfxPlayHandler(audioClickRef)
      }
    } catch { }
  };

  return (
    <main>
      <section className={classes.viewContainer}>
        <audio id='musict4' ref={audioClickRef} src={audioClickMap[0]} type='audio' />
        {!showTopicFourScreenView && <TopicFourNonScreenView />}
        {showTopicFourScreenView && <TopicFourScreenView audioClickHandlerT4={audioClickHandlerT4} />}
      </section>
      {(machineState.matches('questionOne') || machineState.matches('questionTwo') || machineState.matches('finished')) ? <CharacterOverlay /> : null}
      <BackButton hide={machineState.matches('start')}/>
    </main>
  );
};


const TopicFour = (props) => {
  return (
    <TopicFourContextProvider>
      <TopicFourContents />
      <ExitCourseButton />
    </TopicFourContextProvider>
  );
};

export default TopicFour;
