import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import createTopicThreeMachine from '../machines/topicThreeMachine';
import useScorm from '../utils/hooks/useScorm';

/*---------------------------------*/

const TopicThreeContext = React.createContext([{}, {}]);

/*---------------------------------*/


const TopicThreeContextProvider = (props) => {
  const topicThreeState = useSelector((state) => state.questions.t03);
  const dispatch = useDispatch();
  const scorm = useScorm(); 
  const [machineState, send] = useMachine(createTopicThreeMachine({ ...topicThreeState, dispatch, scorm }), { devTools: true });

  useEffect(() => {
    send('START');
  }, [send]);

  return (
    <TopicThreeContext.Provider value={{ machineState, send }}>
      {props.children}
    </TopicThreeContext.Provider>
  )
};

TopicThreeContextProvider.propTypes = {
  
};

export { TopicThreeContext, TopicThreeContextProvider };