export default {
  colors: {
    acn: {
      lightPurple: '#a100ff',
      purple: '#7500c0',
      darkPurple: '#460073',
      lightBlue: '#00baff',
      blue: '#008eff',
      darkBlue: '#004dff',
      lightGrey: '#bebebe',
      grey: '#959595',
      darkGrey: '#5a5a5a'
    },
    game: {
      red: '#db3320',
      brown: '#a86c1f',
      yellow: '#ffb600',
      green: '#13ac3a',
      teal: '#25bac8',
      darkblue: '#374681',
      peach: '#eedac4'
    }
  },
};