import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';
import { createUseStyles } from 'react-jss';
import { audioDecreasing, audioIncreasing } from '../../data/audioMap';
import { useSelector } from 'react-redux';
import { sfxPlayHandler } from '../../utils/helpers';

// const isIE = /*@cc_on!@*/false || !!document.documentMode;

const useStyles = createUseStyles({
  root: {
    width: '100%',
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  points: {
    position: 'absolute',
    top: 'calc(50% - 0.6em)',
    left: (props) => typeof props.possibleTotal === 'number' ? '33%' : '37%',
    width: (props) => typeof props.possibleTotal === 'number' ? '66%': '50%',
    margin: 0,
    textAlign: 'center',
    fontSize: (props) => typeof props.possibleTotal === 'number' ? '1.4vw' : '1.6vw',
    '@media screen and (max-width: 767px)': {
      fontSize: (props) => typeof props.possibleTotal === 'number' ? 10 : 12
    },
    '@media screen and (min-width: 1920px)': {
      fontSize: '28px !important'
    },
    '& span': {
      fontFamily: 'inherit !important'
    }
  },
  hiddenReaderText: {
    position: 'absolute',
    left: -10000,
    top: 'auto',
    height: 1,
    width: 1,
    overflow: 'hidden'
  },
})

const PointsIncrementor = React.forwardRef(({ isOpen, pointTotal, possibleTotal, realPointTotal = pointTotal, isAllocatingPoints, onRest, tabIndex = 0 }, ref) => {
  const classes = useStyles({ possibleTotal });
  const lastPointTotal = useRef(pointTotal);
  const [springProps, set, stop] = useSpring(() => ({ points: pointTotal, immediate: true }));
//  const initFocus = useRef();
  const coinsAudioRef = useRef();
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  useEffect(() => {
    coinsAudioRef.current.volume = 0.4;
  }, [])

  useEffect(() => {
    if (isAllocatingPoints && pointTotal !== lastPointTotal.current) {

      stop();
      set({
        points: pointTotal,
        immediate: false,
        onRest: () => onRest()
      });
    }

    if (isOpen && !isAllocatingPoints) {
      stop();
      lastPointTotal.current = pointTotal;
      set({ points: pointTotal, onRest: () => null });
    }

    return () => stop();
  }, [set, stop, onRest, pointTotal, isOpen, isAllocatingPoints, soundsEffects]);

  useEffect(() => {
    try {
      if (pointTotal < lastPointTotal.current) {
        coinsAudioRef.current.src = audioDecreasing;
      } else {
        coinsAudioRef.current.src = audioIncreasing;
      }
      if (pointTotal !== lastPointTotal.current) {
        if (soundsEffects) { sfxPlayHandler(coinsAudioRef) }
      }
    } catch { }

  }, [pointTotal, soundsEffects])

  return (
    <div ref={ref} role='article' id='coins' tabIndex={tabIndex} aria-label={`Current points is ${realPointTotal}.`} className={classes.root}>
      <img src='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Coin_02.png' alt='' />
      <audio role='presentation' id='audioPoints' src={audioIncreasing} ref={coinsAudioRef} type='audio' />
      <h4 className={classes.points}>
        <animated.span aria-labelledby='coins'>
          {springProps.points.interpolate(x => x.toFixed(0))}
        </animated.span>
        {possibleTotal ? ` / ${possibleTotal}` : ''}
      </h4>
      {/*isAllocatingPoints 
        ? <p className={classes.hiddenReaderText} role='alert'>
          {`${realPointTotal > lastPointTotal.current ? 'Added' : 'Subtracted'} ${Math.abs(realPointTotal - lastPointTotal.current)} points, for a total of ${realPointTotal} points.`}
          </p> 
        : null*/}
    </div>
  );
});

PointsIncrementor.propTypes = {
  pointTotal: PropTypes.number.isRequired,
  possibleTotal: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  isAllocatingPoints: PropTypes.bool.isRequired,
  onRest: PropTypes.func.isRequired,
  tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default PointsIncrementor;
