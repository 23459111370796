import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  screenViewRoot: {
    padding: 0,
    width: '100vw',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: ({ props }) => `url(${props.backgroundImage})`,
    backgroundColor: 'rgba(255,225,225,0.6)' // temporary, should be background image
  },
  outerScreenContainer: {
    flex: '0 0 auto',
    position: 'relative',
    // border: '1px solid #ff0000',
    boxSizing: 'border-box',
    width: '77vw',
    margin: 0,
    padding: 0,
    // maxWidth: 1440,
    height: 0,
    // overflow: 'hidden',
    paddingTop: '42.25%',
    zIndex: 0
  },
  innerScreenContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const aspectRatio = 16 / 9;
const determineIfWindowIsWide = () => aspectRatio <= window.innerWidth / window.innerHeight;
const determineIfWindowIsTall = () => 1 >= window.innerWidth / window.innerHeight;


const InnerScreenView = ({ children, forwRef, ...props }) => {
  const classes = useStyles({ props });
  const ref = useRef();
  const [containerStyle, setContainerStyle] = useState(() => {
    if (determineIfWindowIsTall()) {
      return { 
        height: '100vh',
        backgroundSize: 'contain'
      };
    }
    if (determineIfWindowIsWide()) {
      return {
        height: '57vw',
        backgroundSize: 'cover'
      };
    }
    return { height: '100vh', backgroundSize: 'cover' };
  });

  const setScreenPosition = useCallback(() => {
    if (determineIfWindowIsTall()) {
      return setContainerStyle({ 
        height: '100vh',
        backgroundSize: 'contain'
      });
    }
    if (determineIfWindowIsWide()) {
      return setContainerStyle({
        maxHeight: '57vw',
        backgroundSize: 'cover'
      });
    }
    setContainerStyle({ height: '100vh', backgroundSize: 'cover' })
  }, []);

  useEffect(() => {
    setScreenPosition();
    window.addEventListener('resize', setScreenPosition);

    return () => window.removeEventListener('resize', setScreenPosition);
  }, [setScreenPosition]);
  
  return (
    <div className={classes.screenViewRoot} style={containerStyle}>
      <div className={classes.outerScreenContainer} ref={ref}>
        <div className={classes.innerScreenContainer} ref={forwRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

InnerScreenView.propTypes = {
  backgroundImage: PropTypes.string.isRequired
};

export default InnerScreenView;
