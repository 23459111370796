import React from 'react';
import { useSelector } from 'react-redux';
import getGameProgress from '../store/gameProgressSelector';
import { HashRouter, Switch } from 'react-router-dom';
import RouteWithGuard from './RouteWithGuard';
import Intro from '../views/Intro';
import Main from '../views/Main';
import TopicOne from '../views/TopicOne';
import TopicTwo from '../views/TopicTwo';
import TopicThree from '../views/TopicThree';
import TopicFour from '../views/TopicFour';
import Summary from '../views/Summary';


const Routes = (_props) => {
  const gameProgress = useSelector(getGameProgress);

  return (
    <HashRouter>
      <Switch>
        <RouteWithGuard key={'a'} exact path='/' gameProgress={gameProgress}>
          <Intro />
        </RouteWithGuard>
        <RouteWithGuard key={'b'} path='/board' gameProgress={gameProgress}>
          <Main />
        </RouteWithGuard>
        <RouteWithGuard key={'c'}  path='/topic-1' gameProgress={gameProgress}>
          <TopicOne />
        </RouteWithGuard>
        <RouteWithGuard key={'d'}  path='/topic-2' gameProgress={gameProgress}>
          <TopicTwo />
        </RouteWithGuard>
        <RouteWithGuard key={'e'}  path='/topic-3' gameProgress={gameProgress}>
          <TopicThree />
        </RouteWithGuard>
        <RouteWithGuard key={'f'} path='/topic-4' gameProgress={gameProgress}>
          <TopicFour />
        </RouteWithGuard>
        <RouteWithGuard key={'g'}  path='/summary' gameProgress={gameProgress}>
          <Summary />
        </RouteWithGuard>
      </Switch>
    </HashRouter>
  );
};

export default Routes;
