import { Machine, actions } from 'xstate';
import { setScore, setLastStep, increasePoints, markTopicCompleted, decreasePoints, setTopicPointTotal } from '../store/action-creators';
import { setScoreInMachineContext } from '../utils/helpers';

const { assign } = actions;

export default function createTopicThreeMachine(context) {
  // destructuring here to provide insight into the shape of context
  const {
    step,
    completed,
    qs,
    dispatch,
    scorm,
  } = context;

  return Machine(
    {
      id: 'topicThree',
      initial: 'start',
      context: {
        step,
        completed,
        qs,
        dispatch,
        scorm,
        // currentQuestionIndex: 0 // 1) Q for Joe
      },
      states: {
        // entry: [assign({ step: 'start' }), 'dispatchSetLastStep'],
        start: {
          after: {
            1000: [
              { target: 'lifeChoice', cond: 'shouldShowLifeChoice' }, // we can use this conditional to jump ahead if progess is saved
              { target: 'firstHelpfulHints', cond: 'shouldShowFirstHelpfulHints' },
              { target: 'monitor.initialView', cond: 'shouldShowMonitorInitialView' },
              { target: 'monitor.firstText', cond: 'shouldShowFirstText' },
              { target: 'monitor.classifications', cond: 'shouldShowClassifications' },
              { target: 'monitor.secondText', cond: 'shouldShowSecondText' },
              { target: 'monitor.secondHelpfulHints', cond: 'shouldShowSecondHelpfulHints' },
              { target: 'monitor.sharingTools', cond: 'shouldShowSharingTools' },
              { target: 'monitor.thirdText', cond: 'shouldShowThirdText' },
              { target: 'questionOne.awaitingInput', cond: 'shouldShowQuestionOne' },
              { target: 'questionOne.outcome', cond: 'shouldShowQuestionOneOutcome' },
              { target: 'questionTwo.awaitingInput', cond: 'shouldShowQuestionTwo' },
              { target: 'questionTwo.outcome', cond: 'shouldShowQuestionTwoOutcome' },
              { target: 'questionThree.awaitingInput', cond: 'shouldShowQuestionThree' },
              { target: 'questionThree.outcome', cond: 'shouldShowQuestionThreeOutcome' },
              { target: 'questionFour.awaitingInput', cond: 'shouldShowQuestionFour' },
              { target: 'questionFour.outcome', cond: 'shouldShowQuestionFourOutcome' },
              { target: 'finished' }
            ]
          }
        },
        lifeChoice: {
          entry: [assign({ step: 'lifeChoice' }), 'dispatchSetLastStep'],
          on: {
            CONTINUE: {
              target: 'firstHelpfulHints'
            }
          }
        },
        firstHelpfulHints: {
          id: 'firstHelpfulHints',
          entry: [assign({ step: 'firstHelpfulHints' }), 'dispatchSetLastStep'],
          on: {
            CONTINUE: {
              target: '#monitor'
            },
            BACK: {
              target: 'lifeChoice'
            }
          }
        },
        monitor: {
          id: 'monitor',
          initial: 'initialView',
          //Page 36 empty screen ?
          entry: [assign({ step: 'loginScreen' }), 'dispatchSetLastStep'],
          states: {
            initialView: {
              after: {
                1500: 'firstText'
              },
            },
            //Page 36 first text and button
            firstText: {
              entry: [assign({ step: 'firstText' }), 'dispatchSetLastStep'],
              on: {
                CONTINUE: {
                  target: 'classifications'
                },
                BACK: {
                  target: '#firstHelpfulHints', internal: false
                }
              }
            },
            // has to be new helpful hints component or redesign old?
            classifications: {
              entry: [assign({ step: 'classifications' }), 'dispatchSetLastStep'],
              on: {
                CONTINUE: {
                  target: '#secondText'
                },
                BACK: {
                  target: 'firstText'
                }
              }
            },
            secondText: {
              id: 'secondText',
              entry: [assign({ step: 'secondText' }), 'dispatchSetLastStep'],
              on: {
                CONTINUE: {
                  target: 'secondHelpfulHints'
                },
                BACK: {
                  target: 'classifications'
                }
              }
            },
            secondHelpfulHints: {
              entry: [assign({ step: 'secondHelpfulHints' }), 'dispatchSetLastStep'],
              on: {
                CONTINUE: {
                  target: 'sharingTools'
                },
                BACK: {
                  target: 'secondText'
                }
              }
            },
            sharingTools: {
              entry: [assign({ step: 'sharingTools' }), 'dispatchSetLastStep'],
              on: {
                CONTINUE: {
                  target: 'thirdText'
                },
                BACK: {
                  target: 'secondHelpfulHints'
                }
              }
            },
            thirdText: {
              id: 'thirdText',
              entry: [assign({ step: 'thirdText' }), 'dispatchSetLastStep'],
              on: {
                CONTINUE: [
                  { target: '#questionFour', cond: 'shouldSkipToQuestionFour' },
                  { target: '#questionThree', cond: 'shouldSkipToQuestionThree' },
                  { target: '#questionTwo', cond: 'shouldSkipToQuestionTwo' },
                  { target: '#questionOne' }
                ],
                BACK: {
                  target: 'sharingTools'
                }
              }
            },
          },
        },
        questionOne: {
          id: 'questionOne',
          entry: [assign({ step: 'questionOne' }), 'dispatchSetLastStep'],
          initial: 'awaitingInput',
          states: {
            awaitingInput: {
              on: {
                ANSWER: {
                  target: 'outcome'
                },
                BACK: {
                  target: '#thirdText', internal: false
                }
              }
            },
            outcome: {
              entry: [
                assign({ step: 'questionOneOutcome' }),
                'dispatchSetLastStep',
                'dispatchSetScore',
                'dispatchIncreasePoints'
              ],// allocate points in action here
              on: {
                CONTINUE: {
                  target: '#questionTwo'
                }
              }
            }
          }
        },
        questionTwo: {
          id: 'questionTwo',
          entry: [assign({ step: 'questionTwo' }), 'dispatchSetLastStep'],
          initial: 'awaitingInput',
          states: {
            awaitingInput: {
              on: {
                ANSWER: {
                  target: 'outcome'
                },
                BACK: {
                  target: '#thirdText', internal: false
                }
              }
            },
            outcome: {
              entry: [
                assign({ step: 'questionTwoOutcome' }),
                'dispatchSetLastStep',
                'dispatchSetScore',
                'dispatchIncreasePoints'
              ],// allocate points in action here
              on: {
                CONTINUE: {
                  target: '#questionThree'
                }
              }
            },
          }
        },
        questionThree: {
          id: 'questionThree',
          entry: [assign({ step: 'questionThree' }), 'dispatchSetLastStep'],
          initial: 'awaitingInput',
          states: {
            awaitingInput: {
              on: {
                ANSWER: {
                  target: 'outcome'
                },
                BACK: {
                  target: '#thirdText', internal: false
                }
              }
            },
            outcome: {
              entry: [
                assign({ step: 'questionThreeOutcome' }),
                'dispatchSetLastStep',
                'dispatchSetScore',
                'dispatchIncreasePoints'
              ],// allocate points in action here
              on: {
                CONTINUE: {
                  target: '#questionFour'
                }
              }
            },
          }
        },
        questionFour: {
          id: 'questionFour',
          entry: [assign({ step: 'questionFour' }), 'dispatchSetLastStep'],
          initial: 'awaitingInput',
          states: {
            awaitingInput: {
              on: {
                ANSWER: {
                  target: 'outcome'
                },
                BACK: {
                  target: '#thirdText', internal: false
                }
              }
            },
            outcome: {
              entry: [
                assign({ step: 'questionFourOutcome' }),
                'dispatchSetLastStep',
                'dispatchSetScore',
                'dispatchIncreasePoints'
              ],// allocate points in action here
              on: {
                CONTINUE: {
                  target: '#finished'
                }
              }
            }
          }
        },
        finished: {
          id: 'finished',
          entry: [
            assign({ step: 'finished' }),
            'dispatchSetLastStep',
          ],
          on: {
            CONTINUE: {
              target: 'final'
            }
          }
        },
        final: {
          entry: [
            'dispatchSetPointTotal',
            'dispatchTopicThreeComplete'
          ],
          type: 'final'
        }
      }
    },
    {
      actions: {
        dispatchSetScore: (ctx, evt) => {
          if (!evt || !evt.ans) return;

          const i = ctx.scorm.getInteractionCount(); // the interactionCount
          const {
            q, // the question key (e.g., 'q1)
            interactionType, // SCORM question type ('performance' for all of them)
            response, // user's input
            result, // must be either 'correct' or 'wrong'
            questionSet, // the topic key (e.g., 't01')
            objective, // a string (no white-space) describing the question
            ans // the correct answer
          } = evt;
          // we can take advantage of the fact that context is set first with assign
          setScoreInMachineContext(ctx, assign, q, result, response);
          ctx.dispatch(setScore({ questionSet, response, question: q, score: result === 'correct' ? 1 : 0 }));
          ctx.scorm.logInteraction({ i, q, interactionType, response, questionSet, objective, ans });
          ctx.scorm.logResult({ i, result });
        },
        dispatchSetLastStep: (ctx) => {
          // we can take advantage of the fact that context is set first with assign
          ctx.dispatch(setLastStep('t03', ctx.step));
          ctx.scorm.commitAndSaveProgress();
        },
        dispatchSetPointTotal: (ctx) => {
          ctx.dispatch(setTopicPointTotal('t03'));
        },
        dispatchTopicThreeComplete: (ctx) => {
          ctx.dispatch(markTopicCompleted('t03'));
          ctx.scorm.commitAndSaveProgress();
        },
        dispatchIncreasePoints: (ctx, evt) => {
          if (!evt || !evt.result) return;

          if (evt.result === 'correct') {
            ctx.dispatch(increasePoints(evt.points));
          } else {
            ctx.dispatch(decreasePoints(evt.points / 2));
          }
          ctx.scorm.commitAndSaveProgress();
        }
      },
      guards: {
        shouldShowLifeChoice: (ctx) => {
          return ctx.step === 'start' || ctx.step === 'lifeChoice'; // will be updated once the state tree is built out and we want to save user progress
        },
        shouldShowFirstHelpfulHints: (ctx) => {
          return ctx.step === 'firstHelpfulHints';
        },
        shouldShowMonitorInitialView: (ctx) => {
          return ctx.step === 'loginScreen';
        },
        shouldShowFirstText: (ctx) => {
          return ctx.step === 'firstText';
        },
        shouldShowClassifications: (ctx) => {
          return ctx.step === 'classifications';
        },
        shouldShowSecondText: (ctx) => {
          return ctx.step === 'secondText';
        },
        shouldShowSecondHelpfulHints: (ctx) => {
          return ctx.step === 'secondHelpfulHints';
        },
        shouldShowSharingTools: (ctx) => {
          return ctx.step === 'sharingTools';
        },
        shouldShowThirdText: (ctx) => {
          return ctx.step === 'thirdText';
        },
        shouldShowQuestionOne: (ctx) => {
          return ctx.step === 'questionOne';
        },
        shouldShowQuestionOneOutcome: (ctx) => {
          return ctx.step === 'questionOneOutcome';
        },
        shouldSkipToQuestionTwo: (ctx) => {
          return !!ctx.qs[0].res;
        },
        shouldShowQuestionTwo: (ctx) => {
          return ctx.step === 'questionTwo';
        },
        shouldShowQuestionTwoOutcome: (ctx) => {
          return ctx.step === 'questionTwoOutcome';
        },
        shouldSkipToQuestionThree: (ctx) => {
          return !!ctx.qs[1].res;
        },
        shouldShowQuestionThree: (ctx) => {
          return ctx.step === 'questionThree';
        },
        shouldShowQuestionThreeOutcome: (ctx) => {
          return ctx.step === 'questionThreeOutcome';
        },
        shouldSkipToQuestionFour: (ctx) => {
          return !!ctx.qs[2].res;
        },
        shouldShowQuestionFour: (ctx) => {
          return ctx.step === 'questionFour';
        },
        shouldShowQuestionFourOutcome: (ctx) => {
          return ctx.step === 'questionFourOutcome';
        },
        isFinished: (ctx) => {
          return ctx.completed;
        }
      }
    }
  )
}
