import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { TopicThreeContext } from '../../../contexts/topicThreeContext';
import { YES, NO } from '../../life-choices/constants';
import InnerScreenView from '../InnerScreenView';
import ButtonCustom from '../../inputs/ButtonCustom';
import ClassificationTopicThree from '../../dialogs/ClassificationTopicThree';
import { helpfulHintsThreeTopicThree } from '../../../data/hints';
import HelpfulHints from '../../dialogs/HelpfulHints';
import TopicThreeSharingTool from './TopicThreeSharingTool';
import useInitialFocus from '../../../utils/hooks/useInitialFocus';

const choiceBackgroundMap = {
  [YES]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/AirportLaptop.png',
  [NO]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/TrainLaptop.png'
};

const useStyles = createUseStyles({
  textBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2vw',
    color: 'white',
    fontWeight: 'bolder',
    '& p': {
      fontSize: '1.8vw',
      fontWeight: 600,
      lineHeight: 1.3,
      color: 'white'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      width: '100%'
    }
  },
  text: {
    padding: '3.9vw 3.9vw 0'
  }
});

const textTopicThree = [
  <>The first thing to do when sharing deliverables inside or outside Accenture is to classify & protect the documents. If you work with a client, make sure you are following the client's policies when it comes to sharing and protecting client data. <br/><br/>You can protect your deliverables with the Sensitivity Toolbar in the Microsoft Office Suite (Outlook, Word, Excel, and PowerPoint).</>,
  <>When storing sensitive information, only use Accenture or client-provided solutions. If you believe that you have a business need for a solution that Accenture or your client does not provide, raise it with your supervisor or with the CIO organization.<br/><br/>Do not use consumer or commercial solutions if Accenture or client-provided solutions are available.</>,
  <>Use the tips you just learned to answer the following questions to keep playing.</>
];

const TopicThreeScreenView = ({ audioClickHandlerT3 }) => {
  const classes = useStyles();
  const { machineState, send } = useContext(TopicThreeContext);
  const isPlaneOrTrain = useSelector((state) => state.player.travel);
  const textFocus0 = useInitialFocus();
  const textFocus1 = useInitialFocus();
  const textFocus2 = useInitialFocus();

  const close = () => {
    audioClickHandlerT3();
    send('CONTINUE');
  };

  return (
    <>
      <InnerScreenView backgroundImage={choiceBackgroundMap[isPlaneOrTrain] || choiceBackgroundMap[YES]}>
        {/* Text 1, 2, 3 */}
        {
          (machineState.matches('monitor.firstText') ||
            machineState.matches('monitor.secondText') ||
            machineState.matches('monitor.thirdText')) &&
          <div className={classes.textBox}>
            <div>
              <div className={classes.text} >
                {machineState.matches('monitor.firstText') ? <p ref={textFocus0} tabIndex={0}>{textTopicThree[0]}</p> : ''}
                {machineState.matches('monitor.secondText') ? <p ref={textFocus1} tabIndex={0}>{textTopicThree[1]}</p> : ''}
                {machineState.matches('monitor.thirdText') ? <p ref={textFocus2} tabIndex={0}>{textTopicThree[2]}</p> : ''}
              </div>
              <ButtonCustom isBloop={false} onClick={close} buttonText={'NEXT!'} />
            </div>
          </div>
        }
        {/* Classification Helpful Hints */}
        {
          machineState.matches('monitor.classifications') && <ClassificationTopicThree
            isOpen={machineState.matches('monitor.classifications')}
            close={close}
          />
        }
        {/* Second helpful hints */}
        <HelpfulHints
          key='2dHH'
          hints={helpfulHintsThreeTopicThree}
          buttonText='Move on!'
          isOpen={machineState.matches('monitor.secondHelpfulHints')}
          close={close}
          bounce={true}
          isBloop={false}
        />
        <TopicThreeSharingTool
          isOpen={machineState.matches('monitor.sharingTools')}
          audioClickHandlerT3={audioClickHandlerT3}
        />
      </InnerScreenView>
    </>
  );
};

export default TopicThreeScreenView;
