import React, { useContext, useEffect, useRef, useState } from 'react';
//import PropTypes from 'prop-types';
import { setModalOpen } from '../../store/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { createUseStyles } from 'react-jss';
import FocusLock from "react-focus-lock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBack, ButtonNext, CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel';
import { IntroContext } from '../../contexts/introContext';
import CharacterSlide from './CharacterSlide';
import SoundSelectionButton from '../inputs/SoundSelectionButton';
import { JULIA, MICHAEL, ANTHONY, SARAH } from '../../data/character';
import { audioWoosh } from '../../data/audioMap';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { sfxPlayHandler } from '../../utils/helpers';
import ButtonCustom from '../inputs/ButtonCustom';

const useStyles = createUseStyles({
  bg: {
    background: 'radial-gradient(circle, rgba(161,0,255,1) 30%, rgba(70,0,115,1) 80%)',
    minHeight: '100vh',
    textAlign: 'center',
    fallbacks: {
      background: 'rgb(161,0,255)',
    },
    '& h4': {
      marginTop: 0,
      fontSize: '2.7vw',
      fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif',
      paddingTop: '2%',
      marginBottom: 0,
      color: 'white',
    }
  },
  carousel: {
    position: 'relative'
  },
  dotsContainer: {
    '@media screen and (max-width: 768px)': {
      display: 'none'
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      display: 'none'
    },
  },
  carouselDot: {
    width: '14%',
    backgroundColor: 'transparent',
    border: 'transparent',
    opacity: 0.55,
    transition: '0.2s opacity ease-in',
    paddingTop: '1.6vw',
    '& img': {
      width: '70%'
    },
    '&.carousel__dot--selected': {
      opacity: 1
    }
  },
  activeDot: {
    backgroundColor: 'white',
    height: '1vw',
    width: '1vw',
    borderRadius: '50%',
    margin: 'auto auto 10px',
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      margin: 'auto auto 5px'
    }
  },
  carouselButton: {
    background: 'unset',
    border: 'unset',
    position: 'absolute',
    top: '45%',
    '&.right': {
      right: '10%'
    },
    '&.left': {
      left: '10%'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      background: 'transparent',
      border: 'transparent'
    }
  },
  buttonContainer: {
    width: '3vw',
    height: '3vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
    '&:hover .outer': {
      left: 0,
      top: 0,
      opacity: 0.2
    },
    '&:hover .inner': {
      transform: 'scale(1.05)'
    },
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      width: '4vw',
      height: '4vw',
    }
  },
  buttonRoot: {
    width: '14vw',
    height: '4.5vw'
  },
  buttonText: {
    fontSize: '1.9vw',
    '@media screen and (max-width: 767px)': {
      fontSize: '2vw'
    }
  },
  //outside polygon
  outerPolygon: {
    position: 'absolute',
    top: '3px',
    left: '3px',
    display: 'inline-block',
    width: 'calc(100% + 0.3vw)',
    height: 'inherit',
    background: 'rgb(79,13,116)',
    boxSizing: 'border-box',
    clipPath: 'polygon(2% 3%, 95% 0, 100% 100%, 0 100%)',
    transition: 'all 200ms ease-in-out'
  },
  //inside background
  innerPolygon: {
    position: 'absolute',
    background: 'linear-gradient(to top, #f96355 , #ffdb06)',
    width: 'inherit',
    height: '94%',
    clipPath: 'polygon(2% 3%, 95% 0, 100% 100%, 0 100%)',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 200ms ease-in-out'
  },
  textContainer: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    transform: 'rotate(-2deg)',
    display: 'flex',
    alignItems: 'center',
  },
  textContainer_span: {
    color: '#fff',
    font: 'bold 1.5vw system-ui',
    margin: 'auto'
  },
  slider: {
    height: '34vw',
    '@media screen and (max-width: 750px) and (orientation:landscape) ': {
      height: '38vw'
    },
    '@media screen and (max-width: 650px) and (orientation:landscape) ': {
      height: '44vw'
    }
  },
});

const characterMap = {
  0: JULIA,
  1: MICHAEL,
  2: SARAH,
  3: ANTHONY,
}

const CharacterSelection = (props) => {
  const { send } = useContext(IntroContext);
  const classes = useStyles();
  const wooshRef = useRef();
  const dispatch = useDispatch();
  const soundsEffects = useSelector(state => state.settings.soundsEffects);
  const [character, setCharacterState] = useState(0);
  const valueRef = useRef(character);
  const slideRef = useRef();

  const selectCharacter = (character) => {
    dispatch(setModalOpen(false));
    send('SELECT', { character });
  };

  useEffect(() => {
    dispatch(setModalOpen(true));
  }, [dispatch]);

  useEffect(() => {
    wooshRef.current.volume = 0.6;
  }, [])

  const handleSlideSFX = () => {
    if (soundsEffects) {
      wooshRef.current.pause();
      sfxPlayHandler(wooshRef);
    }
  }
  // const handleKeyPress = (e) => {
  //   if (e.code === 'Space' || e.code === 'Enter') {
  //     selectCharacter(characterMap[valueRef.current])
  //   }
  // }

  const setCharacter = (char) => {
    valueRef.current = char;
    setCharacterState(char);
  }

  // useEffect(() => {
  //   slideRef.current.addEventListener('keypress', handleKeyPress);
  //   return () => {
  //     slideRef.current.removeEventListener('keypress', handleKeyPress)
  //   }
  // }, [])

  return (
    <section className={classes.bg}>
      <audio role='presentation' id='woosh' ref={wooshRef} src={audioWoosh} type='audio' />
      {/* Carousel to go here */}
      <CarouselProvider
        visibleSlides={1}
        totalSlides={4}
        step={1}
        naturalSlideWidth={800}
        naturalSlideHeight={450}
        infinite
        className={classes.carousel}
      >
        <h4 role={'alert'}>Please select a character</h4>

        <FocusLock group='charSelection'>
          <div ref={slideRef} >
            <Slider tabIndex={-1} className={classes.slider}> 
              <Slide tabIndex={-1} index={0}>
                <CharacterSlide
                  character={JULIA}
                  characterNumber={1}
                  selectCharacter={setCharacter}
                />
              </Slide>
              <Slide tabIndex={-1} index={1}>
                <CharacterSlide
                  character={MICHAEL}
                  characterNumber={2}
                  selectCharacter={setCharacter}
                />
              </Slide>
              <Slide tabIndex={-1} index={2}>
                <CharacterSlide
                  character={SARAH}
                  characterNumber={3}
                  selectCharacter={setCharacter}
                />
              </Slide>
              <Slide tabIndex={-1} index={3}>
                <CharacterSlide
                  character={ANTHONY}
                  characterNumber={4}
                  selectCharacter={setCharacter}
                />
              </Slide>
            </Slider>
          </div>

          <div style={{ position: 'relative', top: '-5vw', height: '10px' }}>
            <ButtonCustom
              buttonText='SELECT!'
              arialabeltext={`Select ${characterMap[character]} and start the game.`}
              classes={{ root: classes.buttonRoot, buttonText: classes.buttonText }}
              onClick={() => selectCharacter(characterMap[character])}
            />
          </div>
          <ButtonBack onClick={handleSlideSFX} className={`${classes.carouselButton} left`}>
            <div className={classes.buttonContainer}>
              <div className={`${classes.outerPolygon} outer`}></div>
              <div className={`${classes.innerPolygon} inner`}>
                <div className={classes.textContainer}>
                  <span aria-label='button back clciked' className={classes.textContainer_span}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{ margin: '2px 2px 0 0' }} />
                  </span>
                </div>
              </div>
            </div>
          </ButtonBack>
          <ButtonNext onClick={handleSlideSFX} className={`${classes.carouselButton} right`}>
            <div className={classes.buttonContainer}>
              <div className={`${classes.outerPolygon} outer`}></div>
              <div className={`${classes.innerPolygon} inner`}>
                <div className={classes.textContainer}>
                  <span className={classes.textContainer_span}>
                    <FontAwesomeIcon icon={faChevronRight} style={{ margin: '2px 0 2px 0' }} />
                  </span>
                </div>
              </div>
            </div>
          </ButtonNext>
        </FocusLock>
        <div aria-hidden="true" role='presentation' className={classes.dotsContainer}>
          <Dot onClick={handleSlideSFX} slide={0} className={classes.carouselDot}>
            <div>
              <div className={classes.activeDot}></div>
              <img src='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CharacterSelect-01.png' alt='Julia' />
            </div>
          </Dot>
          <Dot onClick={handleSlideSFX} slide={1} className={classes.carouselDot}>
            <div>
              <div className={classes.activeDot}></div>
              <img src='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CharacterSelect-02.png' alt='Michael' />
            </div>
          </Dot>
          <Dot onClick={handleSlideSFX} slide={2} className={classes.carouselDot}>
            <div>
              <div className={classes.activeDot}></div>
              <img src='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CharacterSelect-03.png' alt='Sarah' />
            </div>
          </Dot>
          <Dot onClick={handleSlideSFX} slide={3} className={classes.carouselDot}>
            <div>
              <div className={classes.activeDot}></div>
              <img src='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CharacterSelect-04.png' alt='Anthony' />
            </div>
          </Dot>
        </div>
      </CarouselProvider>
      {/* <ButtonCustom 
        buttonText='SELECT!'
        onClick={() => send('SELECT')}
      /> */}
      <FocusLock group='charSelection'>
        <SoundSelectionButton modal />
      </FocusLock>
    </section>
  );
};

CharacterSelection.propTypes = {

};

export default CharacterSelection;
