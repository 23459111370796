import React, { useContext, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { SummaryContext } from '../../contexts/summaryContext';
import { lifeChoiceSrcMap } from '../life-choices/sourcemap';

const useStyles = createUseStyles({
  badgeContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 30,
    '@media screen and (max-width: 767px)': {
      marginBottom: 15
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      marginBottom: 15
    },
    '@media screen and (max-height: 380px)': {
      marginBottom: 10
    }
  },
  badgeSpot: {
    width: '5vw',
    minWidth: 50,
    height: '5vw',
    minHeight: 50,
    margin: '0 1vw',
    borderRadius: '50%',
    backgroundColor: 'rgba(70,0,115,0.7)',
    '& img': {
      position: 'relative',
      width: '90%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    '@media screen and (max-height: 380px)': {
      minWidth: 40,
      minHeight: 40,
      width: 40,
      height: 40
    }
  }
})

const TopicSummaryBadges = (props) => {
  const classes = useStyles();
  const { machineState } = useContext(SummaryContext);
  const officeOrHome = useSelector((state) => state.player.officeOrHome);
  const travel = useSelector((state) => state.player.travel);
  const accentureArea = useSelector((state) => state.player.accentureArea);
  const [visibleBadges, setVisibleBadges] = useState({ 
    officeOrHome: false, 
    travel: false, 
    accentureArea: false 
  });
  
  useEffect(() => {
    if (machineState.matches({ topicSummary: 'outlook' })) {
      setVisibleBadges((state) => ({ ...state, officeOrHome: true }))
    }
    if (machineState.matches({ topicSummary: 'sharingTools' })) {
      setVisibleBadges((state) => ({ ...state, travel: true }))
    }
    if (machineState.matches({ topicSummary: 'workstationHygiene' })) {
      setVisibleBadges((state) => ({ ...state, accentureArea: true }))
    }
  }, [machineState])

  return (
    <div className={classes.badgeContainer}>
      <div className={classes.badgeSpot}>
        {officeOrHome && visibleBadges.officeOrHome ? <img src={lifeChoiceSrcMap[officeOrHome].src} alt='' /> : null}
      </div>
      <div className={classes.badgeSpot}>
        {travel && visibleBadges.travel ? <img src={lifeChoiceSrcMap[travel].src} alt='' /> : null}
      </div>
      <div className={classes.badgeSpot}>
        {accentureArea && visibleBadges.accentureArea ? <img src={lifeChoiceSrcMap[accentureArea].src} alt='' /> : null}
      </div>
    </div>
  );
};

TopicSummaryBadges.propTypes = {};

export default TopicSummaryBadges;
