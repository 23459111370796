import React from 'react';
import { PropTypes } from 'prop-types';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  headerContainer: {
    width: 'inherit',
    height: '7vw',
  },
  /*
  orange #f96355 rgb(249,99,85)
  yellow #ffdb06 rgb(255,217,7)
  */
  outer_polygon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: 'inherit',
    height: 'inherit',
    background: 'white',
    boxSizing: 'border-box',
    clipPath: 'polygon(0 1%, 100% 0%, 94% 99%, 3% 100%)',

  },
  inner_polygon: {
    position: 'relative',
    display: 'block',
    backgroundImage: 'linear-gradient(to right, #f96355 , #ffdb06)',
    width: '95.5%',
    height: '86%',
    clipPath: 'polygon(0 1%, 100% 0%, 94% 99%, 3% 100%)',
  },
  inner_bottom_border: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundImage: 'linear-gradient(to right, #e64745 , #e87b22)',
    clipPath: 'polygon(3% 92%, 96% 92%, 100% 0, 100% 100%, 0 100%, 0 0)'
  },
  center: {
    position: 'relative',
    textAlign: 'center',
    width: 'inherit',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    paddingLeft: '1.25vw',
    paddingRight: '1.25vw',
    boxSizing: 'border-box',
    textTransform: 'uppercase',
  }
});

const OrangeHeader = ({ headerText = '', tabIndex = 0, fontSize, rootStyles, innerPolygonStyles, classes={} }) => {
  const localClasses = useStyles();

  return (
    <div className={`${localClasses.headerContainer} ${classes.root || ''}`} style={{ ...rootStyles }}>
      <div className={localClasses.outer_polygon}>
        <div className={localClasses.inner_polygon} style={{ ...innerPolygonStyles }}>
          <div className={localClasses.inner_bottom_border}>
          </div>
        </div>
      </div>
      <div tabIndex={tabIndex} className={localClasses.center} style={{ ...fontSize }}>
        <h1 className={classes.text}>{headerText}</h1>
      </div>
    </div>
  );
}

OrangeHeader.propTypes = {
  headerText: PropTypes.string,
  tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.object,
  rootStyles: PropTypes.object,
  innerPolygonStyles: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string)
};

export default OrangeHeader;
