import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
//import { AudioContext } from '../../contexts/audioContext';
import { audioBoxSpring, audioBtnBloop } from '../../data/audioMap';
import { sfxPlayHandler } from '../../utils/helpers';

const AudioEffects = ({ effect, setCurrentEffect }) => {
//  const { effect } = useContext(AudioContext)
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  const btnAudio = useRef();
  const boxSpringAudio = useRef();

  const play = useCallback((efx) => {
    try {
      switch (efx) {
        case 'bloop':
          btnAudio.current.pause();
          btnAudio.current.volume = 0.5
          sfxPlayHandler(btnAudio)
          setCurrentEffect('')
          break;
        case 'boxSpring':
          boxSpringAudio.current.pause();
          btnAudio.current.volume = 0.2
          sfxPlayHandler(boxSpringAudio)
          setCurrentEffect('')
          break;
        default:
          break;
      }
    } catch { }
  }, [setCurrentEffect]);

  useEffect(() => {
    try {
      btnAudio.current.volume = 0.5
      boxSpringAudio.current.volume = 0.5
    } catch { }
  }, [])

  useEffect(() => {
    if (soundsEffects) {
      setTimeout(() => {
        play(effect);
      }, 50);
      // play(effect);
    }
  }, [effect, soundsEffects, play]);

  return (<>
    <audio id='btnBloop' ref={btnAudio} src={audioBtnBloop} type='audio' />
    <audio id='boxSpring' ref={boxSpringAudio} src={audioBoxSpring} type='audio' />
  </>);
};

export default AudioEffects;
