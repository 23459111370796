import BackButton from '../inputs/BackButton';
import { createUseStyles } from 'react-jss';
import SoundSelectionButton from '../inputs/SoundSelectionButton';
import ExitCourseButton from '../inputs/ExitCourseButton';
import { setModalOpen } from '../../store/action-creators';
import { useDispatch } from 'react-redux';
import React, { useContext, useEffect }from 'react';
import PropTypes from 'prop-types';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import { useSpring, animated, config } from 'react-spring';
import '@reach/dialog/styles.css';
import { AudioContext } from '../../contexts/audioContext';

const AnimatedDialogContent = animated(DialogContent);

const AnimatedTRDialog = ({
  children,
  isOpen,
  close,
  dismiss,
  ariaLabel,
  ariaLabelledBy,
  initialFocusRef,
  showClose,
  showBackButton,
  dialogStyles,
  overlayStyles,
  onMounted,
  classes = {}
}) => {
  const dispatch = useDispatch();
  const [props, set, stop] = useSpring(() => ({
    from: { scale: 0.1, opacity: 0.5, overlayOpacity: 0 },
    to: { scale: isOpen ? 1 : 0.01, opacity: isOpen ? 1 : 0, overlayOpacity: isOpen ? 1 : 0 },
    config: isOpen ? { ...config.wobbly } : { duration: 150 },
  }));
  const {playEffect} = useContext(AudioContext)

  useEffect(() => {
    if (isOpen) {
      stop();
      set({
        to: { scale: isOpen ? 1 : 0.01, opacity: isOpen ? 1 : 0, overlayOpacity: isOpen ? 1 : 0 },
        config: isOpen ? { ...config.wobbly } : { duration: 150 },
        onRest: (isOpen && typeof onMounted === 'function') ? () => onMounted() : () => null
      });
    }
  }, [set, stop, isOpen, onMounted])


  useEffect(() => {
    dispatch(setModalOpen(isOpen));
  }, [isOpen, dispatch])

  useEffect(()=>{
    console.log('isOpen:', isOpen);
    if (isOpen) { 
      console.log('boxSpring');
      playEffect('boxSpring');
    }
  }, [isOpen, playEffect]);

  return (
  <>
    {isOpen &&
      <DialogOverlay
        onDismiss={dismiss || close}
        initialFocusRef={initialFocusRef}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          margin: '0 auto',
          background: 'rgba(0,0,0,0.6)',
          zIndex: 5,
          ...overlayStyles
        }}
        className={classes.overlay || ''}
      >
        <>
          <AnimatedDialogContent
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            style={{
              boxSizing: 'border-box',
              opacity: props.opacity.interpolate(
                isOpen
                ? { range: [0, 0.7, 1], output: [0.3, 1, 1] }
                : { range: [0, 0.3, 1], output: [0, 0.1, 1], extrapolateRight: 'clamp' }
              ),
              transform: props.scale.interpolate((value) => `scale(${value})`),
              ...dialogStyles
            }}
            className={classes.dialog || ''}
          >
            {showClose &&
              <button className="close-button" onClick={close}>
                <VisuallyHidden>Close</VisuallyHidden>
                <span aria-hidden>×</span>
              </button>
            }
            {children}
          </AnimatedDialogContent>
          <SoundSelectionButton modal />
          {showBackButton ? <BackButton modal /> : null}
          <ExitCourseButton modal/>
        </>
      </DialogOverlay>
    }
    </>
  );
};

AnimatedTRDialog.propTypes = {
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  initialFocusRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  isOpen: PropTypes.bool.isRequired,
  dismiss: PropTypes.func,
  close: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  showBackButton: PropTypes.bool,
  dialogStyles: PropTypes.object,
  overlayStyles: PropTypes.object,
  onMounted: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string)
};

export default AnimatedTRDialog;
