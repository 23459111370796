export const officeHints = [
  {
    id: 1,
    text: 'Always lock your screen when you are away from your computer.',
    points: 5
  },
  {
    id: 2,
    text: 'Use a privacy screen and lock-down cable.',
    points: 5
  },
  {
    id: 3,
    text: 'Be sure to know which wireless networks your device is auto-connecting to.',
    points: 5
  }
];

export const homeHints = [
  {
    id: 4,
    text: 'Never share your Accenture devices with colleagues, friends, or family members.',
    points: 5
  },
  {
    id: 5,
    text: 'Change the default administrator password on your router to one that is both strong and unique.',
    points: 5
  },
  {
    id: 6,
    text: 'Make sure your home router is secured with either WPA2 or WPA3 encryption. It is important not to use WEP, since it is a much older security protocol and not very secure.',
    points: 5
  },
  {
    id: 7,
    text: 'Create a strong network password and only share it with people you trust.',
    points: 5
  }
];

export const helpfulHintsTopicOne = [
  // {
  //   text: 'You should always make sure that passwords are complex and unique. Remember, with so much personal information available to hackers from past breaches, don’t use a password that is based on personal information that is readily available, and it\'s important to change your password frequently.',
  //   points: 5
  // },
  {
    id: 8,
    text: 'Do you ever struggle to create new, unique passwords? The Person-Action-Object (PAO) method ties together a random person, action, and object. \n\nExample: Albert Einstein eats carrots. This random string of words can create a unique password: “AEe@tsc@rr0ts!”. ',
    points: 5
  },
  // {
  //   text: 'It is tempting to recycle passwords to make them easier to remember, but you should never use the same password for Accenture work and for personal sites, such as social media or online shopping, and you should only use your personal email for sites that are not business related.',
  //   points: 5
  // },
  {
    id: 9,
    text: 'Consider using a password manager like LastPass, Dashlane, or Keeper. If you use a password manager to store Accenture credentials, it should be protected with Multi-Factor Authentication. Only use a password manager for client work when explicitly permitted by the client.',
    points: 5
  }
];

export const helpfulHintsTopicTwo = [
  {
    id: 10,
    text: 'If you need to check your email at a public computer, such as a hotel business room, do not access sensitive information and never save anything locally.',
    points: 5
  },
  {
    id: 11,
    text: 'Only use Accenture devices to access restricted Accenture data. If your client provides you a computer, make sure to follow all client policies.',
    points: 5
  }
];

export const travelHint = [
  {
    id: 12,
    text: 'Whenever possible, put Accenture devices in your carryon baggage, rather than checked baggage, to keep them close to you unless directed otherwise by airport security.',
    points: 5
  },
];

export const noTravelHint = [
  {
    id: 13,
    text: 'If you ever travel for work in the future, put Accenture devices in your carry-on baggage, rather than checked baggage, to keep them close to you, unless directed otherwise by airport security.',
    points: 5
  }
];

export const helpfulHintsTopicThree = [
  {
    id: 14,
    text: 'Restricted',
    content: `Restricted data is highly sensitive, strategic data considered “Material, Non-Public” for which an investor would very likely consider important in deciding whether to buy or sell securities and that could affect the price of the security or other similarly sensitive data. \n\nBy design, very few people have access to Restricted information because its distribution is limited to only select individuals on a need-to-know-basis.`,
    points: 5
  },
  {
    id: 15,
    text: 'Highly Confidential',
    content: `Highly Confidential data is sensitive data that can be distributed only to those individuals or groups within Accenture and external parties who need to know the information to do their jobs.`,
    points: 5
  },
  {
    id: 16,
    text: 'Confidential',
    content: `Confidential data is sensitive and must not be shared outside of Accenture and external parties where there is a need to know. In some cases, Confidential data can be shared broadly across Accenture, but it should be shared only as necessary. \n\nAll non-public information that is not classified as Highly Confidential or Restricted must be classified as Confidential.`,
    points: 5
  },
  {
    id: 17,
    text: 'Unrestricted',
    content: `Unrestricted data is not sensitive, and is data that is intended to be generally available to the public.`,
    points: 5
  },
];

export const helpfulHintsThreeTopicThree = [
  {
    id: 18,
    text: `Media and portable storage devices (e.g., CDs, DVDs, or USBs), even those provided by Accenture or a client, should only be used when there is no practical Accenture-provided alternative (e.g., SharePoint).`,
    points: 5
  },
  {
    id: 19,
    text: `Make sure your portable storage devices are encrypted.`,
    points: 5
  },
  {
    id: 20,
    text: `Accenture External File Sharing (AEFS) Tool is Accenture’s approved tool for sharing documents with non-Accenture colleagues such as contractors or vendors. `,
    points: 5
  },

];

export const sharingToolsTopicThree = [
  {
    id: 21,
    text: 'Placeholder for the aggregate of all sharing tools steps',
    points: 60
  }
];

export const helpfulHintsTopicFour = [
  {
    id: 22,
    text: 'Allow your machines to download patches and updates by connecting to the internet regularly. You may then need to restart your machine to install the patches and updates.',
    points: 5
  }
];

export const topicFourApps = [
  { id: 23, text: 'Catalog App #1', points: 5 },
  { id: 24, text: 'Catalog App #2', points: 5 },
  { id: 25, text: 'Catalog App #3', points: 5 },
  { id: 26, text: 'Catalog App #4', points: 5 },
  { id: 27, text: 'Catalog App #5', points: 5 },
];

