import React, { useState, useContext } from 'react';
//import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { createUseStyles } from 'react-jss';
import { t02 } from '../../../data/questions';
import { TopicTwoContext } from '../../../contexts/topicTwoContext';
import ButtonCustom from '../../inputs/ButtonCustom';
import ButtonCheckboxTopicTwo from '../../inputs/ButtonCheckBoxTopicTwo';

const useStyles = createUseStyles({
  //1916 x 922 client size test
  root: {
    width: '70%',
    display: 'flex',
    marginLeft: '18%',
    marginRight: '6%',
    height: '100%',
    alignItems: 'center',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '62%',
      marginLeft: '22%',
    },
    '@media screen and (min-aspect-ratio: 3 / 1)': {
      width: '57%',
      marginLeft: '25%',
    }
  },
  buttonContainer: {
    position: 'absolute',
    left: '2vw',
    bottom: '1vw',
    '& > div:first-child': {
      textAlign: 'right',
      marginBottom: '0.5vw',
      fontSize: '1.35vw'
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      left: '1vw',
      bottom: '4vw',
      '& > div:first-child': {
        marginRight: '4vw',
        fontSize: '1.15vw'
      },
    },
    '@media screen and (min-aspect-ratio: 3 / 1)': {
      left: '0.5vw',
      bottom: '5.5vw',
      '& > div:first-child': {
        marginBottom: '-2.5vw',
        marginRight: '-5vw',
        fontSize: '1.15vw'
      },
    }
  },
  questionLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    textAlign: 'right',
    '& .checkIcon': {
      color: 'green',
      position: 'absolute',
      top: '50%',
      bottom: '50%',
      transform: 'translateY(-50%)',
      fontSize: '2.5vw',
      '@media screen and (min-aspect-ratio: 3 / 1)': {
        left: '-1vw'
      }
    }
  },
  questionRight: {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    textAlign: 'left',
    '& .checkIcon': {
      color: 'green',
      position: 'absolute',
      top: '50%',
      bottom: '50%',
      transform: 'translateY(-50%)',
      fontSize: '2.5vw',
      '@media screen and (min-aspect-ratio: 3 / 1)': {
        right: '-1vw'
      }
    }
  },
  imageCheckBox: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageCheckBox_inner: {
    width: '15vw',
    position: 'relative',
    '& .checkIcon': {
      color: 'green',
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      bottom: '-5%',
      fontSize: '2.5vw'
    }
  },
  imageCheckBox_img: {
    width: '100%',
  },
  counter: {
    '& span': {
      fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    },
    '&.notCompleted': {
      color: 'grey'
    },
    '&.completed': {
      color: 'green'
    },
  }
});

// Data
const questions = t02.q1;
const questionLeft = questions.options.slice(0, 3)
const questionRight = questions.options.slice(3, questions.options.length)
const wrongAnswer = { 'e': true };
const ans = questions.options.filter((opt) => !(opt.id in wrongAnswer)).map(opt => opt.id)



const TopicTwoQuestion = ({ audioClickHandlerT2 }) => {
  const classes = useStyles();
  const { send } = useContext(TopicTwoContext);
  const [selections, setSelections] = useState([]);
  //Making Correct answers array
  const [correctAnswer] =
    useState(questions.options.map(opt => ({ [opt.id.toString()]: ans.includes(opt.id) })));

  // Variables
  let buttinIsDisabled = !(selections.filter(u => !(u in wrongAnswer)).length === ans.length
    && selections.length === ans.length);

  // Functions
  const updateChecked = (id, checked) => {
    audioClickHandlerT2();
    const index = selections.indexOf(id);
    if (checked && index < 0) {
      return setSelections((state) => [...state, id]);
    }
    if (!checked && index > -1) {
      return setSelections((state) => [...state.slice(0, index), ...state.slice(index + 1)]);
    }
  };

  const submitAnswers = () => {
    audioClickHandlerT2();
    const scormData = {
      q: questions.id,
      objective: questions.objective,
      ans: questions.ans,
      interactionType: questions.type,
      response: questions.ans,
      questionSet: 't02',
    };
    send('ANSWER', { ...scormData, points: questions.points, result: 'correct' });
  };
  // Styles 
  const disabledInnerStyles = {
    background: 'darkgrey'
  };

  const disabledOuterStyles = {
    background: 'black'
  };

  const disabledBorderStyles = {
    background: 'lightgrey'
  };

  const overlayRootStyles = {
    height: '4.4vw',
  };

  const disabledRootStyles = {
    height: '4.4vw',
    opacity: '55%',
  };

  return (
    <div className={classes.root}>
      {/* Left side questions */}
      <div style={{ flex: 1 }}>
        {
          questionLeft.map((opt, index) => {
            return (
              <div key={opt.id} className={classes.questionLeft}>
                <div>
                  {selections.indexOf(opt.id) > -1 ? <FontAwesomeIcon icon={faCheckCircle} className={`${classes.check} checkIcon`} /> : ''}
                </div>
                <div>
                  <ButtonCheckboxTopicTwo
                    checked={selections.indexOf(opt.id) > -1}
                    isCorrect={correctAnswer[index][opt.id]}
                    optionText={''}
                    updateChecked={(checked) => updateChecked(opt.id, checked)}
                    company={opt.company}
                    title={opt.title}
                    text={opt.text}
                    textTwo={opt.textTwo}
                    textThree={opt.textThree}
                    hideTwo={opt.hideTwo}
                    hide={opt.hide}
                    id={'left_CB' + index}
                  />
                </div>
              </div>
            )
          })
        }
      </div>
      {/* Middle Image question */}
      <div className={classes.imageCheckBox}>
        <div className={classes.imageCheckBox_inner} aria-label={selections.indexOf('g') > -1 ? 'Scrubbed logo' : ''}>
          <ButtonCheckboxTopicTwo
            ariaLabel={selections.indexOf('g') > -1 ? 'Client name has been scrubbed' : 'Monjardin cloud logo, Monjardin text is visible,'}  
            checked={selections.indexOf('g') > -1}
            isCorrect={true}
            optionText={''}
            updateChecked={(checked) => updateChecked('g', checked)}
            title={''}
            text={''}
          >
            <img className={classes.imageCheckBox_img} src={selections.indexOf('g') > -1 ? 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/ClientName-01.png' : 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Monjardin-01.png'} alt={selections.indexOf('g') > -1 ? 'Generic Logo' : 'Monjardin Logo'} />
          </ButtonCheckboxTopicTwo>

          <div>
            {selections.indexOf('g') > -1 ? <FontAwesomeIcon icon={faCheckCircle} className={`${classes.check} checkIcon`} /> : ''}
          </div>

        </div>
      </div>
      {/* Right sidequestions */}
      <div style={{ flex: 1 }}>
        {
          questionRight.map((opt, index = 2) => {
            // String 152 below: We don't want to map the img question
            return (
              opt.id !== 'g' ?
                <div key={opt.id} className={classes.questionRight}>
                  <div>
                    <ButtonCheckboxTopicTwo
                      checked={selections.indexOf(opt.id) > -1}
                      isCorrect={correctAnswer[index + 3][opt.id]}
                      optionText={''}
                      updateChecked={(checked) => updateChecked(opt.id, checked)}
                      company={opt.company}
                      title={opt.title}
                      text={opt.text}
                      id={'rigth_CB' + index}
                    />
                  </div>

                  <div>
                    {selections.indexOf(opt.id) > -1 ? <FontAwesomeIcon icon={faCheckCircle} className={`${classes.check} checkIcon`} /> : ''}
                  </div>
                </div> : '')
          })
        }
      </div>
      <div className={classes.buttonContainer}>
        <div className={`${classes.counter} ${buttinIsDisabled ? 'notCompleted' : 'completed'}`} >
          <span > {`${selections.filter(u => !(u in wrongAnswer)).length} / ${ans.length}`} </span>
        </div>
        <div>
          <ButtonCustom
            isBloop={false}
            disabled={buttinIsDisabled}
            disabledOuterStyles={buttinIsDisabled ? disabledOuterStyles : {}}
            disabledInnerStyles={buttinIsDisabled ? disabledInnerStyles : {}}
            disabledBorderStyles={buttinIsDisabled ? disabledBorderStyles : {}}
            onClick={submitAnswers}
            buttonText={'SUBMIT!'}
            overlayRootStyles={buttinIsDisabled ? disabledRootStyles : overlayRootStyles}
          />
        </div>
      </div>
    </div>
  );
};

TopicTwoQuestion.propTypes = {

};

export default TopicTwoQuestion;
