import React, { useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode'
import useInitialFocus from '../../utils/hooks/useInitialFocus';
import useScorm from '../../utils/hooks/useScorm';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '50%',
    maxWidth: 1000,
    minWidth: 360,
    border: '0.3em solid black',
    padding: '1vw 1.5vw',
    boxSizing: 'border-box',
    background: '#fff',
    '& img': {
      width: '100%',
      height: 'auto'
    },
    '& .lastText': {
      fontFamily: "'Graphik Black', Arial, sans-serif !important",
      fontSize: '2.1vw'
    },
    '@media screen and (max-width: 999px)': {
      width: '60%'
    },
    '@media screen and (max-width: 767px)': {
      width: '70%'
    },
    '@media screen and (max-height: 375px)': {
      width: '70%'
    },
  },
  header: {
    fontSize: '3vw',
    marginBottom: '0.5em',
    '@media screen and (max-width: 767px)': {
      marginTop: '0.2em'
    },
    '@media screen and (max-height: 375px)': {
      marginTop: '0.2em'
    }
  },
  completionMessage: {
    marginTop: 0,
    fontSize: '1vw',
    '@media screen and (max-width: 767px)': {
      fontSize: '2vw',
      marginBottom: 0
    },
    '@media screen and (max-height: 375px)': {
      fontSize: '2vw',
      marginBottom: 0
    }
  },
  qrBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  qrTextBox: {
    paddingLeft: '1vw',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& span': {
      fontFamily: "'Graphik Black', Arial, sans-serif !important",
      fontSize: '2vw',
    },
    '& p': {
      fontSize: '2vw',
    },
    '@media screen and (max-width: 767px)': {
      fontSize: 14
    },
    '@media screen and (max-height: 375px)': {
      fontSize: 14,
    }
  },
  canvas: {
    width: '14vw !important',
    height: '14vw !important'
  }
});

const Certificate = (props) => {
  const classes = useStyles();
  const initFocus = useInitialFocus();
  const currentDate = useSelector(state => state.progress.dateOfCompletion);
  const { getStudentName } = useScorm();

  const studentName = useMemo(() => {
    return getStudentName();
  }, [getStudentName]);

  useEffect(() => {
    var canvas = document.getElementById('canvas');
    //text for QR
    let text = `
    ${studentName}
    Completed on: ${currentDate}`

    QRCode.toCanvas(canvas, text, function (error) {
      if (error) console.error(error)
    })
  }, [currentDate, studentName])

  return (
    <div ref={initFocus} tabIndex={0} className={classes.root}>
      <header>
        <h1 className={classes.header}>Certificate of completion</h1>
      </header>
      <section>
        <p className={classes.completionMessage}>
          This certificate shows that you have satisfied the completion requirements for this training. You may capture a screen shot or print this certificate for an additional record of your completion.
          </p>
        <div className={classes.qrBox}>
          <canvas className={classes.canvas} id="canvas"></canvas>
          <div className={classes.qrTextBox}>
            <p><span>Course: </span> Spin for Security</p>
            <p><span>Name: </span>{studentName}</p>
            <p><span>Date: </span>{currentDate}</p>
          </div>
        </div>
        <p style={{ fontSize: '1.3vw' }}>QR Code (for MyLearning administrative use only).</p>
        <div className={'lastText'}>Your completion has been sent to MyLearning.</div>
      </section>
      <label style={{ color: 'transparent' }}>To complete the course, select the Finish Course button.</label>
    </div>
  )
};

Certificate.propTypes = {};

export default Certificate;
