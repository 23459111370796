import { createSelector } from 'reselect';
import { steps } from '../data/steps';

/*
  ✓ Check to see if character has been selected (don't show intro/character selection) 
  ✓ Check to see if any topics are in progress (on a step that isn't 'start' and not marked complete)
  ✓ Check to see if all topics are completed (go to summary)
  If no topics are in progress, not all topics are completed, 
    and a character has been selected, go to the board view
  HOWEVER, if the game piece is on a topic space (check lottie steps), 
    check to see that topic's completion status. If it is completed, 
    then we should let the player spin. If it is not completed and not
    in progress, that means that it needs to be started and we should redirect to that topic.
*/

const getCharacterSelected = (state) => state.player.character;
const getCurrentPath = (state) => state.progress.currentPath;
const getCurrentStep = (state) => state.progress.currentStep;
const getCharacterMoving = (state) => state.progress.characterMoving;

const getTopicOneStatus = (state) => ({ 
  completed: state.questions.t01.completed, 
  step: state.questions.t01.step 
});
const getTopicTwoStatus = (state) => ({ 
  completed: state.questions.t02.completed, 
  step: state.questions.t02.step 
});
const getTopicThreeStatus = (state) => ({ 
  completed: state.questions.t03.completed, 
  step: state.questions.t03.step 
});
const getTopicFourStatus = (state) => ({ 
  completed: state.questions.t04.completed, 
  step: state.questions.t04.step 
});

const getTopicOneInProgress = createSelector(
  [getTopicOneStatus, getCurrentPath], 
  (topicOneStatus, currentPath) => { 
    return !topicOneStatus.completed && 
      (topicOneStatus.step !== 'start' || currentPath === '/topic-1');
  }
);
const getTopicTwoInProgress = createSelector(
  [getTopicTwoStatus, getCurrentPath], 
  (topicTwoStatus, currentPath) => { 
    return !topicTwoStatus.completed && 
      (topicTwoStatus.step !== 'start' || currentPath === '/topic-2');
  }
);
const getTopicThreeInProgress = createSelector(
  [getTopicThreeStatus, getCurrentPath], 
  (topicThreeStatus, currentPath) => {
    return !topicThreeStatus.completed && 
      (topicThreeStatus.step !== 'start' || currentPath === '/topic-3');
  } 
);
const getTopicFourInProgress = createSelector(
  [getTopicFourStatus, getCurrentPath], 
  (topicFourStatus, currentPath) => {
    return !topicFourStatus.completed && 
      (topicFourStatus.step !== 'start' || currentPath === '/topic-4');
  }
);

const getOnLastStep = createSelector(
  [getCurrentStep],
  (currentStep) => {
    return currentStep === steps.length - 1;
  }
);

const getShowSummary = createSelector(
  [
    getTopicOneStatus,
    getTopicTwoStatus,
    getTopicThreeStatus,
    getTopicFourStatus,
    getOnLastStep,
    getCharacterMoving
  ],
  (topicOneStatus, topicTwoStatus, topicThreeStatus, topicFourStatus, onLastStep, characterMoving) => {
    return topicOneStatus.completed && 
      topicTwoStatus.completed && 
      topicThreeStatus.completed && 
      topicFourStatus.completed && 
      onLastStep &&
      !characterMoving;
  }
);

const getGameProgress = createSelector(
  [
    getCharacterSelected, 
    getShowSummary,
    getTopicOneInProgress,
    getTopicTwoInProgress,
    getTopicThreeInProgress,
    getTopicFourInProgress
  ],
  (
    characterSelected, 
    showSummary, 
    topicOneInProgress, 
    topicTwoInProgress, 
    topicThreeInProgress, 
    topicFourInProgress
  ) => {
    switch (true) {
      case !characterSelected:
        return '/';
      case showSummary:
        return '/summary';
      case topicOneInProgress:
        return '/topic-1';
      case topicTwoInProgress:
        return '/topic-2';
      case topicThreeInProgress:
        return '/topic-3';
      case topicFourInProgress:
        return '/topic-4';
      default: 
        return '/board';
    }
  }
);

export default getGameProgress;

