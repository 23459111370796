import React, { useState, useCallback, useEffect, useLayoutEffect, useRef, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useSpring, animated as a } from 'react-spring';
import { BoardContext } from '../../contexts/boardContext';
import { steps, lottieEvents } from '../../data/steps';
import AnimatedGamePiece from './AnimatedGamePiece';
import { walkingAudioSrc } from '../../data/audioMap';
import { useSelector } from 'react-redux';
import { sfxPlayHandler } from '../../utils/helpers';

const useStyles = createUseStyles({
  box: {
    position: 'absolute',
  },
  lottiePlaceholder: {
    width: 0,
    height: 0,
    borderTop: '10px solid #880000',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    transformOrigin: 'center'
  },
  hiddenReaderText: {
    position: 'absolute',
    left: -10000,
    top: 'auto',
    height: 1,
    width: 1,
    overflow: 'hidden'
  }
});

const getPathScale = (svgRef) => {
  const { width: baseWidth } = svgRef.current?.viewBox?.baseVal || { width: 0 };
  return !!baseWidth ? svgRef.current.clientWidth / baseWidth : 0;
};

const GamePiece = ({ pathRef, pathStyles }) => {
  const classes = useStyles();
  const path = useRef();
  const pathLength = useRef(0.1);
  const { machineState, send } = useContext(BoardContext);
  const { lastStep, currentStep } = machineState.context;
  const [, set_] = useState(); // I don't know why but this is necessary for resizing (setting triggers additional re-render)
  const percentCompleted = useMemo(() => steps[currentStep]?.restPct || 0, [currentStep]);
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  const nextThreshold = useRef(lottieEvents.findIndex((step) => steps[currentStep]?.restPct < step.toPct));
  const prevThreshold = useRef(nextThreshold.current - 1);

  const [rotate, setRotate] = useState(lottieEvents[nextThreshold.current].cycle);

  //  const walkingRef = useRef();
  //  const stoppedRef = useRef();
  const audioWalkingRef = useRef();

  const interpolater = useCallback((completed) => {
    const pathScale = getPathScale(pathRef);
    const { x, y } = (path.current ? path.current.getPointAtLength(pathLength.current * (completed / 100)) : { x: 0, y: 0 });

    return `translate(${x * pathScale}px, ${y * pathScale}px)`;
  }, [pathRef, path, pathLength]);

  const [{ currPct }, set, stop] = useSpring(() => ({
    to: { currPct: percentCompleted },
    onFrame: (val) => {
      if (val.currPct > lottieEvents[nextThreshold.current].toPct) { // goal is to replace steps with lottie direction changes
        prevThreshold.current = nextThreshold.current;
        setRotate(lottieEvents[++nextThreshold.current].cycle);
      }
    }
  }));

  const dimensions = useMemo(() => {
    const height = pathStyles.height || 0;
    // ratio of height to width is 23:7 (1150px x 350px)
    return {
      width: height / 12,
      height: height / 6,
      left: -height / 24, // note this is the width, divided by 2 to center
      top: -height / 6,
    };
  }, [pathStyles]);

  // order matters! this useEffect comes first!
  useEffect(() => {
    path.current = pathRef.current?.querySelector('path');
    pathLength.current = path.current && path.current.getTotalLength();
  }, [pathRef, path]);

  useEffect(() => {
    if (machineState.matches('characterMoving')) {
      set({
        to: async (next, _cancel) => {
          await next({ currPct: percentCompleted })
        },
        config: { duration: machineState.context.currentSpin * 800 }, // set timing to multiple of duration of one walk cycl
        onRest: () => {
          send('COMPLETED');
        }
      });
      stop();
    }
  }, [machineState, send, percentCompleted, set, stop]);

  // Walking SFX 
  useEffect(() => {
    try {
      if (machineState.matches('characterMoving')) {
        audioWalkingRef.current.volume = 0.5;
        soundsEffects ? sfxPlayHandler(audioWalkingRef) : audioWalkingRef.current.pause()
      } else {
        audioWalkingRef.current.pause()
      }
    }
    catch { }
  }, [machineState, soundsEffects])

  // this is here to force an additional re-render which helps with resizing 
  useLayoutEffect(() => {
    set_(Math.random());
  }, [pathStyles]);

  return (
    <a.div
      className={classes.box}
      style={{
        ...dimensions,
        transform: currPct.interpolate(interpolater)
      }}
    >
      <AnimatedGamePiece
        isPlaying={machineState.matches('characterMoving')}
        direction={rotate}
      />
      <audio id='wallkingAudio' ref={audioWalkingRef} src={walkingAudioSrc} type='audio' />
      { machineState.matches('characterMoving') ?
        <span role='alert' className={classes.hiddenReaderText}>
          Character is walking to space {currentStep} from space {lastStep}
        </span>
        : null}
      {/* <div className={classes.lottiePlaceholder} style={{transform: `rotate(${rotate})`}}></div> */}
    </a.div>
  );
};

GamePiece.propTypes = {
  percentCompleted: PropTypes.number,
  pathRef: PropTypes.object,
  pathStyles: PropTypes.object,
  currentSpin: PropTypes.number
};

export default GamePiece;
