import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { AudioContext } from '../../contexts/audioContext';

const useStyles = createUseStyles({
  buttonContainer: {
    display: 'block',
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    width: '16vw',
    height: '5.5vw',
    padding: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    backfaceVisibility: 'hidden',
    '& *': {
      backfaceVisibility: 'hidden'
    },
    '&:hover .outer': {
      left: 0,
      top: 0,
      //opacity: 0.2,
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
        opacity: 0,
      },
    },
    '&:hover .inner': {
      transform: 'scale(1.05)',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
        transform: 'scale(0.99)',
      },
    },
  },
  button: {
    position: 'relative',
    border: 'none',
    width: 'inherit',
    // height: 'inherit',
    padding: '0 8px',
    margin: 0,
    outline: 'none',
    cursor: 'pointer',
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    }
  },
  //outside polygon
  outerPolygon: {
    position: 'absolute',
    top: '3px',
    left: '3px',
    display: 'inline-block',
    width: 'calc(100% + 0.3vw)',
    height: 'inherit',
    background: 'rgb(79,13,116)',
    boxSizing: 'border-box',
    clipPath: 'polygon(2% 3%, 95% 0, 100% 100%, 0 100%)',
    transition: 'all 200ms ease-in-out',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      borderRadius: '0.9rem',
      top: '1px',
      left: '0px',
      transition: 'all 400ms ease-in-out',
    },
  },
  //inside background
  innerPolygon: {
    background: '#8b00ff',
    width: 'inherit',
    height: '94%',
    clipPath: 'polygon(2% 3%, 95% 0, 100% 100%, 0 100%)',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 200ms ease-in-out',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      position: 'absolute',
      borderRadius: '0.5rem',
      top: 0,
      width: '99%',
    },

  },
  //right top corner border
  innerRightBorder: {
    position: 'absolute',
    textAlign: 'center',
    width: '93%',
    height: '97%',
    clipPath: 'polygon(64% 13%, 100% 9%, 99% 58%, 97% 16%)',
    backgroundColor: '#cc00ff',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      display: 'none'
    },

  },
  //left bottom corner border
  innerLeftBorder: {
    position: 'absolute',
    textAlign: 'center',
    width: 'inherit',
    height: 'inherit',
    clipPath: 'polygon(6% 49%, 8% 90%, 46% 93%, 4% 94%)',
    backgroundColor: '#cc00ff',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      display: 'none'
    },

  },
  textContainer: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    transform: 'rotate(-2deg)',
    display: 'flex',
    alignItems: 'center',
  },
  textContainer_span: {
    color: '#fff',
    font: 'bold 2.1vw system-ui',
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    overflowWrap: 'break-word',
    textTransform: 'uppercase',
    display: 'inline-block',
    marginBottom: -5
  }
});

const ButtonCustom = React.forwardRef(({
  onClick,
  buttonText = "",
  id = "",
  autoFocus = false,
  arialabeltext,
  disabled = false,
  overlayRootStyles,
  overlayTextSpanStyles,
  disabledOuterStyles,
  disabledInnerStyles,
  disabledBorderStyles,
  classes = {},
  isBloop = true
}, ref) => {

  const localClasses = useStyles();
  const { playEffect } = useContext(AudioContext)

  const onClickHandler = () => {
    if (onClick) onClick();
    if (isBloop) playEffect('bloop')
  }

  return (
    <button
      autoFocus={autoFocus}
      id={id}
      ref={ref}
      disabled={disabled}
      onClick={onClickHandler}
      aria-label={arialabeltext}
      tabIndex={0}
      className={`${localClasses.buttonContainer} ${classes.root}`}
      style={{ ...overlayRootStyles }}
    >
      <div className={`${localClasses.outerPolygon} outer`} style={{ ...disabledOuterStyles }}></div>
      <div className={`${localClasses.innerPolygon} inner`} style={{ ...disabledInnerStyles }}>
        {/* Apply this styles only for chrome engine */}
        <div className={localClasses.innerRightBorder} style={{ ...disabledBorderStyles }}></div>
        <div className={localClasses.innerLeftBorder} style={{ ...disabledBorderStyles }}></div>
        <div className={`${localClasses.textContainer}`}>
          <div className={localClasses.button} >
            <span
              className={`${localClasses.textContainer_span} ${classes.buttonText}`}
              style={{ ...overlayTextSpanStyles }}
            >
              {buttonText}
            </span>
          </div>
        </div>
      </div>
    </button>
  );
});

ButtonCustom.propTypes = {
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  overlayRootStyles: PropTypes.object,
  disabledOuterStyles: PropTypes.object,
  disabledInnerStyles: PropTypes.object,
  disabledBorderStyles: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string)
}

export default ButtonCustom;
