import React, { useRef, useEffect, useContext, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSpring, animated as a, config } from 'react-spring';
import { BoardContext } from '../../contexts/boardContext';
import { useDispatch } from 'react-redux';
import { setStep } from '../../store/action-creators';
import { steps } from '../../data/steps';
import ButtonCustom from '../inputs/ButtonCustom';

const useStyles = createUseStyles({
  spinnerContainer: {
    position: 'absolute',
    bottom: '-6vw',
    left: '-6vw',
    '@media screen and (min-aspect-ratio: 2/1)': {
      bottom: '-12.5vh',
      left: '-12.5vh'
    }
    // overflow: 'hidden', //so we could see button, cause button is absolute
  },
  spinnerInner: {
    position: 'relative',
    width: '25vw',
    height: '25vw',
    maxWidth: 475,
    maxHeight: 475,
    minWidth: 150,
    minHeight: 150,
    overflow: 'hidden',
    '@media screen and (min-aspect-ratio: 2/1)': {
      width: '50vh',
      height: '50vh'
    }
  },
  disc: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    pointerEvents: 'none',
  },
  pointer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
});

const degrees = [
  null,
  0,
  -45,
  -90,
  -135,
  -180,
  -225,
  -270,
  -315,
];

const calculateCenterPosition = () => {
  const aspectRatio = window.innerWidth / window.innerHeight;
  return aspectRatio < 2
    ? `translate(${(window.innerWidth / 2) - (window.innerWidth * 0.125)}px, -${(window.innerHeight / 2) - (window.innerWidth * 0.05)}px)`
    : `translate(${(window.innerWidth / 2) - (window.innerHeight * 0.25)}px, -${(window.innerHeight / 2) - (window.innerHeight * 0.13)}px)`
};

const Spinner = (props) => {
  const classes = useStyles();
  const { machineState, send } = useContext(BoardContext);
  const { currentSpin, currentStep } = machineState.context;
  const [open, setOpen] = useState(machineState.matches('awaitingInput'));
  const rotation = useRef(degrees[currentSpin]);
  const rendered = useRef(false);
  const dispatch = useDispatch();
  const buttonRef = useRef();

  const [{ rotate }, setSpin, stopSpin] = useSpring(() => ({
    to: { rotate: `${degrees[currentSpin]}deg` },
  }));

  const [{ translate }, setCorner, stopCorner] = useSpring(() => ({
    from: { translate: 'translate(0px, 0px)' },
    to: async (next, _cancel) => {
      await next({
        translate: calculateCenterPosition()
      })
    },
    delay: 2000,
    config: config.wobbly,
  }));

  const spin = () => {
    if (!steps[currentStep] && currentStep > 0) {
      return dispatch(setStep(0)); // we should end the game by this point, but including here for testing and safety
    }

    const { range } = steps[currentStep];
    const randomSpin = Math.ceil(Math.random() * (range[1] - (range[0])) + range[0]);

    // dispatch(setSpinAndStep(randomSpin));
    send('SPIN', { value: randomSpin });
  };

  const [{ x }, setButton] = useSpring(() => ({ x: -40 }));
  // Effects

  useEffect(() => {
    if (machineState.matches('spinning') && rendered.current) {
      const { currentSpin, lastSpin } = machineState.context;

      setSpin({
        to: async (next, _cancel) => {
          await next({
            rotate: `${rotation.current - 1800 + (degrees[currentSpin] - degrees[lastSpin])}deg`
          })
        },
        onRest: () => {
          send('COMPLETED');
          setOpen(false);
          rotation.current = rotation.current - 1800 + (degrees[currentSpin] - degrees[lastSpin]);
        }
      });

      stopSpin();
    }
    rendered.current = true;
  }, [machineState, send, setSpin, stopSpin]);

  useEffect(() => {
    machineState.matches('awaitingInput') && setOpen(true);
  }, [machineState]);

  useEffect(() => {
    setCorner({
      to: {
        translate: open
          ? calculateCenterPosition()
          : 'translate(0, 0)'
      },
      delay: 1000,
      onRest: () => {

        if (open) {
          setButton({ x: 81 })
          send('START')
          buttonRef.current && buttonRef.current.focus();
        }
        else {
          setButton({ x: -40 })
        }
      }
    });
    //useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.1 : 0.6])
  }, [send, open, setCorner, stopCorner]);

  const overlayButtonStyles = {
    position: 'relative',
    width: '11vw',
    height: '8vh',
    marginTop: 0
  }


  return (
    <a.div className={classes.spinnerContainer} style={{ transform: translate }}>
      <a.div
        style={{ position: 'absolute', top: '50%', right: '0', transform: x.interpolate(v => `translateX(${v}%`) }}>
        <ButtonCustom
          ref={buttonRef}
          disabled={!machineState.matches('awaitingInput')}
          onClick={spin}
          buttonText={"SPIN!"}
          overlayRootStyles={overlayButtonStyles}
          arialabeltext={'Spin.'}
        />
      </a.div>
      <div className={classes.spinnerInner}>
        <a.img
          src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Spinner-01.png'}
          alt=''
          id='disc'
          className={classes.disc}
          style={{ transform: rotate.interpolate(rotate => `rotate(${rotate})`) }}
        />
        <img
          src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Spinner-Ticker-01.png'}
          alt=''
          id='pointer'
          className={classes.pointer}
        />
      </div>
    </a.div>
  );
};

Spinner.propTypes = {};

export default Spinner;
