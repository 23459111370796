import {
  SET_SPIN,
  SET_STEP,
  SET_CHARACTER_MOVING,
  SET_SPIN_AND_STEP,
  SET_SHOWED_PROFESSOR_INSTRUCTIONS,
  SET_SHOWED_FUN_FACT,
  SET_LAST_FUN_FACT_SHOWED,
  SET_HELPFUL_HINTS_SHOWED,
  SET_SPLASH,
  SET_CURRENT_PATH,
  SET_REPLAY_MODE,
  SET_SCORE,
  SET_LAST_STEP,
  MARK_TOPIC_COMPLETED,
  SET_TOPIC_POINT_TOTAL,
  SET_CHARACTER,
  SET_LIFE_CHOICE,
  INC_POINTS,
  DEC_POINTS,
  SET_LAST_POINT_TOTAL,
  TOGGLE_SOUNDS_EFFECTS,
  TOGGLE_MUSIC,
  RESET_PROGRESS,
  RESET_QUESTIONS,
  RESET_PLAYER, 
  SET_DATE,
  SET_MODAL_OPEN
} from './action-types';

/* Progress */

export function setSpin(spin) {
  return {
    type: SET_SPIN,
    payload: { spin }
  };
}

export function setStep(step) {
  return {
    type: SET_STEP,
    payload: { step }
  };
}

export function setSpinAndStep(spin) {
  return {
    type: SET_SPIN_AND_STEP,
    payload: { spin }
  };
}

export function setCharacterMoving(isMoving) {
  return {
    type: SET_CHARACTER_MOVING,
    payload: { isMoving }
  };
}

export function setShowedProfessorInstructions(ordinal) {
  return {
    type: SET_SHOWED_PROFESSOR_INSTRUCTIONS,
    payload: { ordinal }
  }
}

export function setShowedFunFact(showedFunFact) {
  return {
    type: SET_SHOWED_FUN_FACT,
    payload: { showedFunFact }
  };
}

export function setLastFunFactShowed(idx) {
  return {
    type: SET_LAST_FUN_FACT_SHOWED,
    payload: { lastFunFactShowed: idx }
  };
}

export function setHelpfulHintsShowed(id) {
  return {
    type: SET_HELPFUL_HINTS_SHOWED,
    payload: { helpfulHintsShowed: id }
  };
}

export function setSplash(splash) {
  return {
    type: SET_SPLASH,
    payload: { splash }
  };
}

export function setCurrentPath(currentPath) {
  return {
    type: SET_CURRENT_PATH,
    payload: { currentPath }
  };
}

export function resetProgress() {
  return {
    type: RESET_PROGRESS
  };
}

export function setDateOfCompletion(date) {
  return {
    type: SET_DATE,
    payload: { date }
  };
}

/* Questions */

export function setScore({ questionSet, question, response, score }) {
  return {
    type: SET_SCORE,
    payload: {
      questionSet, 
      question, 
      response, 
      score
    }
  };
}

export function setLastStep(questionSet, step) {
  return { 
    type: SET_LAST_STEP, 
    payload: { questionSet, step }
  };
}

export function markTopicCompleted(questionSet) {
  return {
    type: MARK_TOPIC_COMPLETED,
    payload: { questionSet }
  };
}

export function setTopicPointTotal(questionSet) {
  return {
    type: SET_TOPIC_POINT_TOTAL,
    payload: { questionSet }
  };
}

export function resetQuestions() {
  return {
    type: RESET_QUESTIONS
  };
}

/* Player */

export function setCharacter(character) {
  return {
    type: SET_CHARACTER,
    payload: { character }
  };
}

export function setLifeChoice(field, value) {
  return {
    type: SET_LIFE_CHOICE,
    payload: { field, value }
  };
}

export function increasePoints(points) {
  return {
    type: INC_POINTS,
    payload: { points }
  };
}

export function decreasePoints(points) {
  return {
    type: DEC_POINTS,
    payload: { points }
  };
}

export function setLastPointTotal(currentTotal) {
  return {
    type: SET_LAST_POINT_TOTAL,
    payload: { lastPointTotal: currentTotal }
  };
}

export function resetPlayer() {
  return {
    type: RESET_PLAYER
  };
}

/* Settings */

export function toggleMusic(music) {
  return {
    type: TOGGLE_MUSIC,
    payload: { music }
  };
}

export function toggleSoundsEffects(soundsEffects) {
  return {
    type: TOGGLE_SOUNDS_EFFECTS,
    payload: { soundsEffects }
  };
}

export function setReplayMode(inReplay) {
  return {
    type: SET_REPLAY_MODE,
    payload: { replayMode: inReplay }
  };
}

export function setModalOpen(modalOpen) {
  return {
    type: SET_MODAL_OPEN,
    payload: { modalOpen }
  };
}
