import React, { useEffect, useState, useRef, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import { ReactComponent as WalkPath } from '../../static/svg/WalkPath.svg';
import GamePiece from './GamePiece';
import Frame from './Frame';
// import TestGamePiece from './testcomponents/TestGamePiece';
// import PathSlider from './testcomponents/PathSlider';

const isChromium = !!window.chrome;
const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    justifyContent: 'center',
    backgroundImage: `url(https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/TableTop-01.jpg)`,
  },
  boardContainer: {
    position: 'relative',
    height: 'inherit',
    width: 'inherit',
    alignSelf: 'center',
    display: 'flex',
  },
  board: {
    flexShrink: 0,
  },
  path: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  sliderContainer: {
    position: 'absolute',
    bottom: 40,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  box: {
    alignSelf: 'center',
  }
});

//const for addittional styles board, path, gamepeace

const aspectRatio = 16 / 9;

const determineIfWindowIsWide = () => aspectRatio <= window.innerWidth / window.innerHeight;


const Board = ({ setBoardSize, boardSize }) => {
  const classes = useStyles();
  const boardRef = useRef();
  const pathRef = useRef();
  const [pathStyles, setPathStyles] = useState({});
  const [boardStyles, setBoardStyles] = useState(() => {
    return determineIfWindowIsWide()
      ? { height: '100%' } : { width: '100%' };
  });

  const positionAndScaleImageOnResize = useCallback(() => {
    const newBoardStyles = determineIfWindowIsWide() ? { height: '100%' } : { width: '100%', height: 'auto' };
    if (boardStyles.width !== newBoardStyles.width) {
      setBoardStyles(newBoardStyles);
    }
  }, [boardStyles, setBoardStyles]);

  /* Effects */
  useEffect(() => {
    window.addEventListener('resize', positionAndScaleImageOnResize);
    return () => window.removeEventListener('resize', positionAndScaleImageOnResize);

  }, [positionAndScaleImageOnResize]);

  useEffect(() => {
    const board = boardRef.current;
    const path = pathRef.current;
    const newPathStyles = {};

    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) return;
      const entry = entries[0];

      if (path.width !== entry.contentRect.width) newPathStyles.width = entry.contentRect.width;
      if (path.height !== entry.contentRect.height) newPathStyles.height = entry.contentRect.height;
      setBoardSize({ height: entry.contentRect.height, width: entry.contentRect.width })
      setPathStyles((styles) => ({ ...styles, ...newPathStyles }));
    });
    resizeObserver.observe(board);

    return () => resizeObserver.unobserve(board);
  }, []);

  // FOR TESTING
  // const [sliderPosition, setSliderPosition] = useState(0);

  return (
    <main id='main' className={classes.root}>
      <div className={classes.box}
        style={boardStyles}
      >
        <div className={classes.boardContainer} style={ !determineIfWindowIsWide() ? { } : { width: boardSize.width }} >
          <img
            src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Board-1600x900.jpg'}
            alt=''
            style={{ ...boardStyles, height: '100%' }}
            className={classes.board}
            ref={boardRef}
          />
          <WalkPath
            className={classes.path}
            style={pathStyles}
            ref={pathRef} // fortunately this works b/c SVGR forwards ref to svg
          />
          <GamePiece
            pathStyles={pathStyles}
            pathRef={pathRef}
          />
          <Frame boardSize={boardSize} />

          {/* <TestGamePiece 
          pathStyles={pathStyles} 
          pathRef={pathRef}
          sliderPosition={sliderPosition}
        /> */}
        </div>
      </div>
      {/* <PathSlider sliderPosition={sliderPosition} updateSlider={(pct) => setSliderPosition(pct)}/> */}
    </main>
  );
};

Board.propTypes = {

};

export default Board;
