import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import Portal from '@reach/portal';
import useScorm from '../../utils/hooks/useScorm';

const useStyles = createUseStyles({
  exitButton: {
    position: 'fixed',
    bottom: 0,
    right: 8,
    zIndex: 10,
    // transform: 'translate(-50%, 0)',
    fontSize: 15,
    color: '#ffffff',
    backgroundColor: '#7500c0',
    padding: 12,
    border: 'none',
    boxShadow: '3px 5px 0 2px #460073',
    cursor: 'pointer',
    transition: 'transform 200ms, box-shadow 200ms',
    transformOrigin: 'center',
    '&:hover': {
      transform: 'scale(1.04)',
      boxShadow: '0 0 0 0 #460073'
    },
    '@media screen and (max-width: 767px)': {
      fontSize: 14,
      padding: 10
    },
    '@media screen and (max-width: 999px) and (min-aspect-ratio: 2 / 1)': {
      bottom: 0,
      right: 0
    },
    '@media screen and (min-aspect-ratio: 2.15 / 1)': {
      bottom: 0,
      right: 0
    }
  },
  hidden: {
    visibility: 'hidden'
  }
});

const ExitCourseButton = ({ finish, modal }) => {
  const classes = useStyles();
  const { exitCourse, finishCourse } = useScorm();

  const executeWithoutPropagation = (fn) => (evt) => {
    evt.stopPropagation();
    fn();
  };

  const modalOpen = useSelector((state) => state.settings.modalOpen);

  if (modal) {
    return (
      <>
      <button 
        className={classes.exitButton} 
        onClick={finish ? executeWithoutPropagation(finishCourse) : executeWithoutPropagation(exitCourse)}
      >
        {finish ? 'Finish' : 'Exit'} Course
      </button>
      <div></div>
      </>
    );
  }

  return (
    <Portal>
      <button 
        className={`${classes.exitButton} ${modalOpen ? classes.hidden : ''}`} 
        onClick={finish ? finishCourse : exitCourse}
      >
        {finish ? 'Finish' : 'Exit'} Course
      </button>
      <div></div>
    </Portal>
  );
};

ExitCourseButton.propTypes = {
  finish: PropTypes.bool,
  modal: PropTypes.bool
};

export default ExitCourseButton;
