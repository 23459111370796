import React from 'react';
import PropTypes from 'prop-types';
import {
  CustomCheckboxContainer,
  CustomCheckboxInput
} from '@reach/checkbox';
import "@reach/checkbox/styles.css";
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'inline-block',
    padding: 0,
    height: 'auto',
    width: '85%',
    maxWidth: 350,
    margin: 0,
    cursor: 'pointer',
    fontSize: '1.35vw',
    '@media screen and (min-aspect-ratio: 3 / 1)': {
      width: '95%'
    }
  },
  button: {
    display: 'block',
    padding: '1em',
    color: 'black',
    fallbacks: {
      // background: 'rgba(0,255,166, 8%)' 
    },
    fontSize: '0.75em'
  },
  green: {
    color: 'green'
  },
  red: {
    color: 'red'
  },
  bold: {
    '& span': {
      fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    }
  },
  hide: {
    display: 'none'
  }
});

const ButtonCheckboxTopicTwo = ({ ariaLabel = null, isCorrect, children, id, checked, updateChecked, company, title = 'test', text = 'test text', textTwo, textThree, hide, hideTwo, }) => {
  const classes = useStyles();

  const update = (evt) => {
    updateChecked(evt.target.checked);
  };
  return (
    <CustomCheckboxContainer
      checked={checked}
      onChange={update}
      className={`${classes.container} ${checked ? 'selected' : ''}`}
    >
      <CustomCheckboxInput aria-label={ariaLabel ? ariaLabel : null} id={id} checked={checked} onChange={update} />
      <span
        aria-hidden
        className={`${classes.button} ${checked ? classes.selected : ''} ${checked ? isCorrect ? classes.green : classes.red : ''}`}
      >
        <div className={classes.bold}>
          <span className={`${checked ? classes.hide : ''}`}>{company} </span><span>{title}</span>
        </div>
        <div>
          <label htmlFor={id}>
            {text}
            <span className={`${checked ? classes.hide : ''}`}>
              {hide}
            </span>
            {textTwo}
            <span className={`${checked ? classes.hide : ''}`}>
              {hideTwo}
            </span>
            {textThree}
          </label>
        </div>
        {children}
      </span>
    </CustomCheckboxContainer>
  )
};

ButtonCheckboxTopicTwo.propTypes = {
  optionText: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  updateChecked: PropTypes.func.isRequired
};

export default ButtonCheckboxTopicTwo;
