import { setSplash } from '../../store/action-creators';
import { connect } from 'react-redux';
import React, { useContext, useEffect } from 'react';
//import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { IntroContext } from '../../contexts/introContext';


const useStyles = createUseStyles({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: 'url(https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Cover-05.jpg)',
    '@media screen and (max-width: 750px) and (orientation:landscape) ':{
      backgroundSize: '110%',
      backgroundPosition: 'top'
    }
  },
  logo: {
    position: 'relative',
    top: 10, 
    left: 10,
    width: 186,
    height: 87,
    '@media screen and (max-width: 812px) and (orientation:landscape) ':{
      width: 113,
      height: 'auto',
      left: 60
    }
  }
});

const Splash = ({ splash, setSplash }) => {
  const classes = useStyles();
  const { send } = useContext(IntroContext);
  // need to look into aria-label or some other accessibility option that will read 'Spin for Security' to a screen-reader
  useEffect(() => {
    if(!splash) {
      setSplash(true);
    }
    return () => setSplash(false);
    /* eslint-disable-next-line */
  }, []);

  return (
    <section className={classes.root} onClick={() => send('CONTINUE')}>
      <img src='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/AccenturePresents.png' alt='' role='presentation' className={classes.logo}/>
    </section>
  );
};

Splash.propTypes = {

};

const mapStateToProps = (state, ownProps) => ({
  splash: state.progress.splash
});

const mapDispatchToProps = (dispatch) => ({
  setSplash: (splash) => dispatch(setSplash(splash))
});

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
