import BackButton from '../components/inputs/BackButton';
import React, { useContext, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPath } from '../store/action-creators';
import { TopicOneContextProvider, TopicOneContext } from '../contexts/topicOneContext';
import TopicOneInitialView from '../components/topics/topic-1/TopicOneInitialView';
import TopicOneScreenView from '../components/topics/topic-1/TopicOneScreenView';
import TopicOneQuestionView from '../components/topics/topic-1/TopicOneQuestionView';
import ExitCourseButton from '../components/inputs/ExitCourseButton';
import { createUseStyles } from 'react-jss';
import { audioClickMap } from '../data/audioMap';
import { sfxPlayHandler } from '../utils/helpers';


const useStyles = createUseStyles({
  viewContainer: {
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto', // necessary for InnerScreenView component to scroll on very wide screens
    backgroundColor: '#ddffff'
  }
})

const TopicOneContents = () => {
  const classes = useStyles();
  const { machineState } = useContext(TopicOneContext);
  const dispatch = useDispatch();
  const audioClickRef = useRef();
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  const showTopicOneScreenView = useMemo(() => {
    return machineState.matches('monitor');
  }, [machineState])

  const showTopicOneQuestionView = useMemo(() => {
    return machineState.matches('questionOne')
      || machineState.matches('questionTwo')
      || machineState.matches('finished')
      || machineState.matches('final');
  }, [machineState]);

  const showTopicOneInitialView = useMemo(() => {
    return !showTopicOneScreenView && !showTopicOneQuestionView;
  }, [showTopicOneScreenView, showTopicOneQuestionView]);


  useEffect(() => {
    if (machineState.matches('final')) {
      // history.push('/board');
      dispatch(setCurrentPath('/board'));
    }
  }, [machineState, dispatch]);

  useEffect(() => {
    audioClickRef.current.volume = 0.6;
  }, [])

  const SFXOnClick = () => {
    if (soundsEffects) {
      sfxPlayHandler(audioClickRef);
    }
  }

  return (
    <main>
      <audio id='music' ref={audioClickRef} src={audioClickMap[0]} type='audio' />
      <section className={classes.viewContainer}>
        {showTopicOneInitialView && <TopicOneInitialView />}
        {showTopicOneScreenView && <TopicOneScreenView SFXOnClick={SFXOnClick} />}
        {showTopicOneQuestionView && <TopicOneQuestionView />}
      </section>
      <BackButton hide={machineState.matches('start')}/>
    </main>
  );
};


const TopicOne = (props) => {
  return (
    <TopicOneContextProvider>
      <TopicOneContents />
      <ExitCourseButton />
    </TopicOneContextProvider>
  );
};

export default TopicOne;
