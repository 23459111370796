import React from 'react';
import PropTypes from 'prop-types';
import { YES, NO } from './constants';
import { createUseStyles } from 'react-jss';
import ButtonCustom from '../inputs/ButtonCustom';
import SmallHeader from '../typography/SmallHeader';
import OrangeHeader from '../typography/OrangeHeader';

const cardBaseStyles = {
  width: '23vw',
  height: '26vw',
  fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  textAlign: 'center',
  borderRadius: '27px',
  fontSize: '1.35vw',
};

const useStyles = createUseStyles({
  rootPlane: {
    ...cardBaseStyles,
    transform: 'rotate(-3deg)',
    //    '@media screen and (max-width: 767px)': {
    //      width: ''
    //    }
  },
  img: {
    width: '100%',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      height: '100%'
    },
  },
  rootTrain: {
    ...cardBaseStyles,
    transform: 'rotate(3deg)',
  },
  container: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      justifyContent: 'end',
      paddingBottom: 0
    }
  },
  bottomContainer: {
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: '0 0 10px 10px',
    width: '100%',
    minHeight: '8vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      minHeight: '16vh'
    }
  },
  middleContainer: {
    display: 'flex',
    alignSelf: 'center',
    marginLeft: '-1.8vw',
    marginRight: '-2vw',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      marginLeft: '-1.5vw',
      marginRight: '-1.5vw',
    },
  },
  text: {
    position: 'relative',
    top: '1.6vw',
    padding: '0 10px',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      top: '3vh'
    }
  },
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonRoot: {
    width: '15vw',
    height: '5vw',
    bottom: '-2.8vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '30vh',
      height: '10vh',
      bottom: '-5.6vh'
    }
  },
  buttonText: {
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      fontSize: '4.2vh'
    }
  }
});

const TravelForWork = ({ makeSelection, ariaId }) => {
  const classes = useStyles();

  const innerPoligonStyles = {
    width: '97.5%'
  };

  return (
    <>
      <OrangeHeader
        headerText={'Do you think you will be traveling for work?'}
        rootStyles={{
          paddingBottom: '1.3vw',
          width: '54vw',
          height: '5.5vw',
          position: 'relative',
          left: '50%',
          transform: 'translate(-50%, 0)',

        }}
        innerPoligonStyles={innerPoligonStyles}
        fontSize={{ fontSize: '0.9vw', textTransform: 'unset' }}
      />
      <div className={classes.root}>
        <div role='main' className={classes.rootPlane}>
          <div className={classes.container} >
            <img
              className={classes.img}
              src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/PlaneCard-01.png'}
              alt='airport'
              role='presentation'
            />
            <div className={classes.bottomContainer}>
              <div aria-label={'Yes'} className={classes.text} >
                Yes, I will be flying somewhere every week
              </div>
              <ButtonCustom
                onClick={() => makeSelection('travel', YES)}
                buttonText={'GO!'}
                arialabeltext={'Yes, I will travel'}
                classes={{ root: classes.buttonRoot, buttonText: classes.buttonText }}
              />
            </div>
          </div>
        </div>
        <div className={classes.middleContainer}>
          <SmallHeader />
        </div>
        <div className={classes.rootTrain}>
          <div className={classes.container} >
            <img
              className={classes.img}
              src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/TrainCard-01.png'}
              alt='train'
              role='presentation'
            />
            <div className={classes.bottomContainer}>
              <div role='main' aria-label={'No'} className={classes.text}>
                No, I'll only ever commute into the Accenture office
              </div>
              <ButtonCustom
                onClick={() => makeSelection('travel', NO)}
                buttonText={'GO!'}
                arialabeltext={'No, I will not travel'}
                classes={{ root: classes.buttonRoot, buttonText: classes.buttonText }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TravelForWork.propTypes = {
  ariaId: PropTypes.string.isRequired,
  makeSelection: PropTypes.func.isRequired
};

export default TravelForWork;
