import {
  increasePoints,
  setHelpfulHintsShowed,
  setLastPointTotal
} from '../../store/action-creators';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import PointsIncrementor from './PointsIncrementor';
import HelpfulHintCheckbox from '../inputs/HelpfulHintCheckbox';
import useScorm from '../../utils/hooks/useScorm';
import { createUseStyles } from 'react-jss';
import SkewedDialogBox from './SkewedDialogBox';
import ButtonCustom from '../inputs/ButtonCustom';
//import { TopicThreeContext } from '../../contexts/topicThreeContext';
//import { Disclosure, DisclosureButton, DisclosurePanel } from '@reach/disclosure';
import { helpfulHintsTopicThree as hints } from '../../data/hints';
import { ReactComponent as Tag } from '../../static/svg/Tag.svg';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '90%',
    marginTop: '-5vh',
    '@media screen and (min-aspect-ratio: 2.5 / 1)': {
      height: '83%',
      '& header': {
        marginTop: '-1.5vw'
      }
    }
  },
  content: {
    overflowWrap: 'anywhere',
    fontSize: '1.3vw'
  },
  pointsContainer: {
    maxWidth: 150,
    position: 'absolute',
    top: '-1.5vw',
    right: '-2vw',
    width: '12vw',
    '@media screen and (min-aspect-ratio: 2.5 / 1)': {
      top: '-0.5vw'
    }
  },
  hints: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    marginTop: '0.5vw',
    '&>li': {
      width: (100 / 4) + '%',
      padding: '5px 10px',
      position: 'relative'
    },
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      padding: 5,
      fontSize: '1.1vw',
      '@media screen and (max-width: 750px) and (orientation:landscape)':{
        fontSize: '1vw',
      },
    }
  },
  checkboxContainer: {
    position: 'relative',
  },
  hintContainer: {
    position: 'absolute',
    height: '3vw',
    width: 'calc(100% - 5.4vw)',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '2.5vw',
    paddingRight: '0.5vw',
    border: '1px solid lightgrey',
    '& p':{
      fontFamily: '\'Graphik Semibold\', Arial, sans-serif !important',
      fontSize: '1vw',
      fontWeight: 600,
      zIndex: '2',
      padding: 0,
      paddingRight: '1.3vw',
      '@media screen and (max-width: 1365px)': {
        fontSize: '0.9vw'
      }
    },
    '@media screen and (max-width: 999px)': {
      justifyContent: 'flex-start',
      padding: '0 0.5vw',
      '& p': {
        paddingRight: 0
      }
    }

  },
  helperText: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '1.7vw',
    marginTop: '0.75vw',
    marginBottom: '0.75vw',
    marginLeft: '0.5vw',
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    '& span': {
      fontWeight: 400,
      display: 'inline-block',
      marginTop: 5
    },
    '@media screen and (max-width: 750px) and (orientation:landscape)':{
      fontSize: 10
    },
  },
  securityBar: {
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      height: '1.1vw',
      alignItems: 'flex-start'
    },
    width: '100%',
    fontSize: '1.1vw',
    maxHeight: '2vw',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    marginLeft: '-1.5vw',
    paddingRight: '1.25vw',
    paddingLeft: '2vw',
    paddingBottom: '5.75vw',
    marginBottom: '-7.5vw',
    marginTop: '1vw',
    paddingTop: '0.75vw',
    '& svg':{
      width: '1.5vw',
      marginRight: 10,
      '&.red' : {
        fill: 'red'
      },
      '&.green' : {
        fill: 'green'
      },
      '&.blue' : {
        fill: 'blue'
      },
      '&.yellow' : {
        fill: '#e4ad00'
      },
    },
    '& span':{
      fontWeight: 600
    },
    '@media screen and (min-aspect-ratio: 2.5 / 1)': {
      marginTop: 0
    }
  },
  buttonContainer: {
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      position: 'absolute',
      bottom: '0px',
      right: '50%',
      transform: 'translate(50%, 0px)'
    }
  },
  buttonRoot: {
    width: '16vw',
    height: '4.4vw',
    // marginTop: '-1vw',
    '@media screen and (min-aspect-ratio: 2.5 / 1)': {
      marginTop: '-1vw'
    }
  }
});

const disabledInnerStyles = {
  background: 'darkgrey'
};

const disabledOuterStyles = {
  background: 'black'
};

const disabledBorderStyles = {
  background: 'lightgrey'
};

const overlayContentContainerStyles = {
  padding: '3.5vw 2.5vw'
};

const colorMap = {
  Restricted: 'red',
  Unrestricted: 'green',
  Confidential: 'blue',
  'Highly Confidential': 'yellow',
};


const ClassificationTopicThree = ({ helperText, buttonText = 'Next!', close, isOpen, bounce = true }) => {
  const classes = useStyles();
  const pointTotal = useSelector((state) => state.player.points);
  const helpfulHintsShowed = useSelector((state) => state.progress.helpfulHintsShowed);
  const dispatch = useDispatch();
  const { commitAndSaveProgress } = useScorm();
  const [isShowingContent, setOpen] = useState({ 0: false, 1: false, 2: false, 3: false });
  const [lastChoice, setLastChoice] = useState('Not set')
  const [isAllocatingPoints, setIsAllocatingPoints] = useState(false);

  const disableButton = useMemo(() => {
    return !hints.every((hint) => hint.id in helpfulHintsShowed);
  }, [helpfulHintsShowed]);

  const allocatePoints = (points, id) => {
    if (disableButton) {
      setIsAllocatingPoints(true);
      dispatch(increasePoints(points));
      dispatch(setLastPointTotal(pointTotal + points))
      dispatch(setHelpfulHintsShowed(id))
      commitAndSaveProgress();
    }
  };

  const handleCallBack = (idx, hintText) => {
    setOpen({ ...isShowingContent, [idx]: true })
    setLastChoice(hintText)
  };

  return (
    <div className={classes.root}>
      <SkewedDialogBox overlayContentContainerStyles={overlayContentContainerStyles}>
        <header>
          <p tabIndex={0} className={classes.helperText}>
            At Accenture we use four different classifications:
          </p>
        </header>
        <section>
          <div className={classes.securityBar}>
            <Tag className={colorMap[lastChoice]} alt='presentation' />
            Sensitivity: <span> {lastChoice}</span>
          </div>
          <ul className={classes.hints}>
            {hints.map((hint, idx) => (
              <li className={classes.liBox} key={buttonText + idx} >
                <div className={classes.hintContainer}>
                  <p>{hint.text}</p>
                </div>
                <div className={classes.checkboxContainer}>
                  <HelpfulHintCheckbox
                    checkBoxAriaLabel={hint.text}
                    allocatePoints={allocatePoints}
                    points={hint.points}
                    callback={() => handleCallBack(idx, hint.text)}
                    stack={true}
                    idx={idx}
                    id={hint.id}
                    hint={hint.content}
                    hintText={hint.text}
                  />
                </div>
              </li>
            ))}
          </ul>
          <div className={classes.buttonContainer}>
            <ButtonCustom
              disabled={disableButton}
              disabledOuterStyles={disableButton ? disabledOuterStyles : {}}
              disabledInnerStyles={disableButton ? disabledInnerStyles : {}}
              disabledBorderStyles={disableButton ? disabledBorderStyles : {}}
              onClick={close}
              buttonText={buttonText}
              classes={{ root: classes.buttonRoot }}
              // overlayRootStyles={disableButton ? disabledRootStyles : overlayRootButtonStyles}
              isBloop={false}
            >
            </ButtonCustom>
          </div>
          <div className={classes.pointsContainer}>
            <PointsIncrementor
              pointTotal={pointTotal}
              isOpen={isOpen}
              isAllocatingPoints={isAllocatingPoints}
              onRest={() => setIsAllocatingPoints(false)}
            />
          </div>
        </section>
      </SkewedDialogBox>
    </div>
  );
};

ClassificationTopicThree.propTypes = {
  hints: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      points: PropTypes.number.isRequired
    })
  ),
  helperText: PropTypes.string,
  buttonText: PropTypes.string,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ClassificationTopicThree;
