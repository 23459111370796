import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import createIntroMachine from '../machines/introMachine';
import useScorm from '../utils/hooks/useScorm';

/*---------------------------------*/

const IntroContext = React.createContext({});

/*---------------------------------*/

const IntroContextProvider = (props) => {
  const character = useSelector(state => state.player.character);
  // const questions = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  const scorm = useScorm();
  const [machineState, send] = useMachine(createIntroMachine({ character, dispatch, scorm }), { devTools: true });

  useEffect(() => {
    send('START');
  }, [send]);

  return (
    <IntroContext.Provider value={{ machineState, send }}>
      {props.children}
    </IntroContext.Provider>
  )
};

IntroContextProvider.propTypes = {
};

export { IntroContext, IntroContextProvider };

