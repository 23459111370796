import React, { } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

const useStyles = createUseStyles({
    coinHudContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    coinHud: {
        width: '100%',
    },
    text: {
        position: 'absolute',
        left: '25%',
        top: '16%',
        width: '75%',
        textAlign: 'center',
        fontSize: '2.25vw',
        fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    }
});

const RightCointHud = ({ boardSize, src = `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CoinHud-01.png` }) => {
    const classes = useStyles();
    const totalCoins = useSelector((state) => state.player.points);
    const boxSize = {
      width: (boardSize.width / 7) || 0,
      height: (boardSize.height / 10) || 0,
    };
    return (
        <div className={classes.coinHudContainer} style={{...boxSize}}>
            <span className={classes.text}>
                {totalCoins}
            </span>
            <img
                className={classes.coinHud}
                src={src}
                alt='coin hud'
            />
        </div>
    );
}

export default RightCointHud;