import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import FlipContainer from './FlipContainer';
import CoinCheckbox from './CoinCheckbox';
import "@reach/checkbox/styles.css";

const useStyles = createUseStyles({
  stacked:{
    display:'flex',
    alignItems:'flex-end',
    flexDirection:'column',
    margin:'1vw 0',
  },
  unstacked:{
    display:'flex',
    alignItems:'center',
    margin:'1vw 0',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      margin: '0 0 1vw'
    }
  }
});

const getHelpfulHintsShowed = (state) => state.progress.helpfulHintsShowed;
const getHelpfulHints = createSelector([getHelpfulHintsShowed], (helpfulHintsShowed) => helpfulHintsShowed);

const HelpfulHintCheckbox = ({ allocatePoints, hintText, points, id, idx, bounce, hint, callback, stack, checkBoxAriaLabel }) => {
  const helpfulHints = useSelector(getHelpfulHints);
  const [isChecked, setIsChecked] = useState(() => id in helpfulHints);

  const classes = useStyles();

  const checkBox = () => {
    if (callback) {
      callback()
    }
    if (!isChecked) {
      setIsChecked(true);
      allocatePoints(points, id);
    }
  };

  return (
    <div role='group' className={`${stack ? classes.stacked : classes.unstacked}`}>
      {/* More props: iconSrc, overlayRootContainer, overlayButton, overlayButtonShadow */}
      <CoinCheckbox checkBoxAriaLabel={checkBoxAriaLabel} idx={idx} hintText={hintText} isChecked={isChecked} value={points} onChange={checkBox} bounce={bounce} />
      {/* More props: overlayContainerStyle */}
      <FlipContainer idx={idx} checked={isChecked} value={hint} stack={stack}/>
    </div>
  );
};

HelpfulHintCheckbox.propTypes = {
  allocatePoints: PropTypes.func.isRequired,
  points: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired, 
  bounce: PropTypes.bool,
  hint: PropTypes.string.isRequired,
  callback: PropTypes.func,
  stack: PropTypes.bool,
};

export default HelpfulHintCheckbox;
