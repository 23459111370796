import BackButton from '../inputs/BackButton';
import React, { } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import AnimatedTRDialog from './AnimatedTRDialog';
import ButtonCustom from '../inputs/ButtonCustom';

const useStyles = createUseStyles({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  icon: {
    maxWidth: '16vw',
    maxHeight: '10vw',
    marginTop: '-5vw'
  },
  title: {
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    textAlign: 'center',
    fontSize: '2.7vw',
    marginTop: '1.5vw',
    marginBottom: '1vw',
  },
  text: {
    padding: '0 5%',
    paddingBottom: '3vw',
    textAlign: 'center',
    '& p': {
      fontSize: '1.35vw',
      fontWeight: 600,
      lineHeight: 1.3,
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
        padding: '0 5%'
      }
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      width: '100%'
    },
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: '-3vw',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      right: '50%',
      transform: 'translateX(50%)'
    },
  }
})

const AnimatedDialogWithIcon = ({
  boardSize,
  titleStyles,
  iconStyles,
  sidesMultiplier = { width: 3, height: 5 },
  title,
  iconSrc = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/FunFact-02.png',
  isBloop,
  ...props }) => {
  const classes = useStyles(); // TODO: figure out how to apply props style object 

  let overlayButtonStyles = {
    width: boardSize ? boardSize?.width / 6 : '14vw',
    height: boardSize ? boardSize?.height / 10 : '5vw',
  }

  return (
    <AnimatedTRDialog
      ariaLabel={props.ariaLabel}
      isOpen={props.isOpen}
      close={props.close}
      showBackButton={props.showBackButton}
      dialogStyles={{
        width: boardSize ? (boardSize?.width / 10) * sidesMultiplier.width : '31vw',
        height: boardSize ? (boardSize?.height / 10) * sidesMultiplier.height : '26vw',
        padding: '0',
        borderRadius: '15px',
        minWidth: '31vw',
        minHeight: '26vw'
      }}
    >
      <div className={classes.root}>
        <img
          src={iconSrc === '' ? 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/FunFact-02.png' : iconSrc}
          alt=''
          role='presentation'
          className={classes.icon}
          style={{ ...iconStyles }}
        />
        {title 
          ? <div tabIndex={title ? 0 : -1} className={classes.title} style={{ ...titleStyles }} >
              {title}
            </div> 
          : null}        
        <div className={classes.text}>
          {props.children}
        </div>
        <div className={classes.buttonWrapper}>
          <ButtonCustom
            isBloop={isBloop}
            onClick={props.close}
            buttonText={props.buttonText}
            overlayRootStyles={overlayButtonStyles} 
          />
        </div>
      </div>
    </AnimatedTRDialog>
  )
};

AnimatedDialogWithIcon.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  iconSrc: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  style: PropTypes.object,
  showBackButton: PropTypes.bool
};

export default AnimatedDialogWithIcon;
