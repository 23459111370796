import React, { useContext } from 'react';
//import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import { SummaryContext } from '../../contexts/summaryContext';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '1vw',
    '@media screen and (max-width: 767px)': {
      marginBottom: 0
    }
  },
  textContainer: {
    color: '#ffffff',
    '& h1': {
      textTransform: 'uppercase',
      fontSize: '4vw',
      marginTop: 0,
      marginBottom: '1vw',
      '@media screen and (min-width: 1600px)': {
        fontSize: 96
      },
      '@media screen and (max-width: 767px)': {
        fontSize: 40,
        marginBottom: 10
      },
      '@media screen and (max-height: 380px)': {
        fontSize: 35,
        marginBottom: 10
      },
      '@media screen and (max-width: 600px)': {
        fontSize: 28
      },
      '@media screen and (min-aspect-ratio: 2 / 1)': {
        marginBottom: 10
      },
    },
    '& p': {
      fontFamily: '\'Graphik Semibold\', \'Arial Black\', sans-serif !important',
      fontSize: 28,
      '& span': {
        color: ({theme}) => theme.colors.acn.lightBlue,
        fontWeight: 'bold',
      },
      '@media screen and (max-width: 1366px)': {
        fontSize: 24
      },
      '@media screen and (max-width: 999px)': {
        fontSize: 20
      },
      '@media screen and (max-height: 380px)': {
        fontSize: 16,
        marginTop: 10,
        marginBottom: 10
      },
      '@media screen and (max-width: 767px)': {
        fontSize: 15,
        marginTop: 10
      },
      '@media screen and (min-aspect-ratio: 2 / 1)': {
        marginTop: 10
      },
    }
  }
});

const TopicSummaryText = (props) => {
  const theme = useTheme();
  const classes = useStyles({theme});
  const { machineState } = useContext(SummaryContext);

  return (
    <div className={classes.root}>
      {machineState.matches({ topicSummary: 'passwords' }) 
        ? <div className={classes.textContainer}>
            <h1 tabIndex={0}>Passwords</h1>
            <p tabIndex={0}>Keep your work passwords unique and only use your Accenture email for business purposes. Use multi-factor authentication whenever possible.</p>
          </div>
        :null}
      {machineState.matches({ topicSummary: 'outlook' }) 
        ? <div className={classes.textContainer}>
            <h1 tabIndex={0}>Outlook</h1>
          <p tabIndex={0}>Evaluate every email for the indicators of phishing and hit the Report Phishing button if anything you receive seems off. Do not send sensitive information without verifying that you are sending to the correct recipient.</p>
          </div>
        :null}
      {machineState.matches({ topicSummary: 'sharingTools' }) 
        ? <div className={classes.textContainer}>
            <h1 tabIndex={0}>Sharing Tools</h1>
          <p tabIndex={0}>Get familiar with the Sensitivity Toolbar and make it a habit to assign a data classification every time you handle sensitive data. Share information via SharePoint, MS Teams (Private Channel), and OneDrive, based on the data classification and purpose.</p>
          </div>
        :null}
      {machineState.matches({ topicSummary: 'workstationHygiene' }) 
        ? <div className={classes.textContainer}>
            <h1 tabIndex={0}>Workstation Hygiene</h1>
            <p tabIndex={0}>Never put off updates and patches. Restart your machine at least once a week to stay up to date and secure.</p>
          </div>
        :null}
    </div>
  );
};

TopicSummaryText.propTypes = {};

export default TopicSummaryText;
