import { Machine } from 'xstate';
import { setCharacter } from '../store/action-creators';
// const { assign } = actions;

export default function createIntroMachine(context) {

  // destructuring here to provide insight into the shape of context
  const {
    character,
    dispatch,
    scorm,
  } = context;

  return Machine({
    id: 'intro',
    initial: 'start',
    context: {
      character,
      dispatch,
      scorm,
    },
    states: {
      start: {
        after: {
          4500: {
            target: 'welcomeMessage'
          }
        },
        on: {
          CONTINUE: {
            target: 'welcomeMessage'
          }
        }
      },
      welcomeMessage: {
        on: {
          CONTINUE: {
            target: 'characterSelection'
          }
        }
      },
      characterSelection: {
        on: {
          SELECT: {
            target: 'final',
            actions: ['dispatchCharacter']
          }
        }
      },
      // may need additional intermediary step
      final: {
        type: 'final'
      }
    }
  },
  {
    actions: {
      dispatchCharacter: (_ctx, evt) => {
        dispatch(setCharacter(evt.character))
      }
    }
  });

}
