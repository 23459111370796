import React, { } from 'react';
import { createUseStyles } from 'react-jss';
import { hudAvatarSourceMap } from '../../data/character'
import { useSelector } from 'react-redux';

const useStyles = createUseStyles({
  avatarContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  avatar: {
    width: '100%',

  },
  '@media (min-width: 663px) and (max-width: 1024px)': {
    avatar: {
      widthMax: '120px',
    }
  }
});

const LeftSideAvatar = ({boardSize}) => {
  const classes = useStyles();
  const character = useSelector(state => state.player.character);
  const boxSize = {
    width: (boardSize.width / 7) || 0,
    height: (boardSize.height / 10) || 0,
  };
  
  return (
    <div className={classes.avatarContainer} style={{ ...boxSize }} >
      <img
        className={classes.avatar}
        src={hudAvatarSourceMap[character || 'JULIA']?.src || ''}
        alt=''
      />
    </div>
  );
}

export default LeftSideAvatar;