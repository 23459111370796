import ButtonCustom from './ButtonCustom';
import React, { useState } from 'react';
import {
  resetPlayer,
  resetProgress,
  resetQuestions,
  setReplayMode
} from '../../store/action-creators';
//import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import Portal from '@reach/portal';
import TRDialog from '../dialogs/TRDialog';

const useStyles = createUseStyles({
  replayButton: {
    position: 'fixed',
    bottom: 0,
    right: 160,
    zIndex: 10,
    transform: 'translate(0, 0)',
    fontSize: 15,
    color: '#ffffff',
    backgroundColor: '#7500c0',
    padding: 15,
    border: 'none',
    boxShadow: '3px 5px 0 2px #460073',
    cursor: 'pointer',
    transition: 'transform 200ms, box-shadow 200ms',
    transformOrigin: 'center',
    '&:hover': {
      transform: 'scale(1.04)',
      boxShadow: '0 0 0 0 #460073'
    },
    '&[disabled]': {
      opacity: 0.2,
      '&:hover': {
        transform: 'none',
        boxShadow: '3px 5px 0 2px #460073',
        cursor: 'default'
      }
    },
    '@media screen and (max-width: 767px)': {
      right: 'auto',
      left: 10,
      fontSize: 14,
      padding: 10
    }
  },
  dialogHeader: {
    marginTop: 0
  },
  buttonRow: {
    display: 'flex',
    marginTop: 30,
    marginBottom: 10
  }
});

const ReplayButton = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const replayCourse = () => {
    dispatch(setReplayMode(true));
    dispatch(resetProgress());
    dispatch(resetQuestions());
    dispatch(resetPlayer());
  };

  const closeDialog = (shouldReplay = false) => {
    setDialogOpen(false);
    if (shouldReplay) replayCourse();
  };

  return (
    <>
      <Portal>
        <button 
          className={classes.replayButton} 
          disabled={dialogOpen}
          onClick={() => setDialogOpen(true)}
        >
          Replay Course
        </button>
        <div></div>
      </Portal>
      <TRDialog 
        ariaLabelledBy='replay-header'
        isOpen={dialogOpen}
        close={() => closeDialog(false)}
        onDismiss={() => closeDialog(false)}
      >
        <h2 id='replay-header' className={classes.dialogHeader}>Replay Game?</h2>
        <div>
          <p>You can try the game again, but your game data will not be saved for this attempt. Select "OK" to continue.</p>
        </div>
        <div className={classes.buttonRow}> 
          <ButtonCustom buttonText='OK' onClick={() => closeDialog(true)}/>
          <ButtonCustom buttonText='Cancel' onClick={() => closeDialog(false)}/>
        </div>
      </TRDialog>
    </>
  );
};

ReplayButton.propTypes = {};

export default ReplayButton;
