import React from 'react';
import { PropTypes } from 'prop-types';
// import { useSelector, useDispatch } from 'react-redux';
// import {
//   increasePoints,
//   setHelpfulHintsShowed,
//   setLastPointTotal
// } from '../../../store/action-creators';
import { createUseStyles } from 'react-jss';
// import CoinCheckbox from '../../inputs/CoinCheckbox';
// import useScorm from '../../../utils/hooks/useScorm';
// import { topicFourApps } from '../../../data/hints';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    position: 'relative'
  },
  browser: {
    width: '100%',
    position: 'absolute',
    top: '3%',
    left: 0
  },
  downloaders: {
    width: '70%',
    height: '10vw',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: '14vw',
    '@media screen and (max-width: 999px)': {
      height: '9.5vw',
    }
  },
  downloadCard: {
    height: '100%',
    width: '19%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  checkboxContainer: {
    width: '2.5vw',
    height: '2.5vw',
    fontSize: '0.9vw',
    marginLeft: 'auto',
    marginRight: '0.75vw',
    '&:hover:not(.selected) img': {
      transform: 'scale(1.05)',
      left: '-1.3vw'
    },
    transform: 'translateY(100%)',
    '@media screen and (max-width: 999px)': {
      width: '3vw',
      height: '3vw',
      fontSize: '1.1vw',
      '&:hover:not(.selected) img': {
        transform: 'scale(1.05)',
        left: '-1.5vw'
      }
    },
    '@media screen and (max-width: 767px)': {
      width: '3.5vw',
      height: '3.5vw',
      fontSize: '1.5vw',
      '&:hover:not(.selected) img': {
        transform: 'scale(1.05)',
        left: '-1.5vw'
      }
    }
  },
  checkboxCheck: {
    height: '1vw',
    width: '0.5vw',
    borderBottom: '3px solid #ffffff',
    borderRight: '3px solid #ffffff',
    marginLeft: -6,
  },
  checkboxCoin: {
    top: '0.25vw',
    left: '-1vw',
    width: '1.8vw',
    '@media screen and (max-width: 999px)': {
      left: '-1.5vw',
      width: '2.5vw',
    },
    '@media screen and (max-width: 767px)': {
      //left: '-1.8vw',
      width: '3vw',
    }
  }
});


const BrowserContainer = ({ disableButton, audioClickHandlerT4 }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const helpfulHintsShowed = useSelector((state) => state.progress.helpfulHintsShowed);
  // const pointTotal = useSelector((state) => state.player.points);
  // const { commitAndSaveProgress } = useScorm();

  // const updateCheckboxes = (evt, index, id) => {
  //   if (audioClickHandlerT4) {
  //     audioClickHandlerT4();
  //   }
  //   if (!disableButton) return;
  //   dispatch(increasePoints(5));
  //   dispatch(setLastPointTotal(pointTotal + 5))
  //   dispatch(setHelpfulHintsShowed(id))
  //   commitAndSaveProgress();
  // };

  return (
    <div className={classes.root}>
      <img src={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Browser-02.png'} alt='Browser' className={classes.browser} />
      <div className={classes.downloaders}>
        {/* {
          topicFourApps.map((app, idx) => {
            return (
              <div key={idx} className={classes.downloadCard} aria-label={`Select App#${idx + 1} from the company portal.`}>
                <CoinCheckbox
                  idx={idx}
                  isChecked={app.id in helpfulHintsShowed}
                  value={5}
                  bounce={false}
                  classes={{ 
                    container: classes.checkboxContainer, 
                    check: classes.checkboxCheck,
                    coin: classes.checkboxCoin 
                  }}
                  onChange={(evt) => updateCheckboxes(evt, idx, app.id)}
                />
              </div>)
          })
        } */}
      </div>
    </div>
  )
}

BrowserContainer.propTypes = {
  disableButton: PropTypes.bool
};

export default BrowserContainer;
