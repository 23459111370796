import React, { useContext, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { t03 } from '../../../data/questions';
import { TopicThreeContext } from '../../../contexts/topicThreeContext';
import { YES, NO } from '../../life-choices/constants';
import QuestionDialog from '../../dialogs/QuestionDialog';
import QuestionOutcomeDialog from '../../dialogs/QuestionOutcomeDialog';
import FinishedTopicDialog from '../../dialogs/FinishedTopicDialog';
import CharacterOverlay from '../../overlays/CharacterOverlay';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  }
});

const questionOne = t03.q1;
const questionTwo = t03.q2;
const questionThree = t03.q3;
const questionFour = t03.q4;

const travelForWorkBackground = {
  [YES]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Plane-01.jpg',
  [NO]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Train-01.jpg'
};

const TopicThreeQuestionView = ({  }) => {
  const classes = useStyles();
  const { machineState, send } = useContext(TopicThreeContext);
  const travelForWork = useSelector((state) => state.player.travel)

  const close = useCallback(() => {
    send('CONTINUE');
  }, [send]);

  return (
    <div className={classes.root}
      style={{
        backgroundImage: `url(${travelForWorkBackground[travelForWork || NO]})`
      }}
    >
      <QuestionDialog
        send={send}
        isOpen={machineState.matches('questionOne.awaitingInput')}
        ariaLabel={'question one of topic three'}
        topic='t03'
        q={questionOne.id}
        prompt={questionOne.prompt}
        objective={questionOne.objective}
        options={questionOne.options}
        ans={questionOne.ans}
        points={questionOne.points}
        questionType={questionOne.type}
      />
      {machineState.matches('questionOne.outcome') &&
        <QuestionOutcomeDialog
          isOpen={machineState.matches('questionOne.outcome')}
          close={close}
          topic='t03'
          q={questionOne.id}
          points={questionOne.points}
          feedbackCorrect={questionOne.feedbackCorrect}
          feedbackIncorrect={questionOne.feedbackIncorrect}
          ans={questionOne.ans}
        />
      }
      <QuestionDialog
        send={send}
        isOpen={machineState.matches('questionTwo.awaitingInput')}
        ariaLabel={'question two of topic three'}
        topic='t03'
        q={questionTwo.id}
        prompt={questionTwo.prompt}
        objective={questionTwo.objective}
        options={questionTwo.options}
        ans={questionTwo.ans}
        points={questionTwo.points}
        questionType={questionTwo.type}
      />
      {machineState.matches('questionTwo.outcome') &&
        <QuestionOutcomeDialog
          isOpen={machineState.matches('questionTwo.outcome')}
          close={close}
          topic='t03'
          q={questionTwo.id}
          points={questionTwo.points}
          feedbackCorrect={questionTwo.feedbackCorrect}
          feedbackIncorrect={questionTwo.feedbackIncorrect}
          ans={questionTwo.ans}
        />
      }
      <QuestionDialog
        send={send}
        isOpen={machineState.matches('questionThree.awaitingInput')}
        ariaLabel={'question three of topic three'}
        topic='t03'
        q={questionThree.id}
        prompt={questionThree.prompt}
        objective={questionThree.objective}
        options={questionThree.options}
        ans={questionThree.ans}
        points={questionThree.points}
        questionType={questionThree.type}
      />
      {machineState.matches('questionThree.outcome') &&
        <QuestionOutcomeDialog
          isOpen={machineState.matches('questionThree.outcome')}
          close={close}
          topic='t03'
          q={questionThree.id}
          points={questionThree.points}
          feedbackCorrect={questionThree.feedbackCorrect}
          feedbackIncorrect={questionThree.feedbackIncorrect}
          ans={questionThree.ans}
        />
      }
      <QuestionDialog
        send={send}
        isOpen={machineState.matches('questionFour.awaitingInput')}
        ariaLabel={'question fourth of topic three'}
        topic='t03'
        q={questionFour.id}
        prompt={questionFour.prompt}
        objective={questionFour.objective}
        options={questionFour.options}
        ans={questionFour.ans}
        points={questionFour.points}
        questionType={questionFour.type}
      />
      {machineState.matches('questionFour.outcome') &&
        <QuestionOutcomeDialog
          isOpen={machineState.matches('questionFour.outcome')}
          close={close}
          topic='t03'
          q={questionFour.id}
          points={questionFour.points}
          feedbackCorrect={questionFour.feedbackCorrect}
          feedbackIncorrect={questionFour.feedbackIncorrect}
          ans={questionFour.ans}
        />
      }
      <FinishedTopicDialog
        isOpen={machineState.matches('finished')}
        ariaLabel={'That\'s it for questions on sharing information tools, let\'s head back to the gameboard...'}
        text={"All done! Let's head back to the gameboard to check your status."}
        buttonText='Play on!'
        close={close}
      />
      <CharacterOverlay />
    </div>
  );
}

export default TopicThreeQuestionView;
