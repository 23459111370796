import BackButton from '../components/inputs/BackButton';
import React, { useContext, useMemo, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPath } from '../store/action-creators';
import { TopicTwoContextProvider, TopicTwoContext } from '../contexts/topicTwoContext';
import { createUseStyles } from 'react-jss';
import TopicTwoInitialView from '../components/topics/topic-2/TopicTwoInitialView';
import TopicTwoQuestionView from '../components/topics/topic-2/TopicTwoQuestionView';
import ExitCourseButton from '../components/inputs/ExitCourseButton';
import { audioClickMap } from '../data/audioMap';
import { sfxPlayHandler } from '../utils/helpers';

const useStyles = createUseStyles({
  viewContainer: {
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto', // necessary for InnerScreenView component to scroll on very wide screens
    backgroundColor: '#ddffff'
  }
})

const TopicOneContents = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { machineState } = useContext(TopicTwoContext);
  const audioClickRef = useRef();
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  const showTopicTwoQuestionView = useMemo(() => {
    return machineState.matches('question') || machineState.matches('finished')
  }, [machineState])

  const showTopicTwoInitialView = useMemo(() => {
    return !showTopicTwoQuestionView;
  }, [showTopicTwoQuestionView]);

  useEffect(() => {
    if (machineState.matches('final')) {
      // history.push('/board');
      dispatch(setCurrentPath('/board'));
    }
  }, [machineState, dispatch]);

  let audioClickHandlerT2 = () => {
    //for IE_11 has to be in if expression
    try {
      if (soundsEffects) {
        audioClickRef.current.pause();
        sfxPlayHandler(audioClickRef);
      }
    } catch { }
  };

  return (
    <main>
      <section className={classes.viewContainer}>
        <audio id='musict2' ref={audioClickRef} src={audioClickMap[1]} type='audio' />
        {showTopicTwoInitialView && <TopicTwoInitialView audioClickHandlerT2={audioClickHandlerT2} />}
        {showTopicTwoQuestionView && <TopicTwoQuestionView audioClickHandlerT2={audioClickHandlerT2} />}
      </section>
      <BackButton hide={machineState.matches('start') || machineState.matches('initialOutlookScreen')}/>
    </main>
  );
};

const TopicTwo = (props) => {
  return (
    <TopicTwoContextProvider>
      <TopicOneContents />
      <ExitCourseButton />
    </TopicTwoContextProvider>
  )
}

TopicTwo.propTypes = {
};

export default TopicTwo;
