import * as Stub from './APIStub.js';
import * as SCORMAPI from './APIWrapper.js';

const API = process.env.REACT_APP_SCORM === true ? SCORMAPI : Stub;

export const {LMSInitialize,
    LMSFinish,
    LMSGetValue,
    LMSSetValue,
    LMSCommit,
    LMSGetLastError,
    LMSGetErrorString,
    LMSGetDiagnostic,
    LMSIsInitialized,
    ErrorHandler,
    getAPIHandle,
    findAPI,
    getAPI} = API;
