import { combineReducers } from 'redux';
import initialState from './state';
import {
  SET_SPIN,
  SET_STEP,
  SET_CHARACTER_MOVING,
  SET_SPIN_AND_STEP,
  SET_SHOWED_PROFESSOR_INSTRUCTIONS,
  SET_SHOWED_FUN_FACT,
  SET_LAST_FUN_FACT_SHOWED,
  SET_HELPFUL_HINTS_SHOWED,
  SET_SPLASH,
  SET_CURRENT_PATH,
  SET_REPLAY_MODE,
  SET_SCORE,
  SET_LAST_STEP,
  MARK_TOPIC_COMPLETED,
  SET_TOPIC_POINT_TOTAL,
  SET_CHARACTER,
  SET_LIFE_CHOICE,
  INC_POINTS,
  DEC_POINTS,
  SET_LAST_POINT_TOTAL,
  TOGGLE_SOUNDS_EFFECTS,
  TOGGLE_MUSIC,
  RESET_PROGRESS,
  RESET_QUESTIONS,
  RESET_PLAYER, 
  SET_DATE,
  SET_MODAL_OPEN
} from './action-types';

function setSpinAndStep(state, { spin }) {
  const step = state.currentStep + spin > 45 ? 0 : state.currentStep + spin;
  return {
    ...state,
    lastStep: state.currentStep,
    currentStep: step,
    lastSpin: state.currentSpin,
    currentSpin: spin
  };
}

function setScore(state, { questionSet, question, response, score }) {
  let newQuestionSet = { ...state[questionSet] }; // shallow copy
  const questionIdx = newQuestionSet.qs.findIndex((qu) => qu.name === question);
  const newQuestion = { ...newQuestionSet.qs[questionIdx], score, res: response };
  newQuestionSet.qs = [
    ...newQuestionSet.qs.slice(0, questionIdx),
    newQuestion,
    ...newQuestionSet.qs.slice(questionIdx + 1)
  ];

  return { ...state, [questionSet]: newQuestionSet };
}

function setLastStep(state, { questionSet, step }) {
  const newQuestionSet = { ...state[questionSet], step };
  return { ...state, [questionSet]: newQuestionSet };
}

function markTopicCompleted(state, { questionSet }) {
  const newQuestionSet = { ...state[questionSet], completed: true };
  return { ...state, [questionSet]: newQuestionSet };
}

function setTopicPointTotal(state, { questionSet }) {
  return { ...state, pointsByTopic: { ...state.pointsByTopic, [questionSet]: state.points } };
}

const progressReducer = (state = initialState.progress, action) => {
  switch (action.type) {
    case SET_SPIN:
      return {
        ...state,
        lastSpin: state.currentSpin,
        currentSpin: action.payload.spin
      };
    case SET_STEP:
      return {
        ...state,
        lastStep: state.currentStep,
        currentStep: action.payload.step
      };
    case SET_SPIN_AND_STEP:
      return setSpinAndStep(state, action.payload);
    case SET_CHARACTER_MOVING:
      return { ...state, characterMoving: action.payload.isMoving };
    case SET_SPLASH:
      return { ...state, splash: action.payload.splash };
    case SET_CURRENT_PATH:
      return { ...state, currentPath: action.payload.currentPath }
    case SET_SHOWED_PROFESSOR_INSTRUCTIONS:
      return action.payload.ordinal === 'first'
        ? { ...state, showedProfessorFirstInstructions: true }
        : { ...state, showedProfessorSecondInstructions: true }
    case SET_SHOWED_FUN_FACT:
      return { ...state, showedFunFact: action.payload.showedFunFact };
    case SET_LAST_FUN_FACT_SHOWED:
      return { ...state, lastFunFactShowed: action.payload.lastFunFactShowed };
    case SET_HELPFUL_HINTS_SHOWED:
      return { 
        ...state,
        helpfulHintsShowed: { 
          ...state.helpfulHintsShowed, 
          [action.payload.helpfulHintsShowed]: true
        }
      };
    case RESET_PROGRESS:
      return { ...initialState.progress, dateOfCompletion: state.dateOfCompletion };
    case SET_DATE:
      return {...state, dateOfCompletion: action.payload.date};
    default:
      return state;
  }
};

const questionsReducer = (state = initialState.questions, action) => {
  switch (action.type) {
    case SET_SCORE:
      return setScore(state, action.payload);
    case SET_LAST_STEP:
      return setLastStep(state, action.payload);
    case MARK_TOPIC_COMPLETED:
      return markTopicCompleted(state, action.payload);
    case RESET_QUESTIONS:
      return initialState.questions;
    default:
      return state;
  }
};

const playerReducer = (state = initialState.player, action) => {
  switch (action.type) {
    case SET_LIFE_CHOICE:
      return { ...state, [action.payload.field]: action.payload.value };
    case INC_POINTS:
      return { ...state, points: state.points + action.payload.points }
    case DEC_POINTS:
      return { ...state, points: state.points >= 50 ? state.points - action.payload.points : 0 };
    case SET_LAST_POINT_TOTAL:
      return { ...state, lastPointTotal: action.payload.lastPointTotal };
    case SET_CHARACTER:
      return { ...state, character: action.payload.character };
    case SET_TOPIC_POINT_TOTAL:
      return setTopicPointTotal(state, action.payload)
    case RESET_PLAYER:
      return initialState.player;
    default:
      return state;
  }
};

const settingsReducer = (state = initialState.settings, action) => {
  switch (action.type) {
    case TOGGLE_SOUNDS_EFFECTS:
      return { ...state, soundsEffects: action.payload.soundsEffects  };
    case TOGGLE_MUSIC:
      return { ...state, music: action.payload.music  };
    case SET_REPLAY_MODE:
      return { ...state, replayMode: action.payload.replayMode };
    case SET_MODAL_OPEN: 
      return { ...state, modalOpen: action.payload.modalOpen }
    default: 
      return state;
  }
};

export const rootReducer = combineReducers({
  progress: progressReducer,
  questions: questionsReducer,
  player: playerReducer,
  settings: settingsReducer
});
