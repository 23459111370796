import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import initialState from './state';
import { rootReducer } from './reducers';
import * as API from '../scorm-api/API';

const REACT_APP_SCORM = process.env.REACT_APP_SCORM;
const REACT_APP_PERSIST_STATE = process.env.REACT_APP_PERSIST_STATE;

export function initializeStore (preloadedState = initialState) {
  const suspendDataString = REACT_APP_SCORM 
    ? API.LMSGetValue('cmi.suspend_data') 
    : window.sessionStorage.getItem('suspend_data');

  if (suspendDataString && REACT_APP_PERSIST_STATE) {
    let savedState = JSON.parse(suspendDataString);
    // restore contexts
    return createStore(
      rootReducer,
      savedState,
      composeWithDevTools(applyMiddleware())
    );
  }

  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware())
  );
}
