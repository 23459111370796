import React from 'react';
//import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import OrangeHeader from '../typography/OrangeHeader';

const useStyles = createUseStyles({
  root: {
    width: '40%',
    minWidth: 350,
    textAlign: 'center'
  },
})

const SummaryTitle = (props) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <div>
        <OrangeHeader 
          headerText='Summary' 
          rootStyles={{ position: 'relative', width: '100%' }} 
          fontSize={{ fontSize: 28 }} 
        />
      </div>
    </section>
  );
};

SummaryTitle.propTypes = {};

export default SummaryTitle;
