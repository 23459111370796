import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import AnimatedTRDialog from './AnimatedTRDialog';
import PointsIncrementor from '../dialogs/PointsIncrementor';
import { createUseStyles, useTheme } from 'react-jss';
import SkewedDialogBox from './SkewedDialogBox';
// import questions from '../../data/questions';
import ButtonCustom from '../inputs/ButtonCustom';
import { setLastPointTotal } from '../../store/action-creators';

const useStyles = createUseStyles({
  pointsContainer: {
    maxWidth: 150,
    position: 'absolute',
    top: '-2vw',
    right: '-2vw',
    width: '12vw'
  },
  contentContainer: {
    textAlign: 'center',
    marginBottom: '2vw',
    '& h2': {
      fontSize: '3.2vw',
      marginBottom: 0,
      marginTop: '2%',
      textTransform: 'uppercase'
    },
    '& p': {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: '1.35vw',
      lineHeight: 1.3,
    }
  },
  pointsAwardContainer: {
    width: '7vw',
    maxWidth: 350,
    minWidth: 220,
    margin: '0 auto',
  },
  pointsAward: {
    position: 'relative',
    width: '100%',
    height: 0,
    overflow: 'hidden',
    paddingTop: '39.3%',
    '& > div': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundSize: 'contain',
      backgroundImage: 'url(https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Coin_01.png)',
    },
  },
  pointsAwardText: {
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    right: '10%',
    transform: 'translateY(-50%)',
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    fontSize: 40,
    color: '#ffffff',
    '@media screen and (max-width: 991px)': {
      fontSize: 35
    },
    '@media screen and (max-width: 767px)': {
      fontSize: 30
    }
  },
  dialog: {
    width: '55vw',
    '@media screen and (max-height: 390px)': {
      width: '70vw'
    },
    '@media screen and (max-width: 767px)': {
      width: '70vw'
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '70vw'
    }
  },
  coinImg: {
    width: '6vw',
    maxWidth: '120px',
    opacity: .5,
    position: 'absolute',
    right: '50%',
    transform: 'translateX(50%)',
    zIndex: 1,
    animation: '0.25s coinsToCorner infinite',
    animationTimingFunction: 'ease-in-out',
    '&.hide': {
      display: 'none'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      animation: '0.25s coinsToCornerIE11 infinite',
      animationTimingFunction: 'ease-in-out',
    },
  }
});


const QuestionOutcomeDialog = ({ isBloop, isOpen, close, topic, q, points, wrongPoints, feedbackCorrect, feedbackIncorrect, ans, buttonText }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const realPointTotal = useSelector((state) => state.player.points);
  const lastPointTotal = useSelector((state) => state.player.lastPointTotal);
  const selectedTopic = useSelector((state) => state.questions[topic]);
  const correct = useMemo(() => {
    const question = selectedTopic?.qs.find((qst) => qst.name === q);
    return question && question.res === ans;
  }, [selectedTopic, q, ans]);
  const initialRef = useRef();

  const [isAllocatingPoints, setIsAllocatingPoints] = useState(false);
  const [pointTotal, setPointTotal] = useState(lastPointTotal);
  const [openFinished, setOpenFinished] = useState(false);

  useEffect(() => {
    if (openFinished) {
      setPointTotal(realPointTotal);
      lastPointTotal !== realPointTotal ? setIsAllocatingPoints(true) : setIsAllocatingPoints(false);
    }
  }, [openFinished, correct, realPointTotal, lastPointTotal]);

  const overlayRootButtonStyles = {
    width: '16vw',
    height: '4.4vw',
    marginBottom: '-7vw',
  };

  const finishIncrementing = () => {
    dispatch(setLastPointTotal(realPointTotal));
    setIsAllocatingPoints(false);
  }

  return (
    <AnimatedTRDialog
      isOpen={isOpen}
      ariaLabelledBy='outcomesHeader'
      initialFocusRef={initialRef}
      close={close}
      onMounted={() => setOpenFinished(true)}
      dialogStyles={{
        background: 'transparent',
        padding: 0,
      }}
      classes={{ dialog: classes.dialog }}
    >
      <SkewedDialogBox>
        <img id='coin' className={`${classes.coinImg} ${isAllocatingPoints && pointTotal > lastPointTotal ? '' : 'hide'}`} src='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Coin-03.png' alt='' />
        <div className={classes.contentContainer}>
          <div className={classes.pointsAwardContainer}>
            <div className={classes.pointsAward}>
              <div>
                <span className={classes.pointsAwardText}>{correct ? `+${points}` : `-${(points / 2)}`}</span>
              </div>
            </div>
          </div>
          <h2
            ref={initialRef}
            id='outcomesHeader'
            style={{ color: correct ? theme.colors.game.green : theme.colors.game.red }}
            tabIndex={0}
          >
            {correct ? feedbackCorrect.header : feedbackIncorrect.header}
          </h2>
          <p tabIndex={0}>{correct ? feedbackCorrect.text : feedbackIncorrect.text}</p>
        </div>
        <ButtonCustom isBloop={isBloop} buttonText={buttonText} overlayRootStyles={overlayRootButtonStyles} disabled={isAllocatingPoints} onClick={close}></ButtonCustom>
        <div className={classes.pointsContainer}>
          <PointsIncrementor
            isOpen={openFinished}
            isAllocatingPoints={isAllocatingPoints}
            pointTotal={pointTotal}
            realPointTotal={realPointTotal}
            onRest={finishIncrementing}
          />
        </div>
      </SkewedDialogBox>
    </AnimatedTRDialog>
  );
};

QuestionOutcomeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  topic: PropTypes.string.isRequired,
  q: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
  feedbackCorrect: PropTypes.shape({
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  feedbackIncorrect: PropTypes.shape({
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  ans: PropTypes.string.isRequired,
  buttonText: PropTypes.string
};

QuestionOutcomeDialog.defaultProps = {
  buttonText: 'Next!'
};

export default QuestionOutcomeDialog;
