import React, { useRef } from 'react';
//import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import narrator2 from '../../static/animation/narrator/Narrator2_Board.json';

const NarratorPointingOverlay = (props) => {
  const ref = useRef();
  return (
    <Lottie lottieRef={ref} animationData={narrator2} autoplay={true} />
  );
};

NarratorPointingOverlay.propTypes = {};

export default NarratorPointingOverlay;
