import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import QuestionDialog from '../../dialogs/QuestionDialog';
import QuestionOutcomeDialog from '../../dialogs/QuestionOutcomeDialog';
import { t04 } from '../../../data/questions';
import FinishedTopicDialog from '../../dialogs/FinishedTopicDialog';
import { TopicFourContext } from '../../../contexts/topicFourContext';

const questionOne = t04.q1;
const questionTwo = t04.q2;

const TopicFourFinishedView = ({ close }) => {

  const { machineState, send } = useContext(TopicFourContext);

  return (<>
    <QuestionDialog
      send={send}
      isOpen={machineState.matches('questionOne.awaitingInput')}
      ariaLabel={'first question of topic four'}
      topic='t04'
      q={questionOne.id}
      prompt={questionOne.prompt}
      objective={questionOne.objective}
      options={questionOne.options}
      ans={questionOne.ans}
      points={questionOne.points}
      questionType={questionOne.type}
    />
    {machineState.matches('questionOne.outcome') &&
      <QuestionOutcomeDialog
        isOpen={machineState.matches('questionOne.outcome')}
        close={close}
        topic='t04'
        q={questionOne.id}
        points={questionOne.points}
        feedbackCorrect={questionOne.feedbackCorrect}
        feedbackIncorrect={questionOne.feedbackIncorrect}
        ans={questionOne.ans}
      />
    }
    <QuestionDialog
      send={send}
      isOpen={machineState.matches('questionTwo.awaitingInput')}
      ariaLabel={'second question of topic four'}
      topic='t04'
      q={questionTwo.id}
      prompt={questionTwo.prompt}
      objective={questionTwo.objective}
      options={questionTwo.options}
      ans={questionTwo.ans}
      points={questionTwo.points}
      questionType={questionTwo.type}
      longText
      twoColumn
    />
    {machineState.matches('questionTwo.outcome') &&
      <QuestionOutcomeDialog
        isOpen={machineState.matches('questionTwo.outcome')}
        close={close}
        topic='t04'
        q={questionTwo.id}
        points={questionTwo.points}
        feedbackCorrect={questionTwo.feedbackCorrect}
        feedbackIncorrect={questionTwo.feedbackIncorrect}
        ans={questionTwo.ans}
      />
    }
    <FinishedTopicDialog
      isOpen={machineState.matches('finished')}
      ariaLabel={'That\'s it for questions on sharing information tools, let\'s head back to the gameboard...'}
      text={"All done! Let's head back to the gameboard."}
      buttonText='Play on!'
      close={close}
    />
  </>)
}

export default TopicFourFinishedView;
