import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { SummaryContext } from '../../contexts/summaryContext';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import PointsIncrementor from '../dialogs/PointsIncrementor';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    marginBottom: 30,
    '@media screen and (max-width: 767px)': {
      marginBottom: 15
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      marginBottom: 15
    },
    '@media screen and (max-height: 380px)': {
      marginBottom: 10
    }
  },
  pointsContainer: {
    position: 'relative',
    width: '14.5vw',
    maxWidth: 320,
    minWidth: 130,
    margin: '0 auto'
  }
})

const TopicSummaryPoints = (props) => {
  const classes = useStyles();
  const { machineState } = useContext(SummaryContext);
  const pointsByTopic = useSelector((state) => state.player.pointsByTopic);
  const [isAllocatingPoints, setIsAllocatingPoints] = useState(false);
  const points = useRef();

  const currentPoints = useMemo(() => {
    switch(true) {
      case machineState.matches({ topicSummary: 'start' }):
        return 0;
      case machineState.matches({ topicSummary: 'passwords' }):
        return pointsByTopic.t01;
      case machineState.matches({ topicSummary: 'outlook' }):
        return pointsByTopic.t02;
      case machineState.matches({ topicSummary: 'sharingTools' }):
        return pointsByTopic.t03;
      case machineState.matches({ topicSummary: 'workstationHygiene' }):
        return pointsByTopic.t04;
      default:
        return -1;
    }
  }, [pointsByTopic, machineState]);
  
  const possibleTotal = useMemo(() => {
    switch(true) {
      case machineState.matches({ topicSummary: 'start' }):
        return 245;
      case machineState.matches({ topicSummary: 'passwords' }):
        return 245;
      case machineState.matches({ topicSummary: 'outlook' }):
        return 355;
      case machineState.matches({ topicSummary: 'sharingTools' }):
        return 855;
      case machineState.matches({ topicSummary: 'workstationHygiene' }):
        return 1060;
      default:
        return 0;
    }
  }, [machineState]);

  useEffect(() => {
    setIsAllocatingPoints(true);
    if (!machineState.matches({ topicSummary: 'start' })) {
      points.current && points.current.focus();
    } 
  }, [currentPoints, machineState])
  
  return (
    <div className={classes.root}>
      <div className={classes.pointsContainer}>
      <PointsIncrementor
        ref={points}
        pointTotal={currentPoints}
        possibleTotal={possibleTotal}
        isOpen={true}
        isAllocatingPoints={machineState.matches({ topicSummary: 'passwords' }) ? false : isAllocatingPoints}
        onRest={() => setIsAllocatingPoints(false)}
      />
    </div>
  </div>
  );
};

TopicSummaryPoints.propTypes = {};

export default TopicSummaryPoints;
