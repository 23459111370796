import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Portal from '@reach/portal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { TopicOneContext } from '../../contexts/topicOneContext';
import { TopicTwoContext } from '../../contexts/topicTwoContext';
import { TopicThreeContext } from '../../contexts/topicThreeContext';
import { TopicFourContext } from '../../contexts/topicFourContext';

const useStyles = createUseStyles({
  backButton: {
    position: 'fixed',
    bottom: 0,
    right: 130,
    zIndex: 10,
    // transform: 'translate(-50%, 0)',
    fontSize: 15,
    color: '#ffffff',
    backgroundColor: '#7500c0',
    padding: 12,
    border: 'none',
    boxShadow: '3px 5px 0 2px #460073',
    cursor: 'pointer',
    transition: 'transform 200ms, box-shadow 200ms',
    transformOrigin: 'center',
    '&:hover': {
      transform: 'scale(1.04)',
      boxShadow: '0 0 0 0 #460073'
    },
    '@media screen and (max-width: 767px)': {
      fontSize: 14,
      padding: 10
    },
    '@media screen and (max-width: 999px) and (min-aspect-ratio: 2 / 1)': {
      bottom: 55,
      right: 0
    },
    '@media screen and (min-aspect-ratio: 2.15 / 1)': {
      bottom: 55,
      right: 0
    }
  },
  hidden: {
    visibility: 'hidden'
  }
});

const BackButton = ({ hide, modal }) => {
  const classes = useStyles();
  const modalOpen = useSelector((state) => state.settings.modalOpen);
  const location = useLocation();
  let currentContext = useMemo(() => {
    switch (location.pathname) {
      case '/topic-1': 
        return TopicOneContext;
      case '/topic-2': 
        return TopicTwoContext;
      case '/topic-3': 
        return TopicThreeContext;
      case '/topic-4': 
        return TopicFourContext;
      default: 
        return TopicOneContext;
    }
  }, [location.pathname]);

  const { send } = useContext(currentContext);
  
  const backHandler = useCallback((evt) => {
    evt.stopPropagation();
    if (typeof send === 'function') {
      send('BACK');
    }
  }, [send]);

  if (modal) {
    return (
      <>
        <button 
          aria-label='Review previous content'
          className={classes.backButton} 
          onClick={backHandler}
        >
          <FontAwesomeIcon icon={faArrowLeft}/>
        </button>
        <div></div>
      </>
    )
  }
  return (
    <Portal>
      <button 
        aria-label='Review previous content'
        className={`${classes.backButton} ${modalOpen || hide ? classes.hidden : ''}`} 
        onClick={backHandler}
      >
        <FontAwesomeIcon icon={faArrowLeft}/>
      </button>
      <div></div>
    </Portal>
  );
};

BackButton.propTypes = {
//  goBack: PropTypes.func.isRequired,
  modal: PropTypes.bool,
  hide: PropTypes.bool
};

export default BackButton;
