import NarratorPointingOverlay from '../overlays/NarratorPointingOverlay';
import React, { useContext, useEffect, useRef } from 'react';
//import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import ButtonCustom from '../inputs/ButtonCustom'
import { IntroContext } from '../../contexts/introContext';
import useInitialFocus from '../../utils/hooks/useInitialFocus';
import { useSelector } from 'react-redux';

const useStyles = createUseStyles({

  bg: {
    background: 'radial-gradient(circle, rgba(161,0,255,1) 30%, rgba(70,0,115,1) 80%)',
    height: '100vh',
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    fallbacks: {
      background: 'rgb(161,0,255)',
    },
    '& img': {
      position: 'absolute',
      maxHeight: '100%',
      width: '30%',
      bottom: 0,
      left: 0,
    }
  },
  welcomeWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    margin: 'auto',
    justifyContent: 'center',
    '& p': {
      fontSize: '1.35vw',
      fontWeight: 600,
      lineHeight: 1.5,
      '@media screen and (max-width: 750px) and (orientation:landscape)': {
        fontSize: 12
      }
    },
    '& p:first-child': {
      fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
      fontSize: '2.7vw',
      lineHeight: 1.2,
      marginBottom: 0
    }
  },
  narrator: {
    position: 'absolute',
    bottom: '-5vw',
    left: '-5vw',
    width: '40vw',
    height: '40vw',
    maxWidth: 840,
    maxHeight: 840,
    '@media screen and (min-aspect-ratio: 2/1)': {
      bottom: 'auto',
      top: '35%'
    }
  }
});

const WelcomeMessage = (props) => {
  const classes = useStyles();
  const { send } = useContext(IntroContext);
  const firstFocus = useRef();
  const isMusic = useSelector(state => state.settings.music);
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  useEffect(() => {
    setTimeout(() => {
      try {
        firstFocus.current.focus()
      } catch{}
    }, 400);
  }, [])

  return (
    <section className={classes.bg}>
      <label aria-live='off' style={{ color: 'transparent' }}
        aria-label={`Background music is ${isMusic ? 'Active' : 'Inactive'} button.`} tabIndex={-1} role='alert' type='button' htmlFor='pp'>
        <label tabIndex={-1} aria-label={`Sounds effects:  ${soundsEffects ? 'Active' : 'Inactive'} button`} type='button' role='presentation'></label>
      </label>
      <div id='pp' className={classes.welcomeWrapper}>
        <p ref={firstFocus} tabIndex={0}> Welcome to Accenture! As a new joiner, you are now part of an organization that makes information security a priority for everyone.</p>
        <p tabIndex={0}> I’m the host of Spin for Security and I will serve as your companion. I'll be taking you through a typical day at Accenture and showing you the best security practices that will help protect Accenture, our clients, and your personal data. </p>
        <ButtonCustom
          id='btn'
          buttonText='START' // mockup says 'PLAY!' in mockup but client script says to select 'start' to continue
          arialabeltext={'Start the game'}
          onClick={() => send('CONTINUE')}
        />
      </div>
      <div tabIndex={-1} className={classes.narrator}>
        <NarratorPointingOverlay />
      </div>
    </section>
  );
};

WelcomeMessage.propTypes = {

};

export default WelcomeMessage;
