import React, { useContext, useCallback, useRef } from 'react';
// import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { TopicFourContext } from '../../../contexts/topicFourContext';
import InnerScreenView from '../InnerScreenView';
import LoaderScreen from './LoaderScreen';
import AnimatedDialogWithIcon from '../../dialogs/AnimatedDialogWithIcon';
import ButtonCustom from '../../inputs/ButtonCustom';
import BrowserView from './BrowserView';
import useInitialFocus from '../../../utils/hooks/useInitialFocus';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  },
  textBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bolder',
    '& p': {
      fontSize: '1.9vw',
      fontWeight: 600,
      lineHeight: 1.3,
      color: 'white',
      marginTop: 0
    }
  },
  text: {
    padding: '0 3.9vw 0'
  }
});

const textTopicFour = [
  <>When using your Accenture devices, you are permitted limited personal use if you are in compliance with Accenture policies and it does not interfere with work or cause any security risks.</>,
  <>Sometimes accidents happen and we need help to fix them, that's what the Accenture Security Operations Center (ASOC) is for. ASOC can help if you have a lost or stolen device or if you accidentally sent the wrong information to the wrong person.</>,
  <>Now you're ready to answer the questions to complete this turn!</>
];

const TopicFourScreenView = ({ audioClickHandlerT4 }) => {
  const classes = useStyles();
  const { machineState, send } = useContext(TopicFourContext);
  const textFocus0 = useInitialFocus();
  const textFocus1 = useInitialFocus();
  const textFocus2 = useInitialFocus();
  const screenRef = useRef();

  const close = useCallback(() => {
    if (audioClickHandlerT4) {
      audioClickHandlerT4();
    }
    send('CONTINUE');
  }, [send,audioClickHandlerT4]);

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `${''}`
      }}
    >
      <InnerScreenView forwRef={screenRef} backgroundImage={'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Topic4-Computer.png'}>
        <LoaderScreen onClick={close} isOpen={machineState.matches('monitor.loadScreen')} />
        {/* ICON Dialogs 1, 2, 3 */}
        <AnimatedDialogWithIcon
          ariaLabel='FirstIconDialog'
          buttonText='GOT IT!'
          isOpen={machineState.matches('monitor.firstIconDialog')}
          close={close}
          boardSize={'55vw'}
          title={'Remember!'}
          iconSrc='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/GoBack_01.png'
          isBloop={false}
          showBackButton
        >
          <p tabIndex={0}>
            When using an Accenture workstation, do not modify, uninstall, disable, or try to circumvent Accenture-provided hardware or software. Reach out to Accenture support services if you have any issues with your Accenture devices.
          </p>
        </AnimatedDialogWithIcon>

        <AnimatedDialogWithIcon
          ariaLabel='Seconds Icon Dialog'
          buttonText='GOT IT!'
          isOpen={machineState.matches('monitor.secondIconDialog')}
          close={close}
          boardSize={'55vw'}
          iconSrc='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/AtEmail-01.png'
          isBloop={false}
          showBackButton
        >
          <p tabIndex={0}>
            When browsing the internet, do not use your Accenture email address for non-business websites or mailing lists. Likewise, do not use your client email to conduct internal Accenture business.
          </p>
        </AnimatedDialogWithIcon>

        <AnimatedDialogWithIcon
          ariaLabel='Third Icon Dialog'
          buttonText='GOT IT!'
          isOpen={machineState.matches('monitor.thirdIconDialog')}
          close={close}
          boardSize={'55vw'}
          title={'+1.202.728.0645'}
          iconSrc='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/PhoneAlert-01.png'
          isBloop={false}
          showBackButton
        >
          <p tabIndex={0}>
            Always call ASOC if you suspect you have been a victim of a security breach. ASOC is also who you should call for help with any physical security issue while you are traveling or in the office. Save the number in your phone if you haven't already. <br/><br/>The global number is +1.202.728.0645
          </p>
        </AnimatedDialogWithIcon>

        {/* Text 1,2,3 */}
        {
          (machineState.matches('monitor.firstText') ||
            machineState.matches('monitor.secondText') ||
            machineState.matches('monitor.thirdText')) &&
          <div className={classes.textBox}>
            <div style={{ width: '100%' }}>
              <div className={classes.text} >
                {machineState.matches('monitor.firstText') ? <p style={{ width: '100%' }} tabIndex={0} ref={textFocus0}>{textTopicFour[0]}</p> : ''}
                {machineState.matches('monitor.secondText') ? <p style={{ width: '100%' }} tabIndex={0} ref={textFocus1}>{textTopicFour[1]}</p> : ''}
                {machineState.matches('monitor.thirdText') ? <p style={{ width: '100%' }} tabIndex={0} ref={textFocus2}>{textTopicFour[2]}</p> : ''}
              </div>
              <ButtonCustom isBloop={false} onClick={close} buttonText={machineState.matches('monitor.firstText') ? 'Submit!' : machineState.matches('monitor.secondText') ? 'Got it!' : 'Next!'} />
            </div>
          </div>
        }

        {/* Browser */}
        <BrowserView
          onClick={close}
          isOpen={machineState.matches('monitor.browser')}
          audioClickHandlerT4={audioClickHandlerT4}
        />

      </InnerScreenView>
    </div>
  );
};

TopicFourScreenView.propTypes = {

};

export default TopicFourScreenView;
