export const JULIA = 'JULIA';
export const MICHAEL = 'MICHAEL';
export const ANTHONY  = 'ANTHONY';
export const SARAH = 'SARAH';

// full-size pictures-src for characters to be used in Topic's and other
export const charactersSourceMap = { 
  [JULIA]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Character-01.png`,
    name: 'Julia',
  },
  [MICHAEL]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Character-02.png`,
    name: 'Michael',
  },
  [ANTHONY]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Character-04.png`,
    name: 'Anthony',
  },
  [SARAH]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Character-03.png`,
    name: 'Sarah',
  },
};
// small pictures of character with peace of hud. To be used on LeftSideAvatar (Frame HUD)
export const hudAvatarSourceMap = { 
  [JULIA]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CharacterHud-02.png`,
    name: 'Julia',
  },
  [MICHAEL]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CharacterHud-03.png`,
    name: 'Michael',
  },
  [ANTHONY]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CharacterHud-05.png`,
    name: 'Anthony',
  },
  [SARAH]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CharacterHud-04.png`,
    name: 'Sarah',
  },
};


// circle avatars for outlook scenario
export const outlookAvatarSourceMap = { 
  [JULIA]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Avatar-01.png`,
    name: 'Julia',
  },
  [MICHAEL]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Avatar-04.png`,
    name: 'Michael',
  },
  [ANTHONY]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Avatar-02.png`,
    name: 'Anthony',
  },
  [SARAH]: {
    src: `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Avatar-03.png`,
    name: 'Sarah',
  },
};