import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import createTopicOneMachine from '../machines/topicOneMachine';
import useScorm from '../utils/hooks/useScorm';

/*---------------------------------*/

const TopicOneContext = React.createContext([{}, {}]);

/*---------------------------------*/

const TopicOneContextProvider = (props) => {
  const topicOneState = useSelector((state) => state.questions.t01);
  const dispatch = useDispatch();
  const scorm = useScorm(); 
  const [machineState, send] = useMachine(createTopicOneMachine({ ...topicOneState, dispatch, scorm }), { devTools: true });

  useEffect(() => {
    send('START');
  }, [send]);

  return (
    <TopicOneContext.Provider value={{ machineState, send }}>
      {props.children}
    </TopicOneContext.Provider>
  )
};

TopicOneContextProvider.propTypes = {
  
};

export { TopicOneContext, TopicOneContextProvider };

