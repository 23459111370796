import React, { useContext, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { TopicOneContext } from '../../../contexts/topicOneContext';
import { useSelector } from 'react-redux';
import InnerScreenView from '../InnerScreenView';
import HelpfulHints from '../../dialogs/HelpfulHints';
import LoginScreen from './LoginScreen';
import { helpfulHintsTopicOne } from '../../../data/hints';
import { OFFICE, HOME } from '../../life-choices/constants';

const choiceBackgroundMap = {
  [OFFICE]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/ComputerOffice-02.png',
  [HOME]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/ComputerHome-02.png'
};

const TopicOneScreenView = ({SFXOnClick}) => {
  const { machineState, send } = useContext(TopicOneContext);
  const officeOrHome = useSelector((state) => state.player.officeOrHome);

  const close = () => {
    SFXOnClick();
    send('CONTINUE');
  };

  return (
    <>
      <InnerScreenView backgroundImage={choiceBackgroundMap[officeOrHome] || choiceBackgroundMap[OFFICE]}>
        <LoginScreen SFXOnClick={SFXOnClick} />
      </InnerScreenView>
      <HelpfulHints
        callback={SFXOnClick}
        key='3rdHH'
        hints={[helpfulHintsTopicOne[0]]}
        buttonText='Next!'
        isOpen={machineState.matches('monitor.thirdHelpfulHints')}
        close={close}
        isBloop={false}
      />
      <HelpfulHints
        callback={SFXOnClick}
        key='4thHH'
        hints={[helpfulHintsTopicOne[1]]}
        buttonText='Next!'
        isOpen={machineState.matches('monitor.fourthHelpfulHints')}
        close={close}
        isBloop={false}
      />
    </>
  );
};

TopicOneScreenView.propTypes = {

};

export default TopicOneScreenView;
