import React, { useContext, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import LifeChoiceOutcome from '../../dialogs/LifeChoiceOutcome';
import HelpfulHints from '../../dialogs/HelpfulHints';
import { helpfulHintsTopicFour } from '../../../data/hints';
import { TopicFourContext } from '../../../contexts/topicFourContext';
import TopicFourFinishedView from './TopicFourFinishedView';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  }
});

const choiceBackgroundMap = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Topic4.png';

const TopicFourNonScreenView = (props) => {
  const classes = useStyles();
  const { machineState, send } = useContext(TopicFourContext);
  const accentureArea = useSelector((state) => state.player.accentureArea)

  const close = useCallback(() => {
    send('CONTINUE');
  }, [send]);

  return (
    <div 
      className={classes.root}
      style={{
        backgroundImage: `url(${choiceBackgroundMap})`
      }}
    >
      <LifeChoiceOutcome 
        choice={accentureArea || 'CONSULTING_AND_STRATEGY'}
        isOpen={machineState.matches('lifeChoice')} 
        close={close} 
      />
      <HelpfulHints 
        key='1stHH'
        hints={helpfulHintsTopicFour  }
        buttonText='Next!'
        isOpen={machineState.matches('firstHelpfulHints')}
        close={close}
        bounce={true}
      />
       {/* Question */}
       <TopicFourFinishedView close={close} send={send} />
    </div>
  );
};

TopicFourNonScreenView.propTypes = {

};

export default TopicFourNonScreenView;
