import BackButton from '../inputs/BackButton';
import SoundSelectionButton from '../inputs/SoundSelectionButton';
import ExitCourseButton from '../inputs/ExitCourseButton';
import React, { useEffect } from 'react';
import { setModalOpen } from '../../store/action-creators';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import '@reach/dialog/styles.css';

const TRDialog = ({
  children,
  isOpen,
  dismiss,
  close,
  ariaLabel,
  ariaLabelledBy,
  initialFocusRef,
  showClose,
  showBackButton,
  dialogStyles,
  overlayStyles,
  classes = {}
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setModalOpen(isOpen));
  }, [isOpen, dispatch])

  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={dismiss || close}
      initialFocusRef={initialFocusRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
        background: 'rgba(0,0,0,0.6)',
        zIndex: 5,
        ...overlayStyles
      }}
      className={classes.overlay || ''}
    >
      <>
        <DialogContent
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          style={{ position: 'relative', ...dialogStyles }}
          className={classes.dialog || ''}
        >
            {showClose &&
              <button className="close-button" onClick={close}>
                <VisuallyHidden>Close</VisuallyHidden>
                <span aria-hidden>×</span>
              </button>
            }
            {children}
        </DialogContent>
        <SoundSelectionButton modal />
        {showBackButton ? <BackButton modal /> : null}
        <ExitCourseButton modal />
      </>
    </DialogOverlay>
  );
};

TRDialog.propTypes = {
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  initialFocusRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  isOpen: PropTypes.bool.isRequired,
  dismiss: PropTypes.func,
  close: PropTypes.func.isRequired,
  showClose: PropTypes.bool,
  showBackButton: PropTypes.bool,
  dialogStyles: PropTypes.object,
  overlayStyles: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string)
};

export default TRDialog;
