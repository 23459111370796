import React, { useEffect } from 'react';
import './static/App.css';
import { ThemeProvider } from 'react-jss';
import theme from './data/theme';
import * as API from './scorm-api/API';
import { loadPage, unloadPage } from './scorm-api/AUFunctions.js';
import Routes from './routes/Routes';
import SoundSelectionButton from './components/inputs/SoundSelectionButton';
import AudioPlayer from './components/inputs/AudioPlayer';
import { questions } from './data/questions';
import { AudioContextProvider } from './contexts/audioContext';

const questionCount = Object.values(questions)
  .reduce((total, prevTopic) => total + Object.values(prevTopic).length, 0);

function App() {
  useEffect(() => {
    loadPage();
    API.LMSSetValue('cmi.core.score.min', 0);
    API.LMSSetValue('cmi.core.score.max', questionCount);

    let iFrameElement = window.frameElement;
    let querySelectedIframe = window.parent.document.querySelector('iframe');
    let querySelectedScormIframe = window.parent.document.getElementById('ScormContent')

    if (iFrameElement) {
      iFrameElement.focus()
    }
    else if (querySelectedIframe) {
      querySelectedIframe.focus();
    }
    else if (querySelectedScormIframe) {
      querySelectedScormIframe.focus();
    }

    return () => unloadPage();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AudioContextProvider>
        <div className='App'>
          <AudioPlayer />
          <Routes />
          <SoundSelectionButton />
        </div>
      </AudioContextProvider>
    </ThemeProvider>
  );
}

export default App;
