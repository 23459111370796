export const backgroundMusicSrc = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Walk-in-the-Park.mp3';
export const walkingAudioSrc = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Walking01.mp3';
// old coins
export const audioCoinsMap = [
  'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Coin01.mp3',
  'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Coin02.mp3',
  'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Coin03.mp3',
  'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Coin04.mp3',
  'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Coin05.mp3',
]
// clicks on buttons/chekboxes for screen views in T-1,2,3,4
export const audioClickMap = [
  'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Click01.mp3',
  'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Click02.mp3',
  'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Click03.mp3',
]
// hero selection slides
export const audioWoosh = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Transition Woosh04.mp3';
// points
export const audioDecreasing = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Point Decrease 04.mp3';
export const audioIncreasing = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Point Increase 04.mp3';
// button
export const audioBtnBloop = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Bloop02.mp3';

// Dialog pop=up (AnimatedDialog wrapper)
export const audioBoxSpring = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Dialogue Box Spring03.mp3';
// Question selected (QuestionDialog)
export const audioQuestionClick = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Question Bloop02.mp3';
//Summary celebrate
export const audioCelebration = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SFX/Celebration.mp3';

