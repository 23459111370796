export const SET_SPIN = 'SET_SPIN';
export const SET_STEP = 'SET_STEP';
export const SET_CHARACTER_MOVING = 'SET_CHARACTER_MOVING';
export const SET_SPIN_AND_STEP = 'SET_SPIN_AND_STEP';
export const SET_SHOWED_PROFESSOR_INSTRUCTIONS = 'SET_SHOWED_PROFESSOR_INSTRUCTIONS';
export const SET_SHOWED_FUN_FACT = 'SET_SHOWED_FUN_FACT';
export const SET_LAST_FUN_FACT_SHOWED = 'SET_LAST_FUN_FACT_SHOWED';
export const SET_HELPFUL_HINTS_SHOWED = 'SET_HELPFUL_HINTS_SHOWED';
export const SET_SPLASH = 'SET_SPLASH';
export const SET_CURRENT_PATH = 'SET_CURRENT_PATH';
export const SET_REPLAY_MODE = 'SET_REPLAY_MODE';
export const SET_SCORE = 'SET_SCORE';
export const SET_LAST_STEP = 'SET_LAST_STEP';
export const MARK_TOPIC_COMPLETED = 'MARK_TOPIC_COMPLETED';
export const SET_TOPIC_POINT_TOTAL = 'SET_TOPIC_POINT_TOTAL'
export const SET_CHARACTER = 'SET_CHARACTER';
export const SET_LIFE_CHOICE = 'SET_LIFE_CHOICE';
export const INC_POINTS = 'INC_POINTS';
export const DEC_POINTS = 'DEC_POINTS';
export const SET_LAST_POINT_TOTAL = 'SET_LAST_POINT_TOTAL';
export const TOGGLE_SOUNDS_EFFECTS = 'TOGGLE_SOUNDS_EFFECTS';
export const TOGGLE_MUSIC = 'TOGGLE_MUSIC';
export const RESET_PROGRESS = 'RESET_PROGRESS';
export const RESET_QUESTIONS = 'RESET_QUESTIONS';
export const RESET_PLAYER = 'RESET_PLAYER';
export const SET_DATE = 'SET_DATE';
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
