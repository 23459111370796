import {
  CONSULTING_AND_STRATEGY,
  INTERACTIVE,
  TECHNOLOGY,
  OPERATIONS,
  CORPORATE_FUNCTIONS,
  NO,
  YES,
  HOME,
  OFFICE
} from './constants';

// images source map for lifeChoice, BadgeClaimm, MiddleColelction and Summary
// text prop is using in the BadgeClaim dialog
export const lifeChoiceSrcMap = {
  [OFFICE]: {
    text: 'You earned the access badge!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/AccessBadge.png',
  },
  [HOME]: {
    text: 'You earned the house key!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/HouseKey.png',
  },
  [NO]: {
    text: 'You earned the train pass!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/TrainPass.png',
  },
  [YES]: {
    text: 'You earned the plane!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Plane.png',
  },
  [CONSULTING_AND_STRATEGY]: {
    text: 'You earned the Consulting & Strategy specialization!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Accenture-Consulting.png',
  },
  [INTERACTIVE]: {
    text: 'You earned the Interactive specialization!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Accenture-Interactive.png',
  },
  [TECHNOLOGY]: {
    text: 'You earned the Technology specialization!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Accenture-Technology.png',
  },
  [CORPORATE_FUNCTIONS]: {
    text: 'You earned the specialization in Corporate Functions!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Accenture-CorporateFunctions.png',
  },
  [OPERATIONS]: {
    text: 'You earned the specialization in Operations!',
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Accenture-Operations.png',
  },
};