import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import AnimatedTRDialog from './AnimatedTRDialog';
import PointsIncrementor from './PointsIncrementor';
import ButtonCheckbox from '../inputs/ButtonCheckbox';
import SkewedDialogBox from './SkewedDialogBox';
import ButtonCustom from '../inputs/ButtonCustom';
import { audioQuestionClick } from '../../data/audioMap';
import { sfxPlayHandler } from '../../utils/helpers';

const useStyles = createUseStyles({
  pointsContainer: {
    maxWidth: 150,
    position: 'absolute',
    top: '-2vw',
    right: '-2vw',
    width: '12vw'
  },
  optionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: '2vw',
  },
  option: {
    flex: '0 0 100%',
    padding: '0.75vw 0.75vw 0',
    textAlign: 'center',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    '&.split': {
      flex: '0 0 45%',
    },
    '&.split.odd:last-child': {
      flex: '0 0 60%',
    },
    '@media screen and (max-width: 991)': {
      flex: '1 0 100%'
    }
  },
  promptContainer: {
    marginBottom: '2%',
    marginTop: '2%',
  },
  prompt: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1.25vw',
    lineHeight: 1.3,
    padding: '0 5%',
    margin: 0,
    '& span': {
      display: 'inline-block',
      fontWeight: 400,
      marginTop: 4
    },
    '@media screen and (max-height: 390px) and (orientation:landscape)': {
      padding: '1% 0',
      fontSize: '1.3vw',
      '&:first-child': {
        paddingBottom: 0
      },
    },
    '&.red': {
      lineHeight: 1,
      color: 'red'
    }
  },
  charImg: {
    position: 'absolute',
    maxHeight: '40vw',
    left: '-5.5vw',
    bottom: '-3vw',
  },
  dialog: {
    width: '55vw',
    '@media screen and (max-height: 390px)': {
      width: '70vw'
    },
    '@media screen and (max-width: 767px)': {
      width: '70vw'
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '70vw'
    }
  },
});

const overlayRootButtonStyles = {
  width: '16vw',
  height: '4.4vw',
  marginBottom: '-7vw',
};
const disabledRootStyles = {
  width: '16vw',
  height: '4.4vw',
  opacity: '55%',
  marginBottom: '-7vw',
};
const disabledInnerStyles = {
  background: 'darkgrey'
};
const disabledOuterStyles = {
  background: 'black'
};
const disabledBorderStyles = {
  background: 'lightgrey'
};


const QuestionDialog = ({
  send,
  isOpen,
  ariaLabel,
  topic,
  q,
  objective,
  prompt,
  options,
  ans,
  points,
  questionType,
  longText,
  twoColumn
}) => {
  const classes = useStyles();
  const pointTotal = useSelector((state) => state.player.points);
  const soundsEffects = useSelector(state => state.settings.soundsEffects);
  const [selections, setSelections] = useState([]);
  const [isRightAnswerCount, setIsRightAnswerCount] = useState(true);
  const initialRef = useRef();
  const questionAudio = useRef();

  const updateChecked = (id, checked) => {
    const index = selections.indexOf(id);
    if (soundsEffects) {
      sfxPlayHandler(questionAudio);
    }
    if (checked && index < 0) {
      // if answer include multiple selections, add to state, otherwise replace existing selection
      return ans.length > 1 ? setSelections((state) => [...state, id]) : setSelections([id]);
    }
    if (!checked && index > -1) {
      return setSelections((state) => [...state.slice(0, index), ...state.slice(index + 1)]);
    }
  };

  const submitAnswers = () => {
    if (ans.length !== 1 && selections.length !== 2) {
      setIsRightAnswerCount(false)
      //For JAWS
      window.addEventListener('keypress', () => setIsRightAnswerCount(true))
    }
    else {
      setIsRightAnswerCount(true)
      window.removeEventListener('keypress', () => setIsRightAnswerCount(true))

      let stringResponse = selections.join(',');
      //because for topic3 q3 && t4 we need an order
      if (((topic === 't03') && (q === 'q3')) || (topic === 't04')) {
        stringResponse = stringResponse.split(",").sort().toString()
      }

      const correct = stringResponse === ans;

      const scormData = {
        q,
        objective,
        ans,
        interactionType: questionType,
        response: stringResponse,
        questionSet: topic,
      };
      send('ANSWER', { ...scormData, points, result: correct ? 'correct' : 'wrong' });
    }
  };
  return (
    <AnimatedTRDialog
      ariaLabel={ariaLabel}
      isOpen={isOpen}
      dismiss={() => null} close={submitAnswers}
      initialFocusRef={initialRef}
      showBackButton
      dialogStyles={{
        background: 'transparent',
        padding: 0,
      }}
      classes={{ dialog: classes.dialog }}
    >
      <SkewedDialogBox>
        <audio role='presentation' id='audioQuestion' src={audioQuestionClick} ref={questionAudio} type='audio' />
        <section>
          <div className={classes.promptContainer}>
            <p ref={initialRef} tabIndex={0} className={classes.prompt} dangerouslySetInnerHTML={{ __html: prompt }}></p>
            <p aria-live='assertive' className={`${classes.prompt} red`}>{!isRightAnswerCount ? 'You must select 2 options' : ''}</p>
          </div>
          <div className={classes.optionRow}>
            {options.map((opt, index) => (
              <div key={opt.id} className={`${classes.option} ${options.length >= 5 && options.length % 2 === 1 ? 'split odd' : (options.length >= 5 || twoColumn) ? 'split' : ''}`}>
                <ButtonCheckbox
                  id={opt.text + index}
                  checked={selections.indexOf(opt.id) > -1}
                  optionText={opt.text}
                  updateChecked={(checked) => updateChecked(opt.id, checked)}
                  longText={options.length >= 6 || longText}
                />
              </div>
            ))}
          </div>
        </section>
        <div>
          <ButtonCustom
            arialabeltext="Submit"
            buttonText='Submit!'
            disabled={selections.length < 1 ? true : false}
            overlayRootStyles={selections.length < 1 ? disabledRootStyles : overlayRootButtonStyles}
            disabledOuterStyles={selections.length < 1 ? disabledOuterStyles : {}}
            disabledInnerStyles={selections.length < 1 ? disabledInnerStyles : {}}
            disabledBorderStyles={selections.length < 1 ? disabledBorderStyles : {}}
            onClick={submitAnswers}>
          </ButtonCustom>
        </div>
        <div className={classes.pointsContainer}>
          <PointsIncrementor
            isOpen={isOpen}
            isAllocatingPoints={false}
            pointTotal={pointTotal}
            onRest={() => null}
          />
        </div>
      </SkewedDialogBox>
    </AnimatedTRDialog>
  );
};

QuestionDialog.propTypes = {
  send: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  topic: PropTypes.PropTypes.string.isRequired,
  q: PropTypes.string.isRequired,
  objective: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  ans: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  longText: PropTypes.bool,
  twoColumn: PropTypes.bool
};

export default QuestionDialog;
