import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import Lottie from 'lottie-react';
import ButtonCustom from '../../inputs/ButtonCustom';
import useInitialFocus from '../../../utils/hooks/useInitialFocus';
import progressBar from '../../../static/animation/misc/Progress_Bar.json';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  },
  textBox: {
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bolder',
    width: '80%',
    margin: 'auto',
    '& p': {
      fontSize: '1.9vw',
      fontWeight: 600,
      lineHeight: 1.3,
      color: 'white',
      marginTop: 0,
      marginBottom: 0
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      flex: 1
    }
  },
  text: {
    padding: '3.9vw 3.9vw 0'
  },
  loader:{
    maxHeight: '4vw',
    margin: '2vw 0'
  },
  progressBar: {
    width: '45vw',
    marginBottom: '4vw',
    marginTop: '2vw',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      height: '10vw'
    }
  }
});

const LoaderScreen = ({ buttonText = 'Next!', onClick, isOpen = true }) => {
  const classes = useStyles();
  const textFocus = useInitialFocus();
  const ref = useRef();
  const [isLoading, setLoad] = useState(true)

  const onComplete = () => {
    setLoad(false)
    ref.current.playSegments([125, 151], true);
  };

  const disabledInnerStyles = {
    background: 'darkgrey'
  };

  const disabledOuterStyles = {
    background: 'black'
  };

  const disabledBorderStyles = {
    background: 'lightgrey'
  };

  return (
    isOpen &&
    <div className={classes.textBox}>
      <p ref={textFocus} tabIndex={0} style={{width: '100%'}}>
        Restart your workstation at least once a week to ensure all software updates are fully installed.
        Respond to compliance notifications and e-mails in a timely manner.
      </p>
      <div className={classes.progressBar}>
        <Lottie lottieRef={ref} animationData={progressBar} loop={0} onComplete={onComplete} style={{height: '100%'}} />
      </div>

      <ButtonCustom isBloop={false} disabled={isLoading} buttonText={buttonText} onClick={onClick}
        disabledOuterStyles={isLoading ? disabledOuterStyles : {}}
        disabledInnerStyles={isLoading ? disabledInnerStyles : {}}
        disabledBorderStyles={isLoading ? disabledBorderStyles : {}}
      />
    </div>
  )
}

export default LoaderScreen;
