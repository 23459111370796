import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AnimatedDialogWithIcon from './AnimatedDialogWithIcon';
import { BoardContext } from '../../contexts/boardContext';
import {lifeChoiceSrcMap} from '../life-choices/sourcemap'

const ClaimBadge = ({ boardSize }) => {
  const { machineState, send } = useContext(BoardContext);
  const officeOrHome = useSelector(state => state.player.officeOrHome)
  const yesOrNo = useSelector(state => state.player.travel)
  const accentureArea = useSelector(state => state.player.accentureArea)

  const budgeClaimed = () => {
    send('CLAIM');
  };

  const titleStyles = {
    fontSize: '2vw'
  }

  const iconStyles = {
    top: '18%',
    height: '43%',
  }
  //Modal window size
  const sidesMultiplier = {
    width: 3,
    height: 4,
  }

  return (
    <AnimatedDialogWithIcon
      ariaLabel='Fun fact'
      iconSrc={
        lifeChoiceSrcMap[accentureArea !== '' ? accentureArea
          : yesOrNo !== '' ? yesOrNo
            : officeOrHome !== '' ? officeOrHome
              : 'OFFICE']?.src}
      buttonText='CLAIM!'
      isOpen={machineState.matches('claimBadge')}
      //isOpen={false}
      close={budgeClaimed}
      boardSize={boardSize}
      title={'CONGRATULATIONS'}
      titleStyles={titleStyles}
      iconStyles={iconStyles}
      sidesMultiplier={sidesMultiplier}
    >
      <p tabIndex={0} style={{ fontWeight: 800 }} >{lifeChoiceSrcMap[accentureArea !== '' ? accentureArea
          : yesOrNo !== '' ? yesOrNo
            : officeOrHome !== '' ? officeOrHome
              : 'OFFICE']?.text || 'You earned badge'}</p>
    </AnimatedDialogWithIcon>
  );
};

ClaimBadge.propTypes = {

};

export default ClaimBadge;
