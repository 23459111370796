import React from 'react';
import PropTypes from 'prop-types';
import {
  CustomCheckboxContainer,
  CustomCheckboxInput
} from '@reach/checkbox';
import "@reach/checkbox/styles.css";
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'inline-block',
    padding: 0,
    height: 'auto',
    width: '100%',
    maxWidth: '33vw',
    margin: 0,
    cursor: 'pointer',
    fontSize: '1.25vw',
    transition: '0.2s all ease-in',
    '&.selected': {
      transform: 'scale(1.045)'
    },
    '&:hover': {
      transform: 'scale(1.045)'
    },
    '&.longText': {
      fontSize: '0.9vw',
      textTransform: 'none',
      '@media screen and (max-width: 1366px)': {
        fontSize: '1.2vw'
      },
      '@media screen and (max-width: 999px)': {
        fontSize: '1.4vw'
      },
      '@media screen and (max-width: 767px)': {
        maxWidth: '37vw',
        fontSize: '1.5vw'
      },

    },
    '&.longText:hover': {
      transform: 'scale(1.035)'
    },
    '&.selected.longText': {
      transform: 'scale(1.035)'
    },
    '@media screen and (max-width: 1366px)': {
      fontSize: '1.5vw'
    },
    '@media screen and (max-width: 999px)': {
      fontSize: '1.7vw'
    },
    '@media screen and (max-width: 767px)': {
      maxWidth: '37vw',
      fontSize: '1.9vw'
    },
    '@media screen and (max-height: 390px) and (orientation:landscape)':{
      height: '15vh',
    }
  },
  buttonContainer: {
    display: 'block',
  },
  button: {
    display: 'block',
    padding: '1.75vw',
    color: '#ffffff',
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    textAlign: 'center',
    clipPath: 'polygon(2% 5%, 98% 0, 100% 100%, 0 93%)',
    background: 'linear-gradient(to right, rgb(0 186 255) 0%, rgb(0 142 255) 100%)',
    fallbacks: {
      background: 'rgba(161,0,255,1)'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      borderRadius: '1rem'
    },
    '@media screen and (max-height: 390px) and (orientation:landscape)':{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 2vw',
      height: '15vh',
    }
  },
  selected: {
    background: 'linear-gradient(to top, #f96355 15%, #ffdb06 100%)',
    fallbacks: {
      background: 'rgb(0 186 255)'
    }
  },
  outerPolygon: {
    position: 'absolute',
    top: '4px',
    left: '3px',
    display: 'inline-block',
    width: 'calc(100% + 0.1vw)',
    height: '100%',
    background: '#3748e2',
    boxSizing: 'border-box',
    clipPath: 'polygon(2% 5%, 98% 0, 100% 100%, 0 93%)',
    '&.selected': {
      background: '#b63428'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      display: 'none'
    }
  }
});

const ButtonCheckbox = ({ id, optionText, checked, updateChecked, longText, disabled = false }) => {
  const classes = useStyles();
  // const [checkedState, setChecked] = useState(props.checked || false);
  // const checked = props.checked !== null ? props.checked : checkedState;

  const update = (evt) => {
    updateChecked(evt.target.checked);
  };

  return (
    <CustomCheckboxContainer
      disabled={disabled}
      checked={checked}
      onChange={update}
      className={`${classes.container} ${checked ? 'selected' : ''} ${longText ? 'longText' : ''}`}
    >
      <CustomCheckboxInput id={id} checked={checked} onChange={update} />

      <div className={`${classes.outerPolygon} ${checked ? 'selected' : ''}`}></div>
      <span
        aria-hidden
        className={`${classes.buttonContainer}`}
      >
        <div className={classes.innerRightBorder}></div>
        <div className={classes.innerLeftBorder}></div>
        <span
          aria-hidden
          className={`${classes.button} ${checked ? classes.selected : ''}`}
        >
          <label htmlFor={id}>{optionText}</label>
        </span>
      </span>
    </CustomCheckboxContainer>
  )
};

ButtonCheckbox.propTypes = {
  optionText: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  updateChecked: PropTypes.func.isRequired
};

export default ButtonCheckbox;
