import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import AnimatedTRDialog from './AnimatedTRDialog';
import {
  OFFICE,
  HOME,
  YES,
  NO,
  CONSULTING_AND_STRATEGY,
  INTERACTIVE,
  TECHNOLOGY,
  OPERATIONS,
  CORPORATE_FUNCTIONS
} from '../life-choices/constants';
import ButtonCustom from '../inputs/ButtonCustom';
import OrangeHeader from '../typography/OrangeHeader';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    backgroundColor: 'transparent',
    width: '40vw',
    margin: '0 auto',
    padding: '0 2rem',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '80vh'
    }
  },
  choiceImage: {
    width: '100%',
    //position: 'absolute',
    marginBottom: 0,
    //ie11 fix
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      height: '100%',
    },
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      bottom: 5
    }
  },
  contentContainer: {
    padding: 20
  },
  container: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      justifyContent: 'end',
      paddingBottom: 0
    }
  },
  bottomContainer: {
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: '0 0 10px 10px',
    minHeight: '8vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      minHeight: '16vh'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      width: '100%'
    },
  },
  textContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    padding: '1.5vw 1vw',
    marginTop: '2vw',
    '& > p': {
      fontSize: '1.1vw',
      fontFamily: '\'Graphik Semibold\', Arial, sans-serif !important',
      fontWeight: 600,
      lineHeight: 1.2,
      '@media screen and (min-aspect-ratio: 2 / 1)': {
        fontSize: '2.2vh'
      }
    },
    zIndex: 1,
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      fontSize: 10,
      padding: 0
    },
    '@media screen and (max-width: 650px) and (orientation:landscape)': {
      fontSize: 9,
      padding: 0
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      padding: '3vh 2vh',
      marginTop: '4vh'
    }
  },
  headerRoot: {
    width: '19vw',
    height: '4.5vw',
    position: 'absolute',
    left: 'calc(50% - 9.5vw)',
    marginTop: '-2vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '38vh',
      height: '9vh',
      left: 'calc(50% - 19vh)',
      marginTop: '-4vh'
    }
  },
  headerText: {
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      fontSize: '2.4vh'
    }
  },
  buttonRoot: {
    width: '12vw',
    height: '4vw',
    bottom: '-2vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '24vh',
      height: '8vh',
      bottom: '-4vh'
    }
  },
  buttonText: {
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      fontSize: '4.2vh'
    }
  }
});

const accentureText = `No matter what area you are working in, the health of your workstation is paramount to getting your job done.`
const lifeChoiceImageMap = { // img links should go here
  [OFFICE]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/LifeChoice-1-Office.png',
    header: 'SUCH A CLEAN OFFICE!',
    text: `To begin your day at the office let’s log into your
    workstation. Your username and password are
    the first line of defense to protect sensitive
    Accenture and client information.`
  },
  [HOME]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/LifeChoice-1-Home.png',
    header: 'SUCH A NICE HOME!',
    text: `To begin your day working from home, let's log
    into your workstation. Your username and
    password are the first line of defense to protect
    sensitive Accenture and client information.`,
  },
  [YES]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/PlaneCard-01.png',
    header: 'I GET THE WINDOW SEAT!',
    text: `I love traveling and helping
    our clients around the globe. However, one of the hardest parts
    of being a global company is sharing our deliverables securely.
    We have many options for sharing and it can be tricky to know the right tool for the job.`,
  },
  [NO]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/TrainCard-01.png',
    header: 'THIS TRAIN RIDE IS SO SMOOTH!',
    text: `You know, as a global company,
    we often need to share documents internally and externally,
    and even across geographies. Even though you might not travel
    for work, you will need to understand the best practices for sharing information.`,
  },
  [CONSULTING_AND_STRATEGY]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Consulting.png',
    header: 'WELCOME to consulting!',
    text: accentureText,
  },
  [INTERACTIVE]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Interactive.png',
    header: 'WELCOME to interactive!',
    text: accentureText,
  },
  [TECHNOLOGY]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Technology.png',
    header: 'WELCOME to TECHNOLOGY',
    text: accentureText,
  },
  [OPERATIONS]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Strategy.png',
    header: 'WELCOME to OPERATIONS!',
    text: accentureText,
  },
  [CORPORATE_FUNCTIONS]: {
    src: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Corporate.png',
    header: 'WELCOME to CORPORATE FUNCTIONS',
    text: accentureText,
  },
};


const LifeChoiceOutcome = ({ choice, isOpen, close }) => {
  const classes = useStyles();

  // default value
  if (!choice) {
    choice = "OFFICE"
  }
  return (
    <AnimatedTRDialog
      ariaLabel={'Life choice'}
      isOpen={isOpen}
      // isOpen={true}
      close={close}
      //      dialogStyles={dialogStyles}
      classes={{ dialog: classes.dialog }}
    >
      {/* Add IMG with character on rigth src=charactersSourceMap[character.toUpperCase()]  */}
      <div className={classes.root}>
        <div className={classes.container} >
          <img
            className={classes.choiceImage}
            src={lifeChoiceImageMap[choice]?.src || ''}
            alt='officeOrHomePicture'
            role='presentation'
          />
          <div className={classes.bottomContainer}>
            <OrangeHeader
              fontSize={{ fontSize: "0.6vw" }}
              headerText={lifeChoiceImageMap[choice]?.header}
              // rootStyles={orangeHeaderStyles}
              classes={{ root: classes.headerRoot, text: classes.headerText }}
            />
            <div className={classes.textContent}>
              <p tabIndex={0}>{lifeChoiceImageMap[choice]?.text}</p>
            </div>
            <ButtonCustom
              onClick={close}
              buttonText={'GO!'}
              arialabeltext={'Continue game'}
              // overlayRootStyles={overlayRootButtonStyles}
              classes={{ root: classes.buttonRoot, buttonText: classes.buttonText }}
            />
          </div>
        </div>
      </div>
    </AnimatedTRDialog>
  );
};

LifeChoiceOutcome.propTypes = {
  choice: PropTypes.string.isRequired
};
export default LifeChoiceOutcome;
