import { Machine, actions } from 'xstate';
import { setScore, setLastStep, increasePoints, markTopicCompleted, setTopicPointTotal } from '../store/action-creators';
import { setScoreInMachineContext } from '../utils/helpers';

const { assign } = actions;

export default function createTopicTwoMachine(context) {
  // destructuring here to provide insight into the shape of context
  const { step, completed, qs, dispatch, scorm } = context;

  return Machine(
    {
      id: 'topicTwo',
      initial: 'start',
      context: {
        step,
        completed,
        qs,
        dispatch,
        scorm,
        currentQuestionIndex: 0
      },
      states: {
        entry: [assign({ step: 'start' }), 'dispatchSetLastStep'],
        start: {
          after: {
            1500: [
              { target: 'initialOutlookScreen', cond: 'shouldShowInitialOutlookScreen' },
              { target: 'firstHelpfulHints', cond: 'shouldShowFirstHelpfulHints' },
              { target: 'secondOutlookScreen', cond: 'shouldShowSecondOutlookScreen' },
//              { target: 'firstIconDialog', cond: 'shouldShowFirstIconDialog' },
              { target: 'secondIconDialog', cond: 'shouldShowSecondIconDialog' },
              { target: 'thirdIconDialog', cond: 'shouldShowThirdIconDialog' },
              { target: 'preQuestionsScreen', cond: 'shouldShowPreQuestionsScreen' },
              { target: 'question.awaitingInput', cond: 'shouldShowQuestion' },
              { target: 'question.outcome', cond: 'shouldShowQuestionOutcome' },
              { target: 'finished' },
            ]
          }
        },
        initialOutlookScreen: {
          on: {
            CONTINUE: {
              target: 'firstHelpfulHints'
            }
          }
        },
        firstHelpfulHints: {
          entry: [assign({ step: 'firstHelpfulHints' }), 'dispatchSetLastStep'],
          on: {
            CONTINUE: {
              target: 'secondOutlookScreen'
            },
            BACK: {
              target: 'initialOutlookScreen'
            }
          }
        },
        secondOutlookScreen: {
          entry: [assign({ step: 'secondOutlookScreen' }), 'dispatchSetLastStep'],
          on: {
            CONTINUE: {
              target: 'secondIconDialog'
            },
            BACK: {
              target: 'firstHelpfulHints'
            }
          }
        },
//        firstIconDialog: {
//          entry: [assign({ step: 'firstIconDialog' }), 'dispatchSetLastStep'],
//          on: {
//            CONTINUE: {
//              target: 'secondIconDialog'
//            }
//          }
//        },
        secondIconDialog: {
          entry: [assign({ step: 'secondIconDialog' }), 'dispatchSetLastStep'],
          on: {
            CONTINUE: {
              target: 'thirdIconDialog'
            },
            BACK: {
              target: 'secondOutlookScreen'
            }
          }
        },
        thirdIconDialog: {
          entry: [assign({ step: 'thirdIconDialog' }), 'dispatchSetLastStep'],
          on: {
            CONTINUE: {
              target: 'preQuestionsScreen'
            },
            BACK: {
              target: 'secondIconDialog'
            }
          }
        },
        preQuestionsScreen: {
          id: 'preQuestionsScreen',
          entry: [assign({ step: 'preQuestionsScreen' }), 'dispatchSetLastStep'],
          on: {
            CONTINUE: {
              target: 'question'
            },
            BACK: {
              target: 'thirdIconDialog'
            }
          }
        },
        question: {
          entry: [assign({ step: 'question' }), 'dispatchSetLastStep'],
          initial: 'awaitingInput',
          states: {
            awaitingInput: {
              on: {
                ANSWER: {
                  target: 'outcome'
                },
                BACK: {
                  target: '#preQuestionsScreen', internal: false
                }
              }
            },
            outcome: {
              entry: [assign({ step: 'questionOutcome' }), 'dispatchSetScore', 'dispatchIncreasePoints', 'dispatchSetLastStep'], // allocate points in action here
              on: {
                CONTINUE: {
                  target: '#finished'
                }
              }
            }
          }
        },
        finished: {
          id: 'finished',
          entry: [
            assign({ step: 'finished' }),
            'dispatchSetLastStep'
          ],
          on: {
            CONTINUE: {
              target: 'final'
            }
          }
        },
        final: {
          entry: [
            'dispatchSetPointTotal',
            'dispatchTopicTwoComplete'
          ],
          type: 'final'
        }
      }
    },
    {
      actions: {
        dispatchSetScore: (ctx, evt) => {
          if (!evt || !evt.ans) return;

          const i = ctx.scorm.getInteractionCount(); // the interactionCount
          const {
            q, // the question key (e.g., 'q1)
            interactionType, // SCORM question type ('performance' for all of them)
            response, // user's input
            result, // must be either 'correct' or 'wrong'
            questionSet, // the topic key (e.g., 't01')
            objective, // a string (no white-space) describing the question
            ans // the correct answer
          } = evt;
          // we can take advantage of the fact that context is set first with assign
          setScoreInMachineContext(ctx, assign, q, result, response);
          ctx.dispatch(setScore({ questionSet, response, question: q, score: result === 'correct' ? 1 : 0 }));
          ctx.scorm.logInteraction({ i, q, interactionType, response, questionSet, objective, ans });
          ctx.scorm.logResult({ i, result });
        },
        dispatchSetLastStep: (ctx, evt) => {
          // we can take advantage of the fact that context is set first with assign
          ctx.dispatch(setLastStep('t02', ctx.step));
          ctx.scorm.commitAndSaveProgress();
        },
        dispatchIncreasePoints: (ctx, evt) => {
          if (!evt || !evt.result) return;

          if (evt.result === 'correct') {
            ctx.dispatch(increasePoints(evt.points));
          }
          ctx.scorm.commitAndSaveProgress();
        },
        dispatchSetPointTotal(ctx, evt) {
          ctx.dispatch(setTopicPointTotal('t02'));
        },
        dispatchTopicTwoComplete(ctx, evt) {
          ctx.dispatch(markTopicCompleted('t02'));
          ctx.scorm.commitAndSaveProgress();
        },
      },
      guards: {
        shouldShowInitialOutlookScreen: (ctx) => {
          return ctx.step === 'start' || ctx.step === 'initialOutlookScreen';
        },
        shouldShowFirstHelpfulHints: (ctx) => {
          return ctx.step === 'firstHelpfulHints';
        },
        shouldShowSecondOutlookScreen: (ctx) => {
          return ctx.step === 'secondOutlookScreen';
        },
//        shouldShowFirstIconDialog: (ctx) => {
//          return ctx.step === 'firstIconDialog';
//        },
        shouldShowSecondIconDialog: (ctx) => {
          return ctx.step === 'secondIconDialog';
        },
        shouldShowThirdIconDialog: (ctx) => {
          return ctx.step === 'thirdIconDialog';
        },
        shouldShowPreQuestionsScreen: (ctx) => {
          return ctx.step === 'preQuestionsScreen';
        },
        shouldShowQuestion: (ctx) => {
          return ctx.step === 'question';
        },
        shouldShowQuestionOutcome: (ctx) => {
          return ctx.step === 'questionOutcome';
        },
        shouldShowFinished: (ctx) => {
          return ctx.step === 'finished';
        }
      },
    }
  );
}
