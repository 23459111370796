import { useEffect, useRef } from 'react';

export default function useInitialFocus() {
  const mounted = useRef(false);
  const initialFocus = useRef();

  useEffect(() => {
    if (!mounted.current && initialFocus.current) {
      mounted.current = true;
      initialFocus.current.focus();
    }
  });

  return initialFocus;
}