import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import PropTypes from 'prop-types';
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox';

const useStyles = createUseStyles({
  container: {
    display: 'inline-block',
    padding: 0,
    height: '4vw',
    width: '4vw',
    fontSize: '1.7vw',
    margin: 0,
    marginLeft: '2vw',
    cursor: 'pointer',
    // filter: 'drop-shadow(0px 0px 4px rgba(50, 50, 50, 0.8))'
    '&:hover:not(.selected) .box': {
      transform: 'scale(1.05)',
      fontSize: '105%',
    },
    '&:hover:not(.selected) .shadow': {
      left: 0,
      top: -1,
      opacity: 0.2
    },
    '&:hover:not(.selected) img': {
      transform: 'scale(1.05)',
      left: '-2.3vw'
    }
  },
  buttonShadow: {
    display: 'block',
    backgroundColor: ({ theme }) => theme.colors.acn.darkPurple,
    position: 'absolute',
    width: '110%',
    height: '105%',
    top: 3,
    left: 5,
    padding: 0,
    clipPath: 'polygon(0 6%, 100% 0, 96% 100%, 0 94%)',
    transition: 'all 200ms ease-in-out',
    '&.selected': {
      backgroundColor: '#23361C'
    },
    //ie11 fix
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      borderRadius: '1rem',
      top: 3,
      left: 4,
    },
  },
  button: {
    display: 'flex',
    padding: 0,
    paddingLeft: 5,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#ffffff',
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    backgroundColor: ({ theme }) => theme.colors.acn.lightPurple,
    textAlign: 'center',
    clipPath: 'polygon(0 6%, 100% 0, 96% 100%, 0 94%)',
    transition: 'all 200ms ease-in-out',
    '&.selected': {
      backgroundColor: ({ theme }) => theme.colors.game.green,
    },
    //ie11 fix
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      position: 'absolute',
      borderRadius: '1rem',
    },
  },
  check: {
    display: 'inline-block',
    transformOrigin: 'center',
    transform: 'rotate(45deg) translateY(-10%)',
    height: '1.3vw',
    width: '0.65vw',
    borderBottom: '4px solid #ffffff',
    borderRight: '4px solid #ffffff',
    marginLeft: -10,
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      height: '1.15vw',
      width: '0.5vw',
      borderBottom: '2px solid #ffffff',
      borderRight: '2px solid #ffffff',
    }
  },
  coinValue: {
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    marginLeft: -3
  },
  //right top corner border
  innerRightBorder: {
    display: 'block',
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    left: '1%',
    width: '93%',
    height: '97%',
    clipPath: 'polygon(64% 13%, 100% 9%, 99% 58%, 97% 16%)',
    backgroundColor: '#cc00ff',
    '&.selected': {
      backgroundColor: '#94D97D'
    },
    //ie11 fix
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      display: 'none'
    },
  },
  //left bottom corner border
  innerLeftBorder: {
    display: 'block',
    position: 'absolute',
    textAlign: 'center',
    width: '93%',
    height: '95%',
    top: '-1%',
    left: '1%',
    clipPath: 'polygon(6% 49%, 8% 90%, 46% 93%, 4% 94%)',
    backgroundColor: '#cc00ff',
    '&.selected': {
      backgroundColor: '#94D97D'
    },
    //ie11 fix
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      display: 'none'
    },
  },
  coin: {
    width: '3vw',
    height: 'auto',
    position: 'absolute',
    left: '-2vw',
    top: '0.5vw',
    transition: 'all 200ms ease-in-out',
    zIndex: 3,
    pointerEvents: 'none',
    '&.bounce': {
      animation: 'bounce 0.8s infinite alternate'
    }
  },
  hide: {
    display: 'none'
  },

});

const CoinCheckbox = ({
  iconSrc = 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Coin-03.png',
  isChecked,
  value,
  onChange,
  bounce,
  hintText,
  overlayRootContainer,
  overlayButton,
  overlayButtonShadow,
  overlayCoinStyles,
  classes = {},
  idx,
  checkBoxAriaLabel = null
}) => {
  const theme = useTheme();
  const localClasses = useStyles({ theme });

  return (
    <CustomCheckboxContainer
      checked={isChecked}
      onChange={onChange}
      className={`${localClasses.container} ${isChecked ? 'selected' : ''} ${classes.container || ''}`}
      style={{ ...overlayRootContainer }}
    >
      <CustomCheckboxInput
        aria-label={checkBoxAriaLabel ? checkBoxAriaLabel : `#${idx + 1}`}
        aria-describedby={`test${idx}`} 
        checked={isChecked} onChange={onChange} />
      <span
        aria-hidden
        className={`${localClasses.buttonShadow} ${isChecked ? 'selected' : ''} shadow`}
        style={{ ...overlayButton }}
      >
      </span>
      <span
        aria-hidden
        className={`${localClasses.button} ${isChecked ? 'selected' : ''} ${classes.button || ''} box`}
        style={{ ...overlayButtonShadow }}
      >
        {isChecked ? <span className={`${localClasses.check} ${classes.check}`}></span> : <span className={localClasses.coinValue}>{value}</span>}
        <span className={`${localClasses.innerRightBorder} ${isChecked ? 'selected' : ''}`}></span>
        <span className={`${localClasses.innerLeftBorder} ${isChecked ? 'selected' : ''}`}></span>
      </span>
      <img
        src={iconSrc} alt='coin' role='presentation'
        className={`${isChecked ? localClasses.hide : localClasses.coin} ${classes.coin || ''} ${bounce === 'bounce' ? 'bounce' : ''}`}
        style={{ ...overlayCoinStyles }}
      />
    </CustomCheckboxContainer>
  )
}

CoinCheckbox.propTypes = {
  iconSrc: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hintText: PropTypes.string,
  overlayRootContainer: PropTypes.object,
  overlayButton: PropTypes.object,
  overlayButtonShadow: PropTypes.object,
  overlayCoinStyles: PropTypes.object,
  classes: PropTypes.objectOf(PropTypes.string)
};

export default CoinCheckbox;
