import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import useScorm from '../utils/hooks/useScorm';
import createTopicFourMachine from '../machines/topicFourMachine';

/*---------------------------------*/

const TopicFourContext = React.createContext([{}, {}]);

/*---------------------------------*/

const TopicFourContextProvider = (props) => {
  const topicFourState = useSelector((state) => state.questions.t04);
  const dispatch = useDispatch();
  const scorm = useScorm(); 
  const [machineState, send] = useMachine(createTopicFourMachine({ ...topicFourState, dispatch, scorm }), { devTools: true });

  useEffect(() => {
    send('START');
  }, [send]);

  return (
    <TopicFourContext.Provider value={{ machineState, send }}>
      {props.children}
    </TopicFourContext.Provider>
  )
};

TopicFourContextProvider.propTypes = {
};

export { TopicFourContext, TopicFourContextProvider };

