import React, { } from 'react';
import { createUseStyles } from 'react-jss';
import LeftSideAvatar from './LeftSideAvatar';
import MiddleCollection from './MiddleCollection';
import RightCointHud from './RightCoinHud';
import BottomProfessor from './BottomProfessor';
import BubbleDialog from '../dialogs/BubbleDialog';


const useStyles = createUseStyles({
  frame: {
    // backgroundImage: `url(https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Frame.png)`,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    left: 0,
    boxShadow: '0px 2px 3px 1px #0000007d'
  },
  frameImg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
  }
});

const Frame = ({ boardSize }) => {
  const classes = useStyles();

  return (
    <div className={classes.frame} >
      <img className={classes.frameImg} src='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Frame.png' alt='' role='presentation'/>
      <LeftSideAvatar boardSize={boardSize} />
      <MiddleCollection boardSize={boardSize} />
      <RightCointHud boardSize={boardSize}/>
      <BottomProfessor boardSize={boardSize} />
      <BubbleDialog boardSize={boardSize} />
    </div>)
}

export default Frame;
