import React, { useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentPath } from '../store/action-creators';
import Splash from '../components/intro/Splash';
import WelcomeMessage from '../components/intro/WelcomeMessage';
import CharacterSelection from '../components/intro/CharacterSelection';
import { IntroContextProvider, IntroContext } from '../contexts/introContext';

const IntroContent = (props) => {
  const { machineState } = useContext(IntroContext);
  // const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (machineState.matches('final')) {
      // use history.push here instead of setCurrentPath b/c '/' route is not guarded
      // history.push('/board');
      dispatch(setCurrentPath('/board'));
    }
  }, [machineState, dispatch]);

  return (
    <main>
      {machineState.matches('start') ? <Splash /> : null}
      {machineState.matches('welcomeMessage') ? <WelcomeMessage /> : null}
      {machineState.matches('characterSelection') ? <CharacterSelection /> : null}
    </main>
  );
};

const Intro = (props) => {
  return (
    <IntroContextProvider>
      <IntroContent />
    </IntroContextProvider>
  )
};

Intro.propTypes = {

};

export default Intro;