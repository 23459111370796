import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import ButtonCustom from '../../inputs/ButtonCustom';
import BrowserContainer from './BrowserContainer';
import useInitialFocus from '../../../utils/hooks/useInitialFocus';
import { topicFourApps } from '../../../data/hints';

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  },
  background: {
    boxSizing: 'border-box',
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    flex: '50%'
  },
  leftSide: {
    flex: 1.75,
    height: '100%'
  },
  rightSide: {
    flex: 1,
    height: '100%',
    flexGrow: 1,
    '-webkit-flex-grow': 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'left',
    paddingLeft: 20,
    fontSize: '1.35vw',
    '& > div:first-child': {
      marginBottom: '2vw'
    }
  },
  center: {
    textAlign: 'center',
    color: 'white',
    fontSize: '2.2vw',
    fontWeight: 600,
    width: '70%',
    lineHeight: 1.3,
    marginTop: '3vw'
  }
});

// const disabledInnerStyles = {
//   background: 'darkgrey'
// };
// const disabledOuterStyles = {
//   background: 'black'
// };
// const disabledBorderStyles = {
//   background: 'lightgrey'
// };

const BrowserView = ({ onClick, isOpen = true, audioClickHandlerT4 }) => {
  //false for test
  const classes = useStyles();
  const helpfulHintsShowed = useSelector((state) => state.progress.helpfulHintsShowed);
  const initialFocusText = useInitialFocus();

  const disableButton = useMemo(() => {
    return !topicFourApps.every((hint) => hint.id in helpfulHintsShowed);
  }, [helpfulHintsShowed]);

  return (
    isOpen &&
    <div className={classes.background}>
      {/* Left side */}
      <div className={classes.leftSide}>
        <BrowserContainer disableButton={disableButton} audioClickHandlerT4={audioClickHandlerT4} />
      </div>
      {/* Right side */}
      <div className={classes.rightSide}>
        <div style={{ width: '100%' }}>
          <p ref={initialFocusText} tabIndex={1}>
            {`When downloading new software, navigate to software.accenture.com to find approved software. Never download or install peer-to-peer software (like BitTorrent). Only download apps for your mobile devices from reputable sources like the Apple App Store, Google Play, or the Accenture Company Portal.`}
          </p>
        </div>
        <div>
          <ButtonCustom
            isBloop={false}
            buttonText={'GOT IT!'}
            // disabled={disableButton}
            // disabledOuterStyles={disableButton ? disabledOuterStyles : {}}
            // disabledInnerStyles={disableButton ? disabledInnerStyles : {}}
            // disabledBorderStyles={disableButton ? disabledBorderStyles : {}}
            onClick={onClick}
          />
        </div>
      </div>
    </div>

  )
};

BrowserView.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool
};

export default BrowserView;
