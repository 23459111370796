import React, { useContext, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setLifeChoice } from '../../store/action-creators';
import TRDialog from './TRDialog';
import OfficeOrHome from '../life-choices/OfficeOrHome';
import TravelForWork from '../life-choices/TravelForWork';
import AccentureArea from '../life-choices/AccentureArea';
import { BoardContext } from '../../contexts/boardContext';


const LifeChoicesComponent = ({ lifeChoices, makeSelection, ariaId }) => {
  if (!lifeChoices.officeOrHome) {
    return <OfficeOrHome ariaId={ariaId} makeSelection={makeSelection}/>
  }
  if (!lifeChoices.travel) {
    return <TravelForWork ariaId={ariaId} makeSelection={makeSelection} />;
  }
  if (!lifeChoices.accentureArea) {
    return <AccentureArea ariaId={ariaId} makeSelection={makeSelection} />;
  }
  return null;
};

const dialogStyles = {
  backgroundColor: 'transparent',
  width: '70vw',
  margin: '5vh auto 10vh'
};

const LifeChoiceSelection = (props) => {
  const { machineState, send } = useContext(BoardContext);
  const lifeChoices = useSelector(state => ({
    officeOrHome: state.player.officeOrHome,
    travel: state.player.travel,
    accentureArea: state.player.accentureArea
  }));
  const dispatch = useDispatch();
  const ariaId = useRef('life-choice-heading');

  const makeSelection = (lifeChoiceField, selection) => {
    dispatch(setLifeChoice(lifeChoiceField, selection))
    send('SELECT');
  };

  return (
    <TRDialog
      dialogStyles={dialogStyles}
      ariaLabelledBy={ariaId.current}
      isOpen={machineState.matches('lifeChoice')}
      close={() => null} // this is to prevent clicking outside to dismiss...a choice must be made!
    >
      <LifeChoicesComponent ariaId={ariaId.current} lifeChoices={lifeChoices} makeSelection={makeSelection}/>
    </TRDialog>
  );
};

LifeChoiceSelection.propTypes = {

};

export default LifeChoiceSelection;
