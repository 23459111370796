import { Machine } from 'xstate';

export default function createSummaryMachine(context) {

  // destructuring here to provide insight into the shape of context
  const {
    character,
    dispatch,
    scorm,
  } = context;

  return Machine({
    id: 'summary',
    initial: 'congratulations',
    context: {
      character,
      dispatch,
      scorm,
    },
    states: {
      congratulations: {
        entry: ['completeCourse'],
        on: {
          CONTINUE: {
            target: 'summaryTitle'
          }
        }
      },
      summaryTitle: {
        after: {
          2000: {
            target: 'topicSummary'
          }
        },
        on: {
          CONTINUE: {
            target: 'topicSummary'
          }
        }
      },
      topicSummary: {
        id: 'topicSummary',
        initial: 'start',
        states: {
          start: {
            after: {
              100: {
                target: 'passwords'  
              }
            }
          },
          passwords: {
            on: {
              CONTINUE: {
                target: 'outlook'
              }
            }
          },
          outlook: {
            on: {
              CONTINUE: {
                target: 'sharingTools'
              }
            }
          },
          sharingTools: {
            on: {
              CONTINUE: {
                target: 'workstationHygiene'
              }
            }
          },
          workstationHygiene: {
            on: {
              CONTINUE: {
                target: '#certificate'
              }
            }
          }
        }
      },
      // may need additional intermediary step
      certificate: {
        id: 'certificate',
        type: 'final'
      }
    }
  }, 
  {
    actions: {
      completeCourse: (ctx) => {
        ctx.scorm.completeCourse();
      }
    }
  });

}
