import React, { useContext, useEffect, useRef } from 'react';
//import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import Lottie from 'lottie-react';
import confetti from '../../static/animation/misc/Confetti.json';
import ButtonCustom from '../inputs/ButtonCustom';
import { SummaryContext } from '../../contexts/summaryContext';
import useInitialFocus from '../../utils/hooks/useInitialFocus';
import { audioCelebration } from '../../data/audioMap';
import { useSelector } from 'react-redux';
import { sfxPlayHandler } from '../../utils/helpers';

const useStyles = createUseStyles({
  root: {
    width: '60%',
    minWidth: 350,
    textAlign: 'center'
  },
  buttonContainer: {

  },
  textContainer: {
    color: '#ffffff',
    '& h1': {
      textTransform: 'uppercase',
      fontSize: '5vw',
      marginTop: 0,
      marginBottom: 25,
      '@media screen and (min-width: 1600px)': {
        fontSize: 96
      },
      '@media screen and (max-width: 767px)': {
        fontSize: 36
      },
      '@media screen and (max-width: 600px)': {
        fontSize: 28
      }
    },
    '& p': {
      fontFamily: '\'Graphik Semibold\', \'Arial Black\', sans-serif !important',
      fontSize: 28,
      '@media screen and (max-width: 1366px)': {
        fontSize: 24
      },
      '@media screen and (max-width: 999px)': {
        fontSize: 20
      },
      '@media screen and (max-width: 767px)': {
        fontSize: 15
      }
    }
  },
  confetti: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    pointerEvents: 'none',
    overflow: 'hidden',
    zIndex: 2,
    '@media screen and (max-aspect-ratio: 16/9)': {
      width: '180vh'
    },
//    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
//      height: 'inherit'
//    },
  }
});

const Congratulations = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { send } = useContext(SummaryContext);
  const ref = useRef();
  const initFocus = useInitialFocus();
  const audioCelebrate = useRef();
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  const onComplete = (_evt) => {
    playCongrats();
    // the following code is used instead of just looping the animation in order to create a pause between cycles
    ref.current.playSegments(Array.from({ length: 10 }, (_) => [0, 2]), true);
    ref.current.playSegments([0, 100]);
  };
  const playCongrats = () => {
    if (soundsEffects) {
      audioCelebrate.current.pause();
      sfxPlayHandler(audioCelebrate);
    }
  }
  useEffect(() => {
    audioCelebrate.current.volume = 0.4;
  }, [])

  useEffect(() => {
    if (soundsEffects) {
      sfxPlayHandler(audioCelebrate);
    } else {
      audioCelebrate.current.pause();
    }
  }, [soundsEffects])

  const overlayButtonStyles = {
    background: theme.colors.acn.blue,
  }
  const disabledOuterStyles = {
    background: theme.colors.acn.darkBlue,
  }
  const disabledInnerStyles = {
    background: theme.colors.acn.blue,
  }
  const disabledBorderStyles = {
    background: theme.colors.acn.lightBlue,
  }

  return (
    <section className={`${classes.root} ${classes.textContainer}`}>
      <audio id='musicSum' ref={audioCelebrate} src={audioCelebration} type='audio' />
      <div className={classes.confetti}>
        <Lottie lottieRef={ref} animationData={confetti} autoplay={true} loop={0} onComplete={onComplete} style={{ width: 'inherit', height: 'inherit' }}/>
      </div>
      <h1 ref={initFocus} tabIndex={0}>Congratulations!</h1>
      <p tabIndex={0}>You’ve completed Spin for Security! After a quick recap, be sure to select the finish button to earn credit for completing the course.</p>
      <div className={classes.buttonContainer}>
        <ButtonCustom
          buttonText='Summary!'
          onClick={() => send('CONTINUE')}
          overlayButtonStyles={overlayButtonStyles}
          disabledOuterStyles={disabledOuterStyles}
          disabledInnerStyles={disabledInnerStyles}
          disabledBorderStyles={disabledBorderStyles}
        />
      </div>
    </section>
  );
};

Congratulations.propTypes = {};

export default Congratulations;
