import React from 'react';
import PropTypes from 'prop-types';
import {
  CONSULTING_AND_STRATEGY,
  INTERACTIVE,
  TECHNOLOGY,
  OPERATIONS,
  CORPORATE_FUNCTIONS
} from './constants';
import { createUseStyles } from 'react-jss';
import ButtonCustom from '../inputs/ButtonCustom';
import OrangeHeader from '../typography/OrangeHeader';

import { lifeChoiceSrcMap } from '../life-choices/sourcemap'

const cardBaseStyles = {
  width: '15vw',
  height: '18vw',
  fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  borderRadius: '27px',
  fontSize: '1.25vw',
  margin: '3vw 1vw 1vw'
}

const useStyles = createUseStyles({
  cards: {
    ...cardBaseStyles
  },
  img: {
    height: '8vw',
    margin: '1.75vw 0 2.5vw'
  },
  bottomContainer: {
    backgroundColor: 'white',
    borderRadius: '5%',
    height: '18vw',
    width: '15vw'
  },
  text: {
    position: 'relative',
    '& span': {
      fontFamily: '\'Graphik Regular\', \'Arial\', sans-serif !important',
      color: '#a100ff'
    }
  },
  root: {
    display: 'flex',
    justifyContent: 'center'
  }
})

const AccentureArea = ({ makeSelection, ariaId }) => {
  const classes = useStyles();

  const overlayRootButtonStyles = {
    //different size for ie11 (true)
    width: window.msCrypto ? '10vw' : '11vw',
    height: '3vw',
    marginTop: window.msCrypto ? '2.1vw' : '2.5vw',
  }
  const innerPoligonStyles = {
    width: '97.5%'
  };

  return (
    <>
      <OrangeHeader
        headerText={'Which area of Accenture are you specializing in?'}
        rootStyles={{
          paddingBottom: '1.3vw',
          width: '54vw',
          height: '5.5vw',
          position: 'relative',
          left: '50%',
          transform: 'translate(-50%, 0)',

        }}
        innerPoligonStyles={innerPoligonStyles}
        fontSize={{ fontSize: '0.9vw', textTransform: 'unset' }} />
      <div className={classes.root}>
        <div className={classes.cards}>
          <div className={classes.bottomContainer} >
            <img
              className={classes.img}
              src={lifeChoiceSrcMap[CONSULTING_AND_STRATEGY].src}
              alt=''
              role='presentation'
            />
            <div role='main' className={classes.text} >
              Accenture <span>Strategy &amp; Consulting</span>
            </div>
            <ButtonCustom
              onClick={() => makeSelection('accentureArea', CONSULTING_AND_STRATEGY)}
              buttonText={'GO!'}
              arialabeltext={'Strategy & Consulting'}
              overlayRootStyles={overlayRootButtonStyles} />
          </div>
        </div>
        <div className={classes.cards}>
          <div className={classes.bottomContainer} >
            <img
              className={classes.img}
              src={lifeChoiceSrcMap[OPERATIONS].src}
              alt=''
              role='presentation'
            />
            <div role='main' aria-label={'Operations'} className={classes.text}>
              Accenture <span>Operations</span>
            </div>
            <ButtonCustom
              onClick={() => makeSelection('accentureArea', OPERATIONS)}
              buttonText={'GO!'}
              arialabeltext={'Operations'}
              overlayRootStyles={overlayRootButtonStyles} />
          </div>
        </div>
        <div className={classes.cards}>
          <div className={classes.bottomContainer} >
            <img
              className={classes.img}
              src={lifeChoiceSrcMap[INTERACTIVE].src}
              alt=''
              role='presentation'
            />
            <div role='main' aria-label={'Interactive'} className={classes.text}>
              Accenture <span>Interactive</span>
            </div>
            <ButtonCustom
              onClick={() => makeSelection('accentureArea', INTERACTIVE)}
              buttonText={'GO!'}
              arialabeltext={'Interactive'}
              overlayRootStyles={overlayRootButtonStyles} />
          </div>
        </div>
        <div className={classes.cards}>
          <div className={classes.bottomContainer} >
            <img
              className={classes.img}
              src={lifeChoiceSrcMap[TECHNOLOGY].src}
              alt=''
              role='presentation'
            />
            <div role='main' aria-label={'Technology'} className={classes.text}>
              Accenture <span>Technology</span>
            </div>
            <ButtonCustom
              onClick={() => makeSelection('accentureArea', TECHNOLOGY)}
              buttonText={'GO!'}
              arialabeltext={'Technology'}
              overlayRootStyles={overlayRootButtonStyles} />
          </div>
        </div>
        <div className={classes.cards}>
          <div className={classes.bottomContainer} >
            <img
              className={classes.img}
              src={lifeChoiceSrcMap[CORPORATE_FUNCTIONS].src}
              alt=''
              role='presentation'
            />
            <div role='main' className={classes.text}>
              Corporate Functions
            </div>
            <ButtonCustom
              onClick={() => makeSelection('accentureArea', CORPORATE_FUNCTIONS)}
              buttonText={'GO!'}
              arialabeltext={'Corporate Functions'}
              overlayRootStyles={overlayRootButtonStyles} />
          </div>
        </div>

      </div>
    </>
  );
};

AccentureArea.propTypes = {
  ariaId: PropTypes.string.isRequired,
  makeSelection: PropTypes.func.isRequired
};

export default AccentureArea;
