import BackButton from '../components/inputs/BackButton';
import React, { useContext, useMemo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPath } from '../store/action-creators';
import { createUseStyles } from 'react-jss';
import { TopicThreeContextProvider, TopicThreeContext } from '../contexts/topicThreeContext';
import TopicThreeInitialView from '../components/topics/topic-3/TopicThreeInitialView';
import TopicThreeScreenView from '../components/topics/topic-3/TopicThreeScreenView';
import TopicThreeQuestionView from '../components/topics/topic-3/TopicThreeQuestionView';
import ExitCourseButton from '../components/inputs/ExitCourseButton';
import { audioClickMap } from '../data/audioMap';
import { sfxPlayHandler } from '../utils/helpers';

const useStyles = createUseStyles({
  viewContainer: {
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'auto', // necessary for InnerScreenView component to scroll on very wide screens
    backgroundColor: '#ddffff'
  }
})


const TopicThreeContents = () => {
  const classes = useStyles();
  const { machineState } = useContext(TopicThreeContext);
  const dispatch = useDispatch();
  const audioClickRef = useRef();
  const soundsEffects = useSelector(state => state.settings.soundsEffects);

  //Variables
  const showInitialView = useMemo(() => {
    return machineState.matches('start') || machineState.matches('lifeChoice') || machineState.matches('firstHelpfulHints') ||
      machineState.matches('secondHelpfulHints');
  }, [machineState]);

  const showMonitorView = useMemo(() => {
    return machineState.matches('monitor');
  }, [machineState])

  const showTopicThreeQuestionView = useMemo(() => {
    return !showMonitorView && !showInitialView;
  }, [showMonitorView, showInitialView]);

  useEffect(() => {
    if (machineState.matches('final')) {
      // history.push('/board');
      dispatch(setCurrentPath('/board'));
    }
  }, [machineState, dispatch]);

  const audioClickHandlerT3 = () => {
    try {
      audioClickRef.current.pause();
      if (soundsEffects) sfxPlayHandler(audioClickRef);
    }
    catch { }
  }

  return (
    <main>
      <section className={classes.viewContainer}>
        <audio id='musict3' ref={audioClickRef} src={audioClickMap[2]} type='audio' />
        {showInitialView && <TopicThreeInitialView />}
        {showMonitorView && <TopicThreeScreenView audioClickHandlerT3={audioClickHandlerT3} />}
        {showTopicThreeQuestionView && <TopicThreeQuestionView />}
      </section>
      <BackButton hide={machineState.matches('start')}/>
    </main>
  );
};

const TopicThree = (props) => {
  return (
    <TopicThreeContextProvider >
      <TopicThreeContents />
      <ExitCourseButton />
    </TopicThreeContextProvider>
  );
};

export default TopicThree;
