import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const Julia = React.lazy(() => import('./JuliaGamePiece'));
const Michael = React.lazy(() => import('./MichaelGamePiece'));
const Sarah = React.lazy(() => import('./SarahGamePiece'));
const Anthony = React.lazy(() => import('./AnthonyGamePiece'));

const getCharacter = (state) => state.player.character;

const getCharacterSelector = createSelector(
  [getCharacter],
  (character) => character || 'JULIA' // add fallback value just in case
);

const AnimatedGamePiece = ({ isPlaying, direction, goingBack }) => {
  const character = useSelector(getCharacterSelector);

  return (
    <React.Suspense fallback={<div></div>}>
      {character === 'JULIA' ? <Julia isPlaying={isPlaying} direction={direction} /> : null}
      {character === 'MICHAEL' ? <Michael isPlaying={isPlaying} direction={direction} /> : null}
      {character === 'SARAH' ? <Sarah isPlaying={isPlaying} direction={direction} /> : null}
      {character === 'ANTHONY' ? <Anthony isPlaying={isPlaying} direction={direction} /> : null}
    </React.Suspense>
  );
};

AnimatedGamePiece.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  direction: PropTypes.string.isRequired
};

export default AnimatedGamePiece;