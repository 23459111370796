import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setLastFunFactShowed, setShowedFunFact } from '../../store/action-creators';
import AnimatedDialogWithIcon from './AnimatedDialogWithIcon';
import { BoardContext } from '../../contexts/boardContext';

const funFacts = [
  '66% of people online utilize only 1 or 2 passwords for all their accounts.',
  'There are over 2 billion business emails sent and received each day. The average worker receives 121 emails per day.',
  'The Social Engineering Team sent over 5 million phishing tests to Accenture people in FY20.',
  'Hackers create 300,000 new pieces of malware daily.'
];

const FunFact = ({boardSize}) => {
  const { machineState, send } = useContext(BoardContext);
  const lastFunFactShowed = useSelector(state => state.progress.lastFunFactShowed);
  const dispatch = useDispatch();

  const closeFunFact = () => {
    dispatch(setLastFunFactShowed(lastFunFactShowed + 1));
    dispatch(setShowedFunFact(true));
    send('CLOSE');
  };
  
  return (
    <AnimatedDialogWithIcon 
      ariaLabel='Fun fact'  
      iconSrc='https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/FunFact-02.png'
      buttonText='GOT IT!'
      isOpen={machineState.matches('funFact')} 
      close={closeFunFact}
      boardSize={boardSize}
      title={'Did you know...'}
    >
      <p tabIndex={0}>{funFacts[lastFunFactShowed + 1] || ''}</p>
    </AnimatedDialogWithIcon>
  );
};

FunFact.propTypes = {

};

export default FunFact;
