import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import createBoardMachine from '../machines/boardMachine';
import useScorm from '../utils/hooks/useScorm';

/*---------------------------------*/

const BoardContext = React.createContext({});

/*---------------------------------*/

const BoardContextProvider = (props) => {
  const progress = useSelector(state => state.progress);
  const questions = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  const scorm = useScorm();
  const [machineState, send] = useMachine(createBoardMachine({ ...progress, questions, dispatch, scorm }), { devTools: true });

  useEffect(() => {
    send('START');
  }, [send]);

  return (
    <BoardContext.Provider value={{ machineState, send }}>
      {props.children}
    </BoardContext.Provider>
  )
};

BoardContextProvider.propTypes = {
};

export { BoardContext, BoardContextProvider };

