import React, { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { TopicThreeContext } from "../../../contexts/topicThreeContext";
import { YES, NO } from '../../life-choices/constants';
import LifeChoiceOutcome from '../../dialogs/LifeChoiceOutcome';
import HelpfulHints from '../../dialogs/HelpfulHints';
import { travelHint, noTravelHint } from '../../../data/hints';


const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  }
});

const travelForWorkBackground = {
  [YES]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Plane-01.jpg',
  [NO]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Train-01.jpg'
};

const TopicThreeInitialView = ({  }) => {
  const classes = useStyles();
  const { machineState, send } = useContext(TopicThreeContext);
  const travelForWork = useSelector((state) => state.player.travel);

  const close = useCallback(() => {
    send('CONTINUE');
  }, [send]);

  return (
    <div className={classes.root}
      style={{
        backgroundImage: `url(${travelForWorkBackground[travelForWork || NO]})`
      }}
    >
      <LifeChoiceOutcome
        choice={travelForWork || "NO"}
        isOpen={machineState.matches('lifeChoice')}
        close={close}
      />
      <HelpfulHints
        key='1stHH'
        hints={travelForWork === 'YES' ? travelHint : noTravelHint}
        buttonText='Move on!'
        isOpen={machineState.matches('firstHelpfulHints')}
        close={close}
        bounce={true}
      />
    </div>
  );
}

export default TopicThreeInitialView;
