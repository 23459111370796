import React from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import createTopicTwoMachine from '../machines/topicTwoMachine';
import useScorm from '../utils/hooks/useScorm';

/*---------------------------------*/

const TopicTwoContext = React.createContext([{}, {}]);

/*---------------------------------*/

const TopicTwoContextProvider = (props) => {
  const topicTwoState = useSelector((state) => state.questions.t02);
  const dispatch = useDispatch();
  const scorm = useScorm(); 
  const [machineState, send] = useMachine(createTopicTwoMachine({ ...topicTwoState, dispatch, scorm }), { devTools: true });

  // useEffect(() => {
  //   send('START');
  // }, [send]);

  return (
    <TopicTwoContext.Provider value={{ machineState, send }}>
      {props.children}
    </TopicTwoContext.Provider>
  )
};

TopicTwoContextProvider.propTypes = {
  
};

export { TopicTwoContext, TopicTwoContextProvider };

