import React, { useContext, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import LifeChoiceOutcome from '../../dialogs/LifeChoiceOutcome';
import HelpfulHints from '../../dialogs/HelpfulHints';
import { TopicOneContext } from '../../../contexts/topicOneContext';
import { OFFICE, HOME } from '../../life-choices/constants';
import { officeHints, homeHints } from '../../../data/hints';
const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  }
});

const choiceBackgroundMap = {
  [OFFICE]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Office-01.png',
  [HOME]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Home-01.png'
};

const TopicOneInitialView = (props) => {
  const classes = useStyles();
  const { machineState, send } = useContext(TopicOneContext);
  const officeOrHome = useSelector((state) => state.player.officeOrHome)

  const close = useCallback(() => {
    send('CONTINUE');
  }, [send]);

  const setHelperText = (officeHome) => {
    return officeHome === HOME 
      ? 'If you frequently work from home, keep these tips in mind:' 
      : 'When working in an office setting, keep these tips in mind:';
  }

  const setSecondHelperText = (officeHome) => {
    return officeHome === HOME
      ? 'When you work in an office setting, keep these tips in mind:'
      : 'When you do work from home, keep these tips in mind:';
  }

  return (
    <div 
      className={classes.root}
      style={{
        backgroundImage: `url(${choiceBackgroundMap[officeOrHome] || choiceBackgroundMap[OFFICE]})`
      }}
    >
      <LifeChoiceOutcome 
        choice={officeOrHome}
        isOpen={machineState.matches('lifeChoice')} 
        close={close} 
      />
      <HelpfulHints 
        key='1stHH'
        hints={officeOrHome === HOME ? homeHints : officeHints}
        helperText={setHelperText(officeOrHome)} 
        buttonText='Next!'
        isOpen={machineState.matches('firstHelpfulHints')}
        close={close}
        bounce
      />
      <HelpfulHints 
        key='2ndHH'
        hints={officeOrHome === HOME ? officeHints : homeHints} 
        helperText={setSecondHelperText(officeOrHome)}
        buttonText='Next!'
        isOpen={machineState.matches('secondHelpfulHints')}
        close={close}
      />
    </div>
  );
};

TopicOneInitialView.propTypes = {

};

export default TopicOneInitialView;
