import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { ReactComponent as Bubble } from '../../static/svg/SpeechBubble-01.svg';
import TRDialog from './TRDialog';
import ButtonCustom from '../inputs/ButtonCustom';
import { BoardContext } from '../../contexts/boardContext';

const useStyles = createUseStyles({
  dialog: {
    backgroundColor: 'transparent',
    color: 'white',
    fontSize: '3.5vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    width: '60vw',
    height: '39vw',
    '@media screen and (min-aspect-ratio: 16 / 9)': {
      width: '106.6vh',
      height: '69.3vh',
      fontSize: '6.5vh'
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  svgContainer: {
    width: '100%',
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    width: '75%',
    height: '60%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '48%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    textAlign: 'center',
    padding: '0.5em 0.75em 1em 0.5em',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      padding: '0.6em 1.2em 1em 0.6em',
      alignItems: 'stretch'
    }
  },
  textLabel: {
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    fontSize: '0.4em',
    lineHeight: 1.2,
    padding: '0 0.75rem 1em',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      padding: '0 0.75rem 1em',
    }
  },
  textContent: {
    padding: '0 1.4em',
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
    fontSize: '0.75em',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      fontSize: '0.6em',
      padding: '0 1rem',
    }
  },
  buttonSection: {
    position: 'absolute',
    bottom: '5vw',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      right: '50%',
      transform: 'translate(50%, 50%)'
    },
    '@media screen and (min-aspect-ratio: 16 / 9)': {
      bottom: '9vh'
    }
  },
  buttonRoot: {
    '@media screen and (min-aspect-ratio: 16 / 9)': {
      width: '28vh',
      height: '10vh'
    }
  },
  buttonText: {
    '@media screen and (min-aspect-ratio: 16 / 9)': {
      fontSize: '3.7vh'
    }
  }
});

const professorFirstInstructions = {
  textLabel: <>Just like in your Accenture career, you will get to direct your path through this game and create a unique experience.<br/> You can even earn Star Coins as you progress but be careful on the quizzes – you will lose coins if you answer incorrectly!</>,
  textContent: "When you are ready to begin, select the start button!"
};

const professorSecondInstructions = {
  textLabel: '',
  textContent: `Let's spin again!`
};

const BubbleDialog = ({ boardSize }) => {
  const { machineState, send } = useContext(BoardContext);
  const classes = useStyles();
  const { textLabel, textContent } = useMemo(() => {
    switch (true) {
      case machineState.matches('professorFirstInstructions'):
        return professorFirstInstructions;
      case machineState.matches('professorSecondInstructions'):
        return professorSecondInstructions;
      default:
        return professorSecondInstructions;
    };
  }, [machineState])

  const continueGame = () => {
    send('CONTINUE');
  };

  const dialogStyles = {
  };

  return (
    <TRDialog
      ariaLabelledBy={'bubble'}
      dialogStyles={dialogStyles}
      overlayStyles={{ backgroundColor: 'rgba(255,255,255,0)' }}
      isOpen={machineState.matches('professorFirstInstructions') || machineState.matches('professorSecondInstructions')}
      close={() => null}
      onDismiss={() => null}
      classes={{ dialog: classes.dialog }}
    >
      <div className={classes.container}>
        <div className={classes.svgContainer} >
          <Bubble style={{ width: '100%', height: '100%' }} />
        </div>
        <div id={'contentContainer'} className={classes.contentContainer}>
          {textLabel &&
            <div tabIndex={0} className={classes.textLabel}>
              {textLabel}
            </div>}
          {textContent &&
            <div tabIndex={0} className={classes.textContent}>
              {textContent}
            </div>}
        </div>
        <div className={classes.buttonSection}>
          <ButtonCustom
            buttonText={machineState.matches('professorFirstInstructions') ? 'Start!' : 'Ok!'}
            arialabeltext={machineState.matches('professorFirstInstructions') ? 'Start!' : 'Ok!'}
            classes={{ root: classes.buttonRoot, buttonText: classes.buttonText }}
            onClick={continueGame}
          />
        </div>
      </div>

    </TRDialog>
  )
}

BubbleDialog.propTypes = {
  boardSize: PropTypes.object
};

export default BubbleDialog;
