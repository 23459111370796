import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import QuestionOutcomeDialog from '../../dialogs/QuestionOutcomeDialog';
import { t02 } from '../../../data/questions';
import { TopicTwoContext } from '../../../contexts/topicTwoContext';
import FinishedTopicDialog from '../../dialogs/FinishedTopicDialog';

const questions = t02.q1;

const FinishedQuestionView = ({audioClickHandlerT2}) => {
  const { machineState, send } = useContext(TopicTwoContext);

  const close = () => {
    audioClickHandlerT2();
    send('CONTINUE')
  }

  return (
    <>
      {machineState.matches('question.outcome') &&
        <QuestionOutcomeDialog
          send={send}
          isOpen={machineState.matches('question.outcome')}
          close={close}
          topic='t02'
          q={questions.id}
          points={questions.points}
          feedbackCorrect={questions.feedbackCorrect}
          feedbackIncorrect={questions.feedbackIncorrect}
          ans={questions.ans}
          isBloop={false}
        />
      }
      {machineState.matches('finished') &&
        <FinishedTopicDialog
          isOpen={machineState.matches('finished')}
          ariaLabel={'Let\'s head back to the gameboard to spin again!'}
          text={'Let\'s head back to the gameboard to spin again!'}
          buttonText='Play on!'
          close={close}
          isBloop={false}
        />
      }
    </>
  );
};

FinishedQuestionView.propTypes = {};

export default FinishedQuestionView;
