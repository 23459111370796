import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { SummaryContextProvider, SummaryContext } from '../contexts/summaryContext';
import Congratulations from '../components/summary/Congratulations';
import SummaryTitle from '../components/summary/SummaryTitle';
import TopicSummary from '../components/summary/TopicSummary';
import Certificate from '../components/summary/Certificate';
import ExitCourseButton from '../components/inputs/ExitCourseButton';
import ReplayButton from '../components/inputs/ReplayButton';
import CharacterOverlay from '../components/overlays/CharacterOverlay';

const useStyles = createUseStyles({
  root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
    width: '100vw',
    height: '100vh',
    background: 'radial-gradient(ellipse at center, rgba(162,0,255,1) 0%, rgba(118,0,192,1) 50%, rgba(70,0,115,1) 100%)',
    overflow: 'hidden'
  }
});

const SummaryContent = (props) => {
	const classes = useStyles();
  const { machineState } = useContext(SummaryContext);
  // const history = useHistory();
  // const dispatch = useDispatch();
  
  // useEffect(() => {
  //   if (machineState.matches('final')) {
  //     // use history.push here instead of setCurrentPath b/c '/' route is not guarded
  //     // history.push('/board');
  //     dispatch(setCurrentPath('/board'));
  //   }
  // }, [machineState, dispatch]);

  return (
    <main role='main' className={classes.root}>
      {machineState.matches('congratulations') ? <Congratulations /> : null}
      {machineState.matches('summaryTitle') ? <SummaryTitle /> : null}
      {machineState.matches('topicSummary') ? <TopicSummary /> : null}
      {machineState.matches('certificate') ? <Certificate /> : null}
      {machineState.matches('certificate') ? <ExitCourseButton finish /> : null}
      {machineState.matches('certificate') ? <ReplayButton /> : null}
      {(machineState.matches('summaryTitle') || machineState.matches('topicSummary')) 
        ? <CharacterOverlay 
            styles={{ left: machineState.matches('summaryTitle') ? '19vw' : '8vw' }}
          /> 
        : null}
    </main>
  );
};


const Summary = (props) => {
  return (
    <SummaryContextProvider>
      <SummaryContent />
    </SummaryContextProvider>
  );
};

Summary.propTypes = {

};

export default Summary;
