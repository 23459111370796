import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AudioEffects from '../components/inputs/AudioEffects';

/*---------------------------------*/

const AudioContext = React.createContext({});

/*---------------------------------*/

const AudioContextProvider = (props) => {
  const [effect, setCurrentEffect] = useState('')

  const playEffect = useCallback((efx) => {
    setCurrentEffect(efx);
    console.log('efx:', efx);
  }, [setCurrentEffect]);

  useEffect(() => {
    console.log('effect logging:', effect);
  }, [effect])

  return (
    <AudioContext.Provider value={{ playEffect }}>
      {props.children}
      <AudioEffects effect={effect} setCurrentEffect={playEffect} />
    </AudioContext.Provider>
  )
};

export { AudioContext, AudioContextProvider };
