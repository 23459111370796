import ExitCourseButton from '../components/inputs/ExitCourseButton';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPath } from '../store/action-creators';
import { createUseStyles } from 'react-jss';
import Board from '../components/board/Board';
import Spinner from '../components/board/Spinner';
import FunFact from '../components/dialogs/FunFact';
import ClaimBadge from '../components/dialogs/ClaimBadge';
import LifeChoiceSelection from '../components/dialogs/LifeChoiceSelection';
import { BoardContextProvider, BoardContext } from '../contexts/boardContext';
import { steps } from '../data/steps';

const useStyles = createUseStyles({
  mainContainer: {
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '100vw',
    maxHeight: '100vh'
  }
});

const MainContents = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { machineState } = useContext(BoardContext);
  const [boardSize, setBoardSize] = useState({})
  
  useEffect(() => {
    if(machineState.matches('gameTopic')) {
      const { topic } = steps[machineState.context.currentStep];
      if (!!topic) {
        // instead of pushing, we're going to update the currentPath and let RouteWithGuard handle redirection
        // history.push(`/${topic}`);
        dispatch(setCurrentPath(`/${topic}`));
      }
      return;
    }
    if (machineState.matches('gameComplete')) {
      dispatch(setCurrentPath('/summary'));
    }
  }, [machineState, dispatch]);

  return (
    <div className={classes.mainContainer}>
      <Board setBoardSize={setBoardSize} boardSize={boardSize} />
      <Spinner />
      <FunFact boardSize={boardSize}/>
      <ClaimBadge boardSize={boardSize}/>
      <LifeChoiceSelection />
    </div>
  );
};


const Main = (props) => {
  return (
    <BoardContextProvider>
      <MainContents />
      <ExitCourseButton />
    </BoardContextProvider>
  );
};

export default Main;
