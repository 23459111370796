import React, { } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import {lifeChoiceSrcMap} from '../life-choices/sourcemap'

const useStyles = createUseStyles({
  collectionContainer: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: `translate(-50%, 0)`,
    backgroundImage: `url(https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/CollectionHud-01.png)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  innerContainer: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '19%',
  },
  collection: {
    width: window.screen.width / 100 * 12,
  },
  img: {
    width: '18%',
    padding: '0 2px',
    transition: '500ms ease-in',
    display: 'none'
  },
  trainOrPlane: {
    width: '20%',
    display: 'none',
  },
  homeOrOffice: {
    marginTop: '8px'
  },
});

const MiddleCollection = ({ boardSize}) => {
  const classes = useStyles();
  const questions = useSelector(state => state.questions);
  const officeOrHome = useSelector(state => state.player.officeOrHome)
  const travel = useSelector(state => state.player.travel)
  const accentureArea = useSelector(state => state.player.accentureArea)

  const boxSize = {
    width: (boardSize.width / 7) || 0,
    height: (boardSize.height / 10) || 0,
  };
  const animate = {
    display: 'block',
    animation: '1s dropToTop'
  };
  return (
    <div className={classes.collectionContainer} style={{ ...boxSize }}>
      <div className={classes.innerContainer}>
        <img
          className={`${classes.img} ${officeOrHome === 'HOME' ? classes.homeOrOffice : ''}`}
          src={`${lifeChoiceSrcMap[officeOrHome]?.src}`}
          alt='officeOrHome'
          style={questions.t02.completed ? { ...animate } : {}}
        />
        <img
          className={classes.trainOrPlane}
          src={`${lifeChoiceSrcMap[travel]?.src}`}
          alt='travel'
          style={questions.t03.completed ? { ...animate } : {}}
        />
        <img
          className={classes.img}
          src={`${lifeChoiceSrcMap[accentureArea]?.src}`}
          alt='specialization'
          style={questions.t04.completed ? { ...animate } : {}}
        />
      </div>
    </div>
  );
}

export default MiddleCollection;