import React from 'react';
// import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  shadowContainer: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    padding: 0,
    zIndex: -1,
    filter: 'drop-shadow(0px 1px 4px rgba(50, 50, 50, 0.5))'
  },
  borderContainer: {
    display: 'block',
    height: '100%',
    backgroundColor: '#ffffff',
    padding: '1vw',
    clipPath: 'polygon(0 3%, 100% 0, 98% 97%, 2% 100%)',
  },
  innerContainer: {
    display: 'block',
    height: '100%',
    backgroundColor: ({ theme }) => theme.colors.game.peach,
    clipPath: 'polygon(0 3%, 100% 0, 98% 97%, 2% 100%)',
  },
  contentContainer: {
    padding: '3.5vw 5.5vw',
    '@media screen and (max-width: 750px) and (orientation:landscape)':{
      padding: '3.5vw',
    },
    '@media screen and (max-height: 390px) and (orientation:landscape)':{
      padding: '1.5vw 2.5vw',
    }
  }
});
 
const SkewedDialogBox = ({ children, overlayContentContainerStyles }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <>
      <div className={classes.shadowContainer}>
        <div className={classes.borderContainer}>
          <div className={classes.innerContainer}>
          </div>
        </div>
      </div>
      <div className={`${classes.contentContainer} content`} style={{ ...overlayContentContainerStyles }}>
        {children}
      </div>
    </>
  );
};

SkewedDialogBox.propTypes = {

};

export default SkewedDialogBox;
