import React, { } from 'react';
import { createUseStyles } from 'react-jss';
import InnerScreenView from '../InnerScreenView';
import { OFFICE, HOME } from '../../life-choices/constants';
import TopicTwoQuestion from './TopicTwoQuestion';
import FinishedQuestionView from './FinishedQuestionView';
import useInitialFocus from '../../../utils/hooks/useInitialFocus';

const choiceBackgroundMap = {
  [OFFICE]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/ComputerOffice-02.png',
  [HOME]: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/ComputerHome-02.png'
};

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover'
  },
  innerScreenContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundImage: `url(https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Powerpoint-01.png)`,
    backgroundSize: '90%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 65%',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      backgroundSize: '78%',
      backgroundPosition: 'center 60%',
    },
    '@media screen and (min-aspect-ratio: 3 / 1)': {
      backgroundSize: '70%',
      backgroundPosition: 'center 55%',
    }
  },
  innerScreenTitle: {
    color: 'white',
    marginTop: '1.5vw',
    fontSize: '1.5vw',
    fontWeight: 600,
    '@media screen and (min-aspect-ratio: 2.5 / 1)': {
      marginTop: '4.2vw'
    },
    '@media screen and (min-aspect-ratio: 3 / 1)': {
      marginTop: '6vw'
    },
  },
  questionsContainer: {
    position: 'absolute',
    right: '0',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'start',
  },
  title: {
    width: '70%',
    marginLeft: '18%',
    textAlign: 'left',
    fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif',
    marginBottom: 10,
    marginTop: '5vw',
    fontSize: '1.7vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      marginTop: '3vw',
      width: '67%',
      marginLeft: '21%'
    },
    '@media screen and (min-aspect-ratio: 3 / 1)': {
      marginTop: '1vw',
      width: '60%',
      marginLeft: '25%',
      fontSize: '1.35vw'
    },
  },
  questions:{
    width: '100%'
  }

});

const TopicTwoQuestionView = ({ officeOrHome = 'OFFICE', audioClickHandlerT2 }) => {
  const classes = useStyles();
  const titleFocus = useInitialFocus();

  return (
    <>
      <InnerScreenView backgroundImage={choiceBackgroundMap[officeOrHome] || choiceBackgroundMap[OFFICE]}>
        <div className={classes.innerScreenContainer}>
          <div ref={titleFocus} tabIndex={0} className={classes.innerScreenTitle}>
            Select all the details that need to be scrubbed from the PowerPoint slide before it can be shared.
          </div>
          <div className={classes.innerScreenContent}>
            <div className={classes.questionsContainer}>
              <div tabIndex={0} className={classes.title}>
                Executive summary
              </div>
              <div className={classes.questions}>
                <TopicTwoQuestion audioClickHandlerT2={audioClickHandlerT2} />
              </div>
            </div>
          </div>
        </div>
      {/* 2 components for CORRECT and GOBACK to board */}
      <FinishedQuestionView audioClickHandlerT2={audioClickHandlerT2} />
      </InnerScreenView>
    </>
  )
}

export default TopicTwoQuestionView;
