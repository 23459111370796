import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { CarouselContext } from 'pure-react-carousel';
import SkewedDialogBox from '../dialogs/SkewedDialogBox';
import OrangeHeader from '../typography/OrangeHeader';

const Julia = React.lazy(() => import('../overlays/JuliaOverlay'));
const Michael = React.lazy(() => import('../overlays/MichaelOverlay'));
const Sarah = React.lazy(() => import('../overlays/SarahOverlay'));
const Anthony = React.lazy(() => import('../overlays/AnthonyOverlay'));

const traits = {
  '1': [
    {
      trait: 'Aspiring',
      value: '80',
      desc: 'Challenges others to think'
    },
    {
      trait: 'Curious',
      value: '90',
      desc: 'Favorite colors are crimson and dark gray'
    },
    {
      trait: 'Leaderly',
      value: '85',
      desc: 'Interested in going to the movies, social media, podcasting, and public speaking'
    },
    {
      trait: 'Cheerful',
      value: '75',
      desc: 'Good at bargaining, cannot keep plants alive, often glances at watch'
    },
    {
      trait: 'Observant',
      value: '75',
      desc: ''
    },
    {
      trait: 'Wise',
      value: '85',
      desc: ''
    },
  ],
  '2': [
    {
      trait: 'Understanding',
      value: '90',
      desc: 'Loves surprises'
    },
    {
      trait: 'Youthful',
      value: '70',
      desc: 'Favorite colors are green and hazel'
    },
    {
      trait: 'Personable',
      value: '80',
      desc: 'Interested in competitive dog grooming, cycling, yoga, and photography'
    },
    {
      trait: 'Adventurous',
      value: '79',
      desc: 'Never washes dishes right away, frequently rambles, and very accident prone'
    },
    {
      trait: 'Logical',
      value: '92',
      desc: ''
    },
    {
      trait: 'Confident',
      value: '87',
      desc: ''
    },
  ],
  '3': [
    {
      trait: 'Innovative',
      value: '90',
      desc: 'Lives for the here and now'
    },
    {
      trait: 'Hearty',
      value: '93',
      desc: 'Favorite color is bright blue and white'
    },
    {
      trait: 'Reliable',
      value: '89',
      desc: 'Interested in traveling, learning new languages, golf, and adult coloring books'
    },
    {
      trait: 'Witty',
      value: '89',
      desc: 'Scratches head when confused, laughs at inappropriate moments'
    },
    {
      trait: 'Dedicated',
      value: '94',
      desc: ''
    },
    {
      trait: 'Energetic',
      value: '82',
      desc: ''
    },
  ],
  '4': [
    {
      trait: 'Helpful',
      value: '88',
      desc: 'Seeks harmony with others'
    },
    {
      trait: 'Kind',
      value: '93',
      desc: 'Favorite colors are yellow and mint green'
    },
    {
      trait: 'Optimistic',
      value: '76',
      desc: 'Interested in relaxing, reading, chocolate cake, meditation, painting, and helping old ladies cross the road'
    },
    {
      trait: 'Punctual',
      value: '79',
      desc: 'Doodles on any bit of paper while waiting for something, addicted to sugar, always wakes early'
    },
    {
      trait: 'Clever',
      value: '93',
      desc: ''
    },
    {
      trait: 'Trusting',
      value: '85',
      desc: ''
    },
  ]
};

const formatAriaLabel = (character, traits) => {
  const traitStr = traits.reduce((str, t, i, arr) => {
    return (i === arr.length - 1)
      ? str + 'and ' + t.trait + '.'
      : str + t.trait + ', ';
  }, '');
  const line1 = `${character}. ${character} is ${traitStr}`;
  const line2 = traits.reduce((str, t) => str + t.desc + (!!t.desc ? '. ' : ''), '');

  return line1 + line2;
};

const useStyles = createUseStyles({
  root: {
    width: '53vw',
    position: 'relative',
    height: '25vw',
    margin: '4% auto',
    '& .content': {
      height: '90%',
      paddingBottom: 0,
      '& header': {
        position: 'relative',
        // media queries offset the SkewedDialogBox changes
        '@media screen and (max-width: 750px) and (orientation:landscape)': {
          left: '2vw'
        },
        '@media screen and (max-height: 390px) and (orientation: landscape)': {
          top: '1.5vw'
        }
      },
      '& section': {
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media screen and (max-height: 390px) and (orientation:landscape)': {
          marginTop: '1.5vw'
        }
      }
    },
    '&:focus': {
      border: '3px solid red'
    },
    '@media screen and (max-width: 750px) and (orientation:landscape) ': {
      height: '29vw',
      margin: '4% auto',
    },
    '@media screen and (max-width: 650px) and (orientation:landscape) ': {
      height: '34vw',
    }
  },
  characterImg: {
    position: 'absolute',
    left: '-15%',
    top: '-10%',
    maxHeight: '31vw',
    maxWidth: '15.5vw',
    '& img': {
      width: '100%'
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      height: '100%',
    },
    '@media screen and (max-width: 750px) and (orientation:landscape) ': {
      top: '0%',
      left: '-17%',
      maxHeight: '30vw',
      maxWidth: '16.5vw'
    },
    '@media screen and (max-width: 650px) and (orientation:landscape) ': {
      top: '13%',
    }
  },
  heroBox: {
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      height: '100%',
    }
  },
  traitsWrapper: {
    display: 'flex',
    width: '80%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textAlign: 'left',
    margin: '1vw auto 0'
  },
  trait: {
    flex: '0 48%',
    marginBottom: '0.75vw',
    '& span': {
      fontWeight: 600,
      fontSize: '0.9vw',
      marginBottom: '0.25vw',
      display: 'block',
      marginLeft: 2
    }
  },
  outerTraitBar: {
    clipPath: 'polygon(1% 2%, 100% 2%, 96% 98%, 2% 100%)',
    width: '100%',
    height: '1.6vw',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  traitBar: {
    height: '1.2vw',
    width: 'calc(100% - 0.5vw)',
    clipPath: 'polygon(1% 2%, 100% 2%, 96% 98%, 2% 100%)',
  },
  descTableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    fontSize: '0.9vw',
    minHeight: '8vw',
    border: '0.25vw solid white',
    margin: '1vw auto',
    '@media screen and (max-width: 750px) and (orientation:landscape) ': {
      fontSize: 9,
      minHeight: '13vw'
    },
    '@media screen and (max-width: 650px) and (orientation:landscape) ': {
      fontSize: 9,
      minHeight: '18vw'
    }
  },
  descTableItem: {
    flex: '1 0 25%',
    width: '50%',
    outline: '0.175vw solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.45vw 0',
    '& span': {
      width: '90%'
    },
    '@media screen and (max-width: 750px) and (orientation:landscape) ': {
      flex: '1 0 5vw',
    },
    '@media screen and (max-width: 650px) and (orientation:landscape) ': {
      padding: '0.75vw 0'
    }
  },
  hide: {
    display: 'none'
  }
});

const orangeHeaderStyles = {
  width: '18vw',
  position: 'relative',
  top: '-3vw',
  left: '6%',
  transform: 'translate(-50%, -50%)',
  marginBottom: '-5vw',
  height: '4vw'
};


const CharacterSlide = ({ character, selectCharacter, characterNumber }) => {
  const classes = useStyles();
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  const ariaLabel = useMemo(() => {
    return formatAriaLabel(character, traits[characterNumber]);
  }, [character, characterNumber])

  useLayoutEffect(() => {
    function onChange() {
      selectCharacter(carouselContext.state.currentSlide);
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, selectCharacter]);

  useEffect(() => {
    selectCharacter(currentSlide)
  }, [currentSlide])

  return (
    <div
      className={classes.root}
      aria-atomic="true"
      aria-live="polite"
      style={{ display: (currentSlide + 1) !== characterNumber ? 'none' : 'block' }}
    >
      <SkewedDialogBox>
        <header>
          <OrangeHeader
            headerText={character}
            fontSize={{ fontSize: "0.9vw" }}
            rootStyles={orangeHeaderStyles}
            tabIndex={-1}
          />
        </header>
        <section>
          <div className={classes.traitsWrapper}>

            {traits[characterNumber]
              ? traits[characterNumber].map((item, idx) => (
                <div className={classes.trait} key={idx}>
                  <span>{item.trait}</span>
                  <div className={classes.outerTraitBar}>
                    <div
                      className={classes.traitBar}
                      style={{ background: `linear-gradient(110deg, #85ae6c 0%,#85ae6c ${item.value}%,#cedc9d ${item.value}%,#cedc9d 100%)` }}
                    >
                    </div>
                  </div>
                </div>
              ))
              : null}
          </div>
          <div className={classes.descTableWrapper}>
            {
              traits[characterNumber]
                ? traits[characterNumber].map((item, idx) => (
                  item.desc !== ''
                    ? <div className={classes.descTableItem} key={idx}>
                      <span>{item.desc}</span>
                    </div>
                    : null
                ))
                : null
            }
          </div>
        </section>
        <div className={classes.characterImg}>
          <React.Suspense fallback={<div></div>}>
            {characterNumber === 1 ? <Julia /> : null}
            {characterNumber === 2 ? <Michael /> : null}
            {characterNumber === 3 ? <Sarah /> : null}
            {characterNumber === 4 ? <Anthony /> : null}
          </React.Suspense>
        </div>
      </SkewedDialogBox>
    </div>
  );
};

CharacterSlide.propTypes = {
  traits: PropTypes.arrayOf(
    PropTypes.shape({
      trait: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  ),
};

export default CharacterSlide;
