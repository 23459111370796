import {
  increasePoints,
  setHelpfulHintsShowed,
  setLastPointTotal
} from '../../../store/action-creators';
import React, { useContext, useState, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { TopicThreeContext } from '../../../contexts/topicThreeContext';
import ButtonCustom from '../../inputs/ButtonCustom';
import FocusLock from "react-focus-lock";
import useInitialFocus from '../../../utils/hooks/useInitialFocus';
import { sharingToolsTopicThree } from '../../../data/hints';

const sharingToolsHHId = sharingToolsTopicThree[0].id;

const sharingToolMap = [
  {
    name: 'Sharepoint',
    iconSrc: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Sharepoint-01.png',
    headerSrc: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/SharepointBar-01.png',
  },
  {
    name: 'Teams',
    iconSrc: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Teams-01.png',
    headerSrc: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/TeamsBar-01.png',
  },
  {
    name: 'OneDrive',
    iconSrc: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Onenote-01.png',
    headerSrc: 'https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/OneDriveBar-01.png',
  },
];

const textMap = [
  {
    text: [
      `Accenture people are strongly encouraged to use Accenture-provided network storage like SharePoint as a safe repository for their work.`,
      `Your client's or team's access-controlled SharePoint site is a recommended location to store final deliverables or process documents.`,
      `If you want to keep any deliverables that you have created, check with your leadership and the client before you retain any documents. And make sure that all files are scrubbed of any sensitive information. `
    ]
  },
  {
    text: [
      `Documents or deliverables that contain sensitive client data should never be shared or stored in standard Teams chats. If you must use Teams to share or store sensitive client information, you must use a Private Channel which allows the channel owner to maintain better control over who has access to that information. When messaging about Accenture business be sure to only use Teams, not other messaging services such as Slack or WhatsApp.`,
      `Chats in Microsoft Teams stay around even after you close the window or end a meeting, so remember to remove individuals from the meeting participants list if they no longer need access to future conversations. To remove someone from the chat select the "X" near their name when you hover over their profile picture.`,
    ]
  },
  {
    text: [
      `Using OneDrive for Business enables data to be securely stored in the cloud without the need to backup an Accenture laptop.`,
      `Client data or deliverables should never be shared directly from OneDrive – put these on SharePoint instead. 
      Simultaneously edit documents with multiple Accenture colleagues using both the web-based version or from desktop Office applications. `,
      `Remember, sending links is more secure than attachments. Click on the "Share" button next to the document that you want to share in your OneDrive and choose if you would like to allow or block editing and downloading.  OneDrive should be used for personal storage and to share work in process.  Final deliverables should be stored on an access-controlled SharePoint site. `
    ]
  },
]

const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '105%'
    }
  },
  textBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    color: 'white',
    fontSize: '1.7vw',
    fontWeight: 600,
    margin: '1vw 0 2vw',
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      margin: '4.2vw 0 1vw',
      fontSize: '3.4vh'
    }
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '100%'
  },
  toolItem: {
    width: '32%'
  },

});
//overlaybutton styles for 1 and 2 stage
const overlayButtonRoot = {
  width: '14vw',
  height: '4vw'
}

const overlayTextSpanStyles = {
  fontSize: '1.7vw'
}

const TopicThreeSharingTool = ({ isOpen, audioClickHandlerT3 }) => {
  const classes = useStyles();
  const { send } = useContext(TopicThreeContext);
  const [isClickedState, setClick] = useState({ 0: false, 1: false, 2: false });
  const [isClickedToolWindow, setClickTool] = useState({ 0: false, 1: false, 2: false });
  const dispatch = useDispatch();
  const helpfulHintsShowed = useSelector((state) => state.progress.helpfulHintsShowed);
  const lastPointTotal = useSelector(state => state.player.lastPointTotal);
  const titleFocus = useInitialFocus();

  useEffect(() => {
    if (isOpen && isClickedToolWindow[0] && isClickedToolWindow[1] && isClickedToolWindow[2]) {
      //increase 60 points and update last total
      if (!(sharingToolsHHId in helpfulHintsShowed)) {
        dispatch(increasePoints(60))
        dispatch(setLastPointTotal(lastPointTotal + 60))
        dispatch(setHelpfulHintsShowed(sharingToolsHHId))
      }
      send('CONTINUE')
    }
  }, [isClickedToolWindow, helpfulHintsShowed, dispatch, lastPointTotal, send, isOpen]);


  useEffect(() => {
    if (!isOpen) {
      setClick({ 0: false, 1: false, 2: false });
      setClickTool({ 0: false, 1: false, 2: false });
    }
  }, [isOpen])

  return (
    <> {isOpen &&
      <div className={classes.root} >
        <div ref={titleFocus} tabIndex={0} className={classes.title}>
          <span>Select one of the approved services below to earn coins and learn more about it. </span>
        </div>
        <div className={classes.mainContainer}>
          {
            sharingToolMap.map((item, idx) => {
              return <React.Fragment key={item.name}>
                {
                  !isClickedState[idx]
                    ? (<BeforeClickView audioClickHandlerT3={audioClickHandlerT3} onClick={setClick} setClickTool={setClickTool} isClickedState={isClickedState} isClickedToolWindow={isClickedToolWindow} idx={idx} iconSrc={item.iconSrc} buttonText={item.name} />)
                    : !isClickedToolWindow[idx]
                      ? (<ModalWindow audioClickHandlerT3={audioClickHandlerT3} onClick={setClickTool} isClickedToolWindow={isClickedToolWindow} idx={idx} barSrc={item.headerSrc} buttonText={item.name} />)
                      : <CompletedWindow idx={idx} iconSrc={item.iconSrc} />
                }
              </React.Fragment>
            })
          }
        </div>
      </div>}
    </>
  )
}
//********************************************************************
//******************* STAGE 1 ****************************************
//******************************************************************* */
const BeforeClickView = ({ isClicked = false, onClick, audioClickHandlerT3, setClickTool, idx, isClickedState, isClickedToolWindow, iconSrc, buttonText }) => {
  const styles = {
    beforeClick_root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '33%',
      height: '100%',
    },
    imgWrapper: {
      height: '10vw',
      width: '10vw',
      margin: '0 auto 2vw',
      display: 'flex',
      alignItems: 'center'
    }
  };
  const handleNextClick = () => {
    for (let u in isClickedToolWindow) {
      if (isClickedState[u] && !isClickedToolWindow[u]) {
        //if user opened new window while old is still open we will close the old
        setClickTool({ ...isClickedToolWindow, [u]: true })
      }
    }
    if (audioClickHandlerT3) {
      audioClickHandlerT3();
    }
    onClick({ ...isClickedState, [idx]: true })
  }
  return (
    <> {!isClicked &&
      <div style={{ ...styles.beforeClick_root }} >
        <div style={{ ...styles.imgWrapper }}>
          <img style={{ width: '100%' }} src={iconSrc} alt='' role='presentation' />
        </div>
        <ButtonCustom isBloop={false} overlayRootStyles={overlayButtonRoot} overlayTextSpanStyles={overlayTextSpanStyles} onClick={handleNextClick} buttonText={buttonText} />
      </div>}
    </>
  )
}

//********************************************************************
//******************* STAGE 2 ****************************************
//******************************************************************* */

const useModalWindowStyles = createUseStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '33%',
      height: '100%'
    },
    focuslockRoot: {
      width: '100%',
      height: '85%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center'
    },
    header: {
      width: '100%',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
        overflow: 'hidden',
      }
    },
    img: {
      width: '100%',
      marginBottom: -4,
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
        marginBottom: 0
      }
    },
    section: {
      background: 'white',
      width: '100%',
      height: '100%'
    },
    footer1: {
      position: 'relative',
      bottom: '6%'
    },
    text: {
      width: '100%',
      overflowWrap: 'break-word',
      boxSizing: 'border-box',
      padding: '1vw 1vw 2vw',
      textAlign: 'left',
      fontWeight: '400',
      fontSize: '1.15vw',
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
        fontSize: '1vw',
      },
    },
});

const ModalWindow = ({ isOpen = true, onClick, isClickedToolWindow, idx, barSrc, audioClickHandlerT3, ...props }) => {
  const classes = useModalWindowStyles();
  //Callbacks
  const handleNextClick = () => {
    if (audioClickHandlerT3) {
      audioClickHandlerT3();
    }
    onClick({ ...isClickedToolWindow, [idx]: true })
  }

  return (
    <div className={classes.root} > {isOpen &&
      <FocusLock
        autoFocus
        returnFocus
        onActivation={() => null}
        disabled={isClickedToolWindow[idx]}
        className={classes.focuslockRoot}
      >
        <header className={classes.header}>
          <img className={classes.img} src={barSrc} alt='' role='presentation' />
        </header>
        <section className={classes.section} >
          <div className={classes.text} >
            {/* map text from data source (string 27) */}
            <span>
              {textMap[idx].text.map((t, index) => <p tabIndex={0} key={index} > {t} </p>)}
            </span>
          </div>
        </section>
        <footer className={classes.footer1} >
          <ButtonCustom isBloop={false} overlayRootStyles={overlayButtonRoot} overlayTextSpanStyles={overlayTextSpanStyles}
            onClick={handleNextClick} buttonText={'NEXT!'} arialabeltext={'Complete step and move to the next'}
          />
        </footer>
      </FocusLock>
    }
    </div>
  )
};

//********************************************************************
//******************* STAGE 3 ****************************************
//******************************************************************* */

const useCompletedWindowStyles = createUseStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: '26%',
      height: '60%',
    },
    header: {
      width: '80%',
      position: 'relative',
      marginBottom: '-14%',
      display: 'flex',
      justifyContent: 'center'
    },
    shadowContainer: {
      position: 'relative',
      display: 'block',
      width: '80%',
      height: 'inherit',
      zIndex: -1,
      filter: 'drop-shadow(0px 1px 4px rgba(50, 50, 50, 0.5))',
    },
    borderContainer: {
      display: 'block',
      height: '100%',
      backgroundColor: '#ffffff',
      padding: '1vw',
      clipPath: 'polygon(0 3%, 100% 0, 98% 97%, 2% 100%)',
    },
    innerContainer: {
      display: 'block',
      height: '100%',
      backgroundColor: ({ theme }) => theme.colors.game.peach,
      clipPath: 'polygon(0 3%, 100% 0, 98% 97%, 2% 100%)',
    },
    contentContainer: {
      position: 'absolute',
      top: '-8%',
      left: 0,
      textAlign: 'center',
      marginBottom: '2vw',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& h1': {
        fontSize: '1.9vw',
        marginBottom: 0,
        textTransform: 'uppercase'
      },
      '& p': {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '1.35vw',
        lineHeight: 1.3,
      },
      '& img': {
        marginTop: '3vw',
        marginBottom: '1vw'
      }
    },
    img: {
      width: '40%'
    },
    imgBox: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      position: 'absolute',
      top: '0px',
      left: '0px',
    },
    pointsAwardContainer: {
      width: '75%',
      maxWidth: 350,
      minWidth: 220,
      margin: '0 auto',
    },
    pointsAward: {
      position: 'relative',
      width: '70%',
      height: 0,
      overflow: 'hidden',
      paddingTop: '27.3%',
      '& > div': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/Coin_01.png)'
      }
    },
    pointsAwardText: {
      display: 'inline-block',
      position: 'absolute',
      top: '49%',
      right: '10%',
      transform: 'translateY(-50%)',
      fontFamily: '\'Graphik Black\', \'Arial Black\', sans-serif !important',
      fontSize: '2.5vw',
      color: '#ffffff',
      '@media screen and (max-width: 991px)': {

      },
      '@media screen and (max-width: 767px)': {

      }
    }

});

const CompletedWindow = ({ iconSrc }) => {
  const theme = useTheme();
  const classes = useCompletedWindowStyles({ theme });

  return (
    <div className={classes.root} style={{ zIndex: 1, }} >
      <header className={classes.header} >
        <div className={classes.pointsAward}>
          <div>
            <span className={classes.pointsAwardText}>+{20}</span>
          </div>
        </div>
      </header>
      <div className={classes.shadowContainer}>
        <div className={classes.borderContainer}>
          <div className={classes.innerContainer}></div>
          <div className={`${classes.contentContainer} content`}>
            <div className={classes.imgBox}>
              <img className={classes.img} src={iconSrc} alt='' role='presentation' />
            </div>
            <h1 style={{ color: theme.colors.game.green }} >COMPLETED</h1>
          </div>
        </div>
      </div>

    </div>
  )
}

export default TopicThreeSharingTool;
