import React, { useRef } from 'react';
// import PropTypes from 'prop-types';
import TRDialog from './TRDialog';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import PointsIncrementor from './PointsIncrementor';
import SkewedDialogBox from './SkewedDialogBox';
import ButtonCustom from '../inputs/ButtonCustom';

const useStyles = createUseStyles({
  pointsContainer: {
    maxWidth: 150,
    position: 'absolute',
    top: '-2vw',
    right: '-2vw',
    width: '12vw'
  },
  text:{
    fontSize: '2.2vw',
    textAlign: 'center',
    fontWeight: 600,
    lineHeight: 1.3,
    padding: '15% 15%',
  },
  dialog: {
    width: '55vw',
    '@media screen and (max-height: 390px)': {
      width: '70vw'
    },
    '@media screen and (max-width: 767px)': {
      width: '70vw'
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '70vw'
    }
  },
});

const FinishedTopicDialog = ({ isBloop, isOpen, close, ariaLabel, text, buttonText }) => {
  const classes = useStyles();
  const pointTotal = useSelector((state) => state.player.points);
  const initialRef = useRef();

  
  const overlayRootButtonStyles = {
    width: '16vw',
    height: '4.4vw',
    marginBottom: '-7vw'
  };

  return (
    <TRDialog 
      isOpen={isOpen} 
      ariaLabel={'Topic finished'} 
      close={close}
      initialFocusRef={initialRef}
      dialogStyles={{
        background: 'transparent',
        padding: 0
      }}
      classes={{ dialog: classes.dialog }}
    >
      <SkewedDialogBox>
        {/* Character Img on left (page 19-20 mock up) src={charactersSourceMap[character.toUpperCase()].src} */}
        <section>
          <p ref={initialRef} tabIndex={0} className={classes.text}>{text}</p>
        </section>
        <div>
          <ButtonCustom isBloop={isBloop} buttonText={buttonText} overlayRootStyles={overlayRootButtonStyles} onClick={close}></ButtonCustom>
        </div>
        <div className={classes.pointsContainer}>
          <PointsIncrementor 
            isOpen={isOpen} 
            isAllocatingPoints={false} 
            pointTotal={pointTotal} 
            onRest={close} 
          />
        </div>
      </SkewedDialogBox>
    </TRDialog>
  );
};

FinishedTopicDialog.propTypes = {};

export default FinishedTopicDialog;
