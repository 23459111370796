import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';

const useStyles = createUseStyles({
  flipContainer: {
    perspective: 800,
    width: '29vw',
    minHeight: '5vw',
    position: 'relative',
    marginLeft: '2vw',
    fontSize: '1.1vw',
    flex: 1,
    '&.stacked': {
      width: '100%',
      marginTop: '2vw',
      marginLeft: 0,
      '@media screen and (min-aspect-ratio: 2 / 1)': {
        marginTop: '1.5vw'
      },
      '& .back': {
        alignItems: 'flex-start'
      }
    },
    '& .card.opened': {
      transform: 'rotateX(-180deg)'
    },
    '& .front.opened': {
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
        display: 'none'
      }
    },
    '& .card, & .front, & .back': {
      width: '100%',
      height: '100%'
    },
    '& .card': {
      transition: '0.3s',
      transformStyle: 'preserve-3d',
      position: 'relative',
      minHeight: '5vw'
    },
    '&.stacked .card': {
      minHeight: '18vw'
    },
    '& .front, & .back': {
      backfaceVisibility: 'hidden',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center'
    },
    '& .front': {
      zIndex: 2,
      transform: 'rotateX(0deg)',
      background: 'linear-gradient(to right, #c9c5c1 0%,#d6d4d3 100%)',
      boxShadow: '0.45vw 0.45vw #6f6f6f',
      color: 'transparent',
    },
    '& .back': {
      transform: 'rotateX(-180deg)',
      '& p': {
        whiteSpace: 'pre-line'
      },
      '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
        backfaceVisibility: 'visible',
      }
    },
    '&.stacked .back': {
      //      textAlign: 'justify'
    },
    '@media screen and (max-width: 750px) and (orientation:landscape)': {
      fontSize: 10,
      marginLeft: '2.5vw',
      width: '33vw'
    },
    '@media screen and (min-aspect-ratio: 2 / 1)': {
      width: '50vw'
    }
  },
});

const FlipContainer = ({ checked, value, idx, overlayContainerStyle, stack }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.flipContainer} ${stack ? `stacked` : `unstacked`} flip`} style={{ ...overlayContainerStyle }}>
      <div className={`card ${checked ? 'opened' : ''} `}>
        <div className={`front ${checked ? 'opened' : ''} `} aria-hidden></div>
        <div aria-live='assertive' className={`back`}>
          {checked ? <p id={'test' + idx}>{value}</p> : null}
        </div>
      </div>
    </div>
  );
};

FlipContainer.propTypes = {
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  overlayContainerStyle: PropTypes.object,
  stack: PropTypes.bool
};

export default FlipContainer;

