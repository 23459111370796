import React from 'react';
// import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useMachine } from '@xstate/react';
import createSummaryMachine from '../machines/summaryMachine';
import useScorm from '../utils/hooks/useScorm';

/*---------------------------------*/

const SummaryContext = React.createContext({});

/*---------------------------------*/

const SummaryContextProvider = (props) => {
  const dispatch = useDispatch();
  const scorm = useScorm();
  const [machineState, send] = useMachine(createSummaryMachine({ dispatch, scorm }), { devTools: true });

  return (
    <SummaryContext.Provider value={{ machineState, send }}>
      {props.children}
    </SummaryContext.Provider>
  )
};

SummaryContextProvider.propTypes = {
};

export { SummaryContext, SummaryContextProvider };

