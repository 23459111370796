import React, { useContext, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useSpring, animated as a } from 'react-spring';
import NarratorOverlay from '../overlays/NarratorOverlay';
import { BoardContext } from '../../contexts/boardContext';

const isChromium = !!window.chrome;

const useStyles = createUseStyles({
  bottomContainer: {
    position: 'absolute',
    bottom: '-3px',
    right: 0,
    width: '23vw',
    height: '10vw'
  },
  hud: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '100%',
  },
  professor: {
    position: 'absolute',
    bottom: '-6vw',
    right: '10%',
    width: '9vw',
    '@media screen and (min-aspect-ratio: 16 / 9)': {
      width: '16vh',
      bottom: '-11vh'
    },
    '@media all and (min-aspect-ratio: 16 / 9) and (-ms-high-contrast: none), (-ms-high-contrast: active) ': {
      bottom: '-3vh'
    }

  }
});

const BottomProfessor = ({ src = `https://accenture-mylearning-a-day-in-the-life.s3.amazonaws.com/BottomRightHud-01.png`, boardSize }) => {
  const classes = useStyles();
  const { machineState } = useContext(BoardContext);
  const bubbleShowing = machineState.matches('professorFirstInstructions')
    || machineState.matches('professorSecondInstructions');

  const boxSize = {
    width: (boardSize.width / 7) || '',
    height: (boardSize.height / 10) || '',
  };

  const professorScaleIE_11 = {
    height: (boardSize.height / 3) || '',
  };

  return (
    <div className={classes.bottomContainer} style={{ ...boxSize }}>
      <img
        className={classes.hud}
        src={src}
        alt=''
        role='presentation'
      />
      <a.div
        className={classes.professor}
        style={{
          //...professorScaleIE_11,
          transform: bubbleShowing
            ? isChromium ? 'scale(2) translate(-15%, -25%)' : 'scale(1.7)'
            : 'scale(1)',
          transition: 'all 500ms ease-in-out',
        }}
      >
        <NarratorOverlay />
      </a.div>
    </div>
  );
}

export default BottomProfessor;
