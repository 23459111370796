import React, { useRef } from 'react';
//import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import narrator1 from '../../static/animation/narrator/Narrator1_Introduction.json';

const NarratorPointingOverlay = (props) => {
  const ref = useRef();
  return (
    <Lottie style={{height: '100%'}} lottieRef={ref} animationData={narrator1} autoplay={true} />
  );
};

NarratorPointingOverlay.propTypes = {};

export default NarratorPointingOverlay;
