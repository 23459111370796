import React, { useEffect } from 'react';
//import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

const RouteWithGuard = ({ children, gameProgress, ...rest }) => {
  const location = useLocation();

  useEffect(() => {
    console.log('gameProgress in RouteWithGuard:', gameProgress);
  }, [gameProgress]); 

  return (
    <Route 
      {...rest} 
      render={() => (
        gameProgress === rest.path 
          ? children 
          : <Redirect to={{ pathname: gameProgress, state: { from: location } }} />
      )} 
    />
  );
};

RouteWithGuard.propTypes = {};

export default RouteWithGuard;
