import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { createUseStyles, useTheme } from 'react-jss';
import TopicSummaryPoints from './TopicSummaryPoints';
import TopicSummaryBadges from './TopicSummaryBadges';
import TopicSummaryText from './TopicSummaryText';
import ButtonCustom from '../inputs/ButtonCustom';
import { SummaryContext } from '../../contexts/summaryContext';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '50%',
    minWidth: 400,
    '@media screen and (max-width: 767px)': {
      width: '66%'
    }
  }
});

const TopicSummary = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { machineState, send } = useContext(SummaryContext);

  const overlayButtonStyles = {
    background: theme.colors.acn.blue,
  }
  const disabledOuterStyles = {
    background: theme.colors.acn.darkBlue,
  }
  const disabledInnerStyles = {
    background: theme.colors.acn.blue,
  }
  const disabledBorderStyles = {
    background: theme.colors.acn.lightBlue,
  }

  return (
    <section className={classes.root}>
      <TopicSummaryPoints />
      <TopicSummaryBadges />
      <TopicSummaryText />
      <div className={classes.buttonContainer}>
        <ButtonCustom 
          buttonText={machineState.matches({ topicSummary: 'workstationHygiene' }) ? 'Finish!' : 'Next!'} 
          onClick={() => send('CONTINUE')}
          overlayButtonStyles={overlayButtonStyles}
          disabledOuterStyles={disabledOuterStyles}
          disabledInnerStyles={disabledInnerStyles}
          disabledBorderStyles={disabledBorderStyles}
        />
      </div>
    </section>
  )
};

TopicSummary.propTypes = {

};

export default TopicSummary;
