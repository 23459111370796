export const t01 = {
  q1: {
    id: 'q1',
    prompt: 'When making a new password, what could you use to create a unique password or passphrase?',
    objective: 'create_unique_password',
    options: [
      { text: 'Previous Passwords', id: 'a' },
      { text: 'Pet\'s Name', id: 'b' },
      { text: 'Children\'s Names', id: 'c' },
      { text: 'Song Lyrics', id: 'd' },
    ],
    ans: 'd',
    feedbackCorrect: {
      header: 'Correct!',
      text: 'You identified a great way to create a secure password, which avoids the use of easy-to-find personal information. Other examples of things to avoid are using your birthday or the city you live in.'
    },
    feedbackIncorrect: {
      header: 'Incorrect!',
      text: 'Be sure not to use easy-to-find personal information in your passwords, other examples of things to avoid using are your birthday or the city you live in.'
    },
    type: 'performance',
    points: 100
  },
  q2: {
    id: 'q2',
    prompt: 'Where should you use your Accenture enterprise ID [EID] and password as your login?',
    objective: 'where_to_use_eid',
    options: [
      { text: 'Accenture WorkStation', id: 'a' },
      { text: 'LinkedIn', id: 'b' },
      { text: 'Facebook', id: 'c' },
      { text: 'Amazon', id: 'd' },
      { text: 'None of the Above', id: 'e' },
    ],
    ans: 'a',
    feedbackCorrect: {
      header: 'Great job!',
      text: 'You should only use your EID and password for your Accenture systems. In some cases, it is appropriate for you to use your Accenture email for non-Accenture sites, as long as those sites support legitimate business processes (e.g., technical discussion board or networking on LinkedIn). However, your Accenture password should always be unique from your other passwords.'
    },
    feedbackIncorrect: {
      header: 'Close but not quite!',
      text: 'You should only use your EID and password for your Accenture systems. In some cases, it is appropriate for you to use your Accenture email for non-Accenture sites, as long as those sites support legitimate business purposes (e.g., technical discussion board or networking on LinkedIn). However, your Accenture password should always be unique from your other passwords.',
    },
    type: 'performance',
    points: 100
  }
};

export const t02 = {
  q1: {
    id: 'q1',
    prompt: '',
    objective: 'executive_summary_questions',
    options: [
      {
        text: `Expanding main facility by 20x`, company: 'Monjardin', title: 'Phase 1', id: 'a'
      },
      {
        text: `Accenture proposes a Marketing and Branding Pivot to capture untapped market share`, company: 'Monjardin', title: 'Phase 2', id: 'b'
      },
      {
        text: 'Move', hide: ' London', textTwo: ' office', hideTwo: ' to Singapore', textThree: ' before end of fiscal year', company: '', title: 'Phase 2.5', id: 'c'
      },
      {
        text: `Progressively deploy cloud-centric leadership skills to emerging markets`, company: 'Monjardin', title: 'Phase 3', id: 'd'
      },
      {
        text: `Continually empower business innovation within our digital ecosystem`, company: '', title: 'Phase 3.5', id: 'e'
      },
      {
        text: `Holistically facilitate exceptional processes`, company: 'Monjardin', title: 'Phase 4', id: 'f'
      },
      {
        text: `Username`, title: 'Phase 5', id: 'g'
      },
    ],
    ans: 'correct',
    feedbackCorrect: {
      header: 'Great job!',
      text: 'You scrubbed all the sensitive information from the PowerPoint slide, this email is ready to send.'
    },
    feedbackIncorrect: {
      header: 'Great job!',
      text: 'You scrubbed all the sensitive information from the PowerPoint slide, this email is ready to send.'
    },
    type: 'performance',
    points: 100
  }
};

export const t03 = {
  q1: {
    id: 'q1',
    prompt: 'It looks like your colleague, Natalia Buitrago, needs you to send a spreadsheet that has the personal information of your client’s employees, including their home addresses, benefits enrollments, and national identification numbers. With what permissions should you send the file?',
    objective: 'permisson_for_share',
    options: [
      { text: 'Restricted', id: 'd' },
      { text: 'Highly Confidential', id: 'c' },
      { text: 'Confidential', id: 'b' },
      { text: 'Unrestricted', id: 'a' },
    ],
    ans: 'c',
    feedbackCorrect: {
      header: 'CORRECT!',
      text: 'Sensitive personal data should always be sent with Highly Confidential permissions to protect against falling into the wrong hands.',
    },
    feedbackIncorrect: {
      header: 'INCORRECT!',
      text: 'Sensitive personal data should always be sent with Highly Confidential permissions to protect against falling into the wrong hands.',
    },
    type: 'performance',
    points: 100
  },
  q2: {
    id: 'q2',
    prompt: 'Now your manager, Samuel Dobrancin, wants you to share a final deliverable with Restricted Accenture data. How should you share the information with your team?',
    objective: 'tool_for_share_clientinfo',
    options: [
      { text: 'OneDrive', id: 'a' },
      { text: 'SharePoint', id: 'b' },
      { text: 'Teams Chat', id: 'c' },
      { text: 'Dropbox', id: 'd' },
    ],
    ans: 'b',
    feedbackCorrect: {
      header: 'CORRECT!',
      text: 'SharePoint is the recommended tool to share access to files and folders with Accenture and non-Accenture colleagues such as contractors or vendors. Unless your client has a different solution, use SharePoint to store and share master documents for team materials.',
    },
    feedbackIncorrect: {
      header: 'INCORRECT!',
      text: 'SharePoint is the recommended tool to share access to files and folders with Accenture and non-Accenture colleagues such as contractors. SharePoint is also the recommended tool to store and share master documents for team materials.',
    },
    type: 'performance',
    points: 100
  },
  q3: {
    id: 'q3',
    prompt: 'Select the 2 correct tools you may use to share a deliverable with your team that contains Highly Confidential client data. (Choose two)',
    objective: 'tools_for_share_client_projectinfo',
    options: [
      { text: 'SharePoint', id: 'a' },
      { text: 'Teams (Private Channel)', id: 'b' },
      { text: 'OneDrive', id: 'c' },
    ],
    ans: 'a,b',
    feedbackCorrect: {
      header: 'CORRECT!',
      text: "You correctly identified that OneDrive isn't the correct solution to share client data. All data with sensitive or client information should be saved in SharePoint, unless the client specifies otherwise. Any data files you are collaborating on within your project team can be stored in a Microsoft Teams private channel as long as you have client approval.",
    },
    feedbackIncorrect: {
      header: 'INCORRECT!',
      text: "OneDrive should not be used for sharing client data.  All final deliverables with sensitive data or client information are recommended to be stored in SharePoint, unless the client specifies otherwise.  Any data files you are collaborating on within your project team can be stored in a Microsoft Teams private channel as long as you have client approval.",
    },
    type: 'performance',
    points: 100
  },
  q4: {
    id: 'q4',
    prompt: 'You are helping a Managing Director, Velizara Tiholova, with some financial documents for the acquisition of a company. With what sensitivity should you send the documents?',
    objective: 'sensitivity_for_financial_document',
    options: [
      { text: 'Restricted ', id: 'd' },
      { text: 'Highly Confidential', id: 'c' },
      { text: 'Confidential', id: 'b' },
      { text: 'Unrestricted', id: 'a' },
    ],
    ans: 'd',
    feedbackCorrect: {
      header: 'CORRECT!',
      text: 'Documents and materials that contain important information like financial statements and forecasting, major litigation information, or significant details on acquisition should be sent with the highest level of sensitivity: Restricted.',
    },
    feedbackIncorrect: {
      header: 'INCORRECT!',
      text: 'Documents and materials that contain important information like financial statements and forecasting, major litigation information, or significant details on acquisition should be sent with the highest level of sensitivity: Restricted.',
    },
    type: 'performance',
    points: 100
  }
};

export const t04 = {
  q1: {
    id: 'q1',
    prompt: 'In which 2 situations should you call ASOC? (Choose two)',
    objective: 'when_should_you_call_ASOC',
    options: [
      { text: 'You sent confidential data to another colleague at a different client account to review.', id: 'a' },
      { text: 'You sent a GIF or meme to the wrong recipient.', id: 'b' },
      { text: 'You forgot your ID at home and can’t get into the client site.', id: 'c' },
      { text: 'There is a fire nearby an Accenture Office.', id: 'd' },
      { text: 'You think you have been involved in a phishing incident.', id: 'e' },
      { text: 'You need someone to help you get a fishing license.', id: 'f' },
    ],
    ans: 'a,e',
    feedbackCorrect: {
      header: 'CORRECT!',
      text: "Whether you're in danger while traveling, think you have been phished, or need help with confidential data that may have been misdirected/lost/stolen, ASOC is there for you.",
    },
    feedbackIncorrect: {
      header: 'INCORRECT!',
      text: "ASOC is there to help you whether you're in danger while traveling, think you have been phished, or need help with confidential data that may have been misdirected/lost/stolen.",
    },
    type: 'performance',
    points: 100
  },
  q2: {
    id: 'q2',
    prompt: 'Your colleague, Kris Torres, has some questions about workstation compliance. Select the 2 options below that will help Kris to have a healthy workstation. (Choose two)',
    objective: 'workstation_compliance',
    options: [
      { text: 'Restart your workstation at least once a week to receive the latest updates and patches.', id: 'a' },
      { text: 'Find any software you need on Google and download if it’s free.', id: 'b' },
      { text: 'Never shut down your Accenture devices if you are working from home.', id: 'c' },
      { text: 'Contact Accenture support services if you have an issue with your workstation.', id: 'd' },
    ],
    ans: 'a,d',
    feedbackCorrect: {
      header: 'CORRECT!',
      text: "Restarting your workstation frequently will help it to be healthy and if you have any issues you should contact Accenture support services for help. If you ever need to download software, check software.accenture.com and ask your manager for help if you can’t find what you need.",
    },
    feedbackIncorrect: {
      header: 'INCORRECT!',
      text: "Restarting your workstation frequently is needed to help it be healthy. If you have any issues with your Accenture devices, you should contact Accenture support services for help. If you ever need to download software, check software.accenture.com and ask your manager for help if you can’t find what you need.",
    },
    type: 'performance',
    points: 100
  }
};

export const questions = {
  t01,
  t02,
  t03,
  t04
};
